import { FieldEnums } from '../enums/Field.enum';
import { IFormData } from '../interfaces/Form-Modal.interface';

const FiltersMock = (): Record<string, IFormData> => {
  const StatusSelectField: IFormData = {
    componentType: FieldEnums.CHIP,
    label: 'status',
    name: 'status',
    optionsData: {
      options: [
        { label: 'all', value: 'all' },
        { label: 'pending', value: 'pending_request' },
        { label: 'to_do', value: 'to_do' },
        { label: 'approved', value: 'approved' },
        { label: 'rejected', value: 'rejected' },
      ],
    },
  };

  const TransporterSelectField: IFormData = {
    componentType: FieldEnums.CUSTOMSELECT,
    label: 'transporter',
    type: 'text',
    placeholder: 'select_a_transporter',
    name: 'transporter',
  };

  const PreloadCheckDateField: IFormData = {
    componentType: FieldEnums.DATEPICKER,
    label: 'date',
    name: 'date',
    placeholder: 'select_a_date',
  };

  const ProductSelectField: IFormData = {
    componentType: FieldEnums.CUSTOMSELECT,
    label: 'product',
    type: 'text',
    placeholder: 'select_a_product',
    name: 'product',
  };

  const AgentSelectField: IFormData = {
    componentType: FieldEnums.CUSTOMSELECT,
    label: 'agent',
    type: 'text',
    placeholder: 'select_an_agent',
    name: 'agent',
  };
  return {
    StatusSelectField,
    TransporterSelectField,
    ProductSelectField,
    AgentSelectField,
    PreloadCheckDateField
  };
};

export default FiltersMock;
