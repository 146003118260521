import { IProduct, IProductData, IProductPayload } from '../interfaces/Product.interface';
import { axiosAuthInstance } from './Auth.service';

export const FetchProductsListCreateEditModal = async (): Promise<IProduct[]> => {
  const client = await axiosAuthInstance();
  const response = await client.get('/product/list?limit=30&offset=0');
  return response.data.data;
};

export const FetchFiltersProductsList = async (
  offset: string,
  search?: string
): Promise<IProductData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/product/list?limit=30&isFilter=true&offset=${offset}${searchQuery}`
  );
  return response.data;
};

export const FetchProductsList = async (
  offset: string,
  search?: string
): Promise<IProductData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/product/list?limit=30&offset=${offset}${searchQuery}`
  );
  return response.data;
};



export const searchProductsList = async (search: string): Promise<IProduct[]> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/product/list?limit=30&offset=0&search=${search}`);
  return response.data.data;
};

export const getProduct = async (id: string): Promise<IProduct> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/product/${id}`);
  return response.data;
};

export const createProduct = async (payload: IProductPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.post('/product', payload);
  return response.data;
};
