export const DashboardFilters = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
};

export const ChecksFilters = {
  PRELOADCHECKS: 'preload-checks',
  ANNUALVETTINGS: 'annual-vettings',
};
