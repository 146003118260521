import { IOptions } from '../interfaces/Form-Modal.interface';
import { axiosAuthInstance } from './Auth.service';

// Get Affiliates
export const GetAffiliates = async (filterByCountry?: boolean): Promise<IOptions[]> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  if (filterByCountry !== undefined && filterByCountry) {
    queyParams += '?ct=true';
  }

  const response = await client.get(`/transporter/affiliates${queyParams}`);
  return response.data.affiliates.filter((affiliate: string | null) => affiliate !== null).map((affiliate: string) => {
      return {
        label: affiliate,
        value: affiliate,
      }
  });
};




