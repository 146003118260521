import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './DriverDetails.scss';
import * as Yup from 'yup';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions,
  IOptionsData,
} from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IAnnualVettingsDataTable,
  IColumnTable,
  IPreloadChecksDataTable,
} from '../../interfaces/Table.interface';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  DriverHandleSelectTransportersSearch,
  FetchFiltersAgents,
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  InitFiltersData,
} from '../../services/Helper.service';
import { GetDriverTransporterList, deleteDriver, editDriver, getDriver } from '../../services/Driver.service';
import { IDriverDetail, IDriverPayload } from '../../interfaces/Driver.interface';
import Loader from '../../shared/components/Loader/Loader';
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment';
import { useSnackbar } from '../../services/SnackbarUtils';
import FiltersMock from '../../constants/Fitlers';
import { ChecksFilters } from '../../constants/Dashboard';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';
import { GetAffiliates } from '../../services/Affiliate.service';

interface IDriversProps {
  transporters: IOptions[];
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const DriverDetails = (props: IDriversProps): React.JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [preloadChecksListPaginationTotal, setPreloadChecksListPaginationTotal] = useState(0);
  const [annualVettingsListPaginationTotal, setAnnualVettingsListPaginationTotal] = useState(0);
  const [preloadChecksDataTable, setPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [annualVettingsDataTable, setAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField } =
    FiltersMock();
  const [openEditModal, setOpenEditModal] = useState(false);
  const PreloadCheckFiltPreloadCheckFiltersDataers: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
  ];
  const [PreloadCheckFilters, setPreloadCheckFilters] = useState<IFormData[]>(
    PreloadCheckFiltPreloadCheckFiltersDataers
  );
  const [driverDetails, setDriverDetails] = useState<IDriverDetail>();
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [cardDriverInfo, setCardDriverInfo] = useState<ICardItem[]>([]);
  const { DriversModalForm } = Mock();
  const [DriversForm, setDriversForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(DriversModalForm))
  );

  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [formInitialValues, setFormInitialValues] = useState<IInitialValues>({
    surname: '',
    name: '',
    transporter: '',
    country: '',
    id_number: '',
    licence_number: '',
    licence_expiration_date: '',
  });
  const { PreloadCheckListColumns, AnnualVettingListColumns } = Mock();
  const formSchema: IFormSchema = Yup.object({
    surname: Yup.string().required(),
    name: Yup.string().required(),
    affiliate: Yup.object().required(),
    transporter: Yup.object().required(),
    id_number: Yup.string().required(),
    licence_number: Yup.string().required(),
    licence_expiration_date: Yup.string().required(),
  });
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const statusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const productFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const agentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());
  const cards: ICard[] = [
    {
      title: t('driver_information'),
      card: cardDriverInfo,
    },
  ];

  const updateFiltersData = (newFilters: IFormData[]): void => {
    setPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const handleTableRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=drivers`);
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=drivers`);
    }
  };

  /* METHOD EMITTING API CALLS */
  /* Get Driver Data Method */
  const fetchDriver = (): void => {
    setIsRequestInProgress(true);
    getDriver(params.id as string)
      .then(async (driverData) => {
        setIsRequestInProgress(false);
        setDriverDetails(driverData);
        setPreloadChecksListPaginationTotal(0);
        setAnnualVettingsListPaginationTotal(0);
        // Method That Sets Drivers Form Values
        setDriverFormValues(driverData);
        getCardDriverInfo(driverData);
        // Find the "Transporter" field in the Form array
        const transporterFieldIndex = DriversForm.findIndex((field) => field.name === 'transporter');
        if (transporterFieldIndex !== -1) {
          DriversForm[transporterFieldIndex].disabled = false;
          // Fill Options Of Transporter Dropdown By Method GetDriverTransporterListOptions
          DriversForm[transporterFieldIndex].options = await GetDriverTransporterListOptions(driverData.affiliate);
        }
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Delete Driver Method */
  const handleDeleteDriver = (): void => {
    setIsRequestInProgress(true);
    deleteDriver(params.id as string)
      .then(() => {
        setIsRequestInProgress(false);

        setOpenEditModal(false);
        showSnackbar(t('driver_has_been_deleted_successfully'), 'success');

        setTimeout(() => {
          window.history.back();
        }, 1000);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const driverId = [params.id as string];
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      statusFilters,
      null,
      transporterFilters,
      productFilters,
      agentFilters,
      undefined,
      driverId
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);

        // Method That Sets Preload Checks Array Data
        setPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const driverId = [params.id as string];
    setIsRequestInProgress(true);

    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      statusFilters,
      null,
      transporterFilters,
      productFilters,
      agentFilters,
      undefined,
      driverId
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setAnnualVettingsListPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Edit Driver Method */
  const handleEditDriver = (driverForm: IFormSchema): void => {
    setIsRequestInProgress(true);
    const driverPayload: IDriverPayload = {
      card_id: driverForm.id_number,
      name: driverForm.name,
      last_name: driverForm.surname,
      driver_licence: driverForm.licence_number,
      exp_licence: driverForm.licence_expiration_date,
      affiliate: driverForm.affiliate.value,
      transporter: driverForm.transporter.value,
    };

    editDriver(params.id as string, driverPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenEditModal(false);
        showSnackbar(t('driver_has_been_edited_successfully'), 'success');
        fetchDriver();
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const SetModalForm = async (): Promise<void> => {
    const formData = DriversForm;
    // Find the "affiliate" field in the Form array
    const affiliateFieldIndex = formData.findIndex((field) => field.name === 'affiliate');
    if (affiliateFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[affiliateFieldIndex].options = await GetAffiliates();
      // Add Method To Handle Affiliate Change
      formData[affiliateFieldIndex].handSelectChange = handleAffiliateChange;
    }




    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      formData[transporterFieldIndex].disabled = false;
      // Fill Options Of Transporter Dropdown By Method GetDriverTransporterListOptions
      if (driverDetails !== undefined) {
        formData[transporterFieldIndex].options = await GetDriverTransporterListOptions(driverDetails.affiliate);
      }
      // Pass Search Product Method With It's result on the attribute method
      formData[transporterFieldIndex].CustomHandleSearchLoadOptions = DriverHandleSelectTransportersSearch;
    }

    setDriversForm(formData);
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  const GetDriverTransporterListOptions = async (affiliate: string, searchQuery?: string): Promise<IOptions[]> => {
    const transporterList = await GetDriverTransporterList(affiliate, searchQuery);
    return transporterList.map(transporter => {
      return { value: transporter.id, label: transporter.name };
    });
  };

  useEffect(() => {
    fetchDriver();
  }, []);

  useEffect(() => {
    void SetModalForm();
    SetFiltersData();
    /* GET CHECKS DATA */
    GetChecksData();
  }, [props, location, activeTab, searchValue]);

  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      PreloadCheckFilters,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      statusFilters,
      transporterFilters,
      productFilters,
      agentFilters
    );
    setPreloadCheckFilters(InitFiltersOptions);
  };

  // This Method Is Trigered When Affiliate Select Change It's Value, So We Can Enable The Transporter Field
  // And Pass The Right Options For It Depending On Affiliate Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleAffiliateChange = async (option: IOptions): Promise<void> => {
    const formData = DriversForm;
    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Get Affiliate Value
      const affiliateValue = option.value;
      // Fill Options Of Transporter Dropdown By Method GetDriverTransporterListOptions
      formData[transporterFieldIndex].options = await GetDriverTransporterListOptions(affiliateValue);
      // Passing Affiliate Value To Use It On Select Field Component To Load Options By API And Send Affiliate Value On API
      formData[transporterFieldIndex].relativeFieldValue = affiliateValue;
    }
  }

  /* Fill Initial Values Driver's Form Method */
  const setDriverFormValues = (driverDetails: IDriverDetail): void => {
    const initialValues = {
      surname: driverDetails.last_name,
      name: driverDetails.name,
      transporter: { label: driverDetails.transporter, value: driverDetails.transporter },
      affiliate: { label: driverDetails.affiliate, value: driverDetails.affiliate },
      id_number: driverDetails.card_id,
      licence_number: driverDetails.driver_licence,
      licence_expiration_date: new Date(driverDetails.exp_licence),
    };
    setFormInitialValues(initialValues);
  };

  const editModalBtnClicked = (): void => {
    setOpenEditModal(true);
  };

  const editModalBtnClose = (): void => {
    setOpenEditModal(false);
  };

  /* Fill Details Card Method */
  const getCardDriverInfo = (driverData: IDriverDetail): void => {
    const cardDriverInfo: ICardItem[] = [
      {
        label: 'name',
        value: `${driverData.name} ${driverData.last_name}`,
        link: `/drivers/details/${driverData.id}`,
      },
      {
        label: 'id_number',
        value: driverData.card_id,
      },
      {
        label: 'affiliate',
        value: driverData.affiliate,
      },
      {
        label: 'driver_licence',
        value: driverData.driver_licence,
      },
      {
        label: 'licence_expirtation_date',
        value:
          driverData.exp_licence === null
            ? '-'
            : moment(driverData.exp_licence).format('YYYY/MM/DD'),
      },
    ];

    setCardDriverInfo(cardDriverInfo);
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, PreloadCheckFilters, tabName);
    navigate(url);
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}
      {driverDetails !== undefined && (
        <Details
          title={`${driverDetails.name} ${driverDetails.last_name}`}
          subtitle={t('drivers')}
          cards={cards}
          displayHeaderEditBtn={true}
          editModalTitle={t('edit_driver_info')}
          modalForm={DriversForm}
          formInitialValues={formInitialValues}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksDataTable={preloadChecksDataTable}
          annualVettingsChecksDataTable={annualVettingsDataTable}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={preloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={annualVettingsListPaginationTotal}
          filtersData={PreloadCheckFilters}
          updateFiltersData={updateFiltersData}
          onTableRowClicked={handleTableRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          openEditModal={openEditModal}
          handleOpenEditModal={editModalBtnClicked}
          handleCloseEditModal={editModalBtnClose}
          handleEditModalSubmit={handleEditDriver}
          handleEditModalDelete={handleDeleteDriver}
          deleteConfirmModalTitle={t('are_you_sure')}
          closeConfirmModalDescription={t('driver_info_will_be_lost')}
          deleteConfirmModalDescription={t('driver_info_will_be_lost')}
          deleteModalConfirmLabel={t('delete_driver')}
          deleteModalCancelLabel={t('cancel')}
          deleteModalButtonLabel={t('delete_driver')}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('driver_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default DriverDetails;
