import React, { useEffect, useState } from 'react';
import { MenuItem, Select, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';
import IconLeft from './../../assets/icons/icon-chevron-left-black.svg';
import {
  IEntireCheckTable,
  IPreloadCheck,
  IPreloadCheckCategory,
  IPreloadCheckPayload,
  IPreloadCheckQuestion,
  IPreloadCheckQuestionPayload,
} from '../../interfaces/PreloadCheck.interface';
import {
  IAnnualVettingRejectedRequirementDataTable,
  IColumnTable,
  IRejectedRequirementDataTable,
} from '../../interfaces/Table.interface';
import {
  editPreloadCheckQuestion,
  editPreloadCheckStatus,
  getPreloadCheck,
  getPreloadCheckCategories,
} from '../../services/PreloadCheck.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import ButtonComponent from '../../shared/components/button/Button';
import Loader from '../../shared/components/Loader/Loader';
import './PreloadCheckEntire.scss';
import Table from '../../shared/components/table/Table';
import { PreloadCheckQuestionStatusEnums, PreloadCheckStatus } from '../../enums/Status.enum';
import IconApproved from './../../assets/icons/icon-approved-black.svg';
import IconRejected from './../../assets/icons/icon-close-black.svg';
import IconStatusApproved from './../../assets/icons/icon-status-approved.svg';
import IconStatusRejected from './../../assets/icons/icon-status-rejected.svg';

const PreloadCheckEntire = (): JSX.Element => {
  const [preloadCheckData, setPreloadCheckData] = useState<IPreloadCheck>();
  const [preloadCheckCategories, setPreloadCheckCategories] = useState<IPreloadCheckCategory[]>();
  const [tablesList, setTablesList] = useState<IEntireCheckTable[]>([]);
  const [rejectedRequirementsDataTable, setRejectedRequirementsDataTable] = useState<
    IRejectedRequirementDataTable[] | IAnnualVettingRejectedRequirementDataTable[]
  >([]);

  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const { CheckQuestionsColumns, AnnualVettingQuestionsColumns } = Mock();

  const location = useLocation();
  const { t } = useTranslation('common');
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const isSecurityCheck = Boolean(searchParams.get('isSecurityCheck'));
  const isAnnualVetting = Boolean(searchParams.get('isAnnualVetting'));

  const EditableStatusColumns: IColumnTable[] = [
    ...(CheckQuestionsColumns as IColumnTable[]),
    {
      accessorKey: 'status',
      header: t('status'),
      Cell: (data: any) => {
        return (
          <div className="status-select">
            <div className="status-container">
              {entireListgetStatusIcon(data.row.original.status)}
            </div>
            <Select
              className="table-status-select"
              labelId="table-status-select-standard-label"
              id="table-status-select-standard"
              value={
                data.row.original.status === PreloadCheckQuestionStatusEnums.ACCEPTABLE
                  ? 'yes'
                  : 'no'
              }
              onChange={(e) => {
                entireListHandleSelectStatusChange(e, data.row.original);
              }}
              MenuProps={{
                classes: { paper: 'oryx-select__paper table-status-select__paper' },
              }}
            >
              <MenuItem value="yes">{t('yes')}</MenuItem>
              <MenuItem value="no">{t('no')}</MenuItem>
            </Select>
          </div>
        );
      },
    },
  ];

  const [CheckQuestionsEditableStatusColumnTable, setCheckQuestionsEditableStatusColumnTable] =
    useState<IColumnTable[]>([]);

  const [CheckQuestionsColumnTable, setCheckQuestionsColumnTable] = useState<IColumnTable[]>([]);

  const entireListgetStatusIcon = (status: PreloadCheckQuestionStatusEnums): React.JSX.Element => {
    switch (status) {
      case PreloadCheckQuestionStatusEnums.ACCEPTABLE:
        return <img src={IconStatusApproved} alt="icon-status" />;
      default:
        return <img src={IconStatusRejected} alt="icon-status" />;
    }
  };

  /* METHOD EMITTING API CALLS */
  /* Get Preload Check Data Method */
  const fetchPreloadCheck = (): void => {
    setIsRequestInProgress(true);
    getPreloadCheck(params.id as string)
      .then((preloadCheck) => {
        setIsRequestInProgress(false);
        setPreloadCheckData(preloadCheck);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadCheckCategories = (): void => {
    setIsRequestInProgress(true);
    getPreloadCheckCategories(params.id as string)
      .then((categories) => {
        setPreloadCheckCategories(categories);
        const rejectedQuestions: IPreloadCheckQuestion[] = [];

        categories.forEach((category) => {
          const filteredQuestions = filterUnacceptableQuestions(category.questions);

          // If there are failed questions, update the category's questions array
          if (filteredQuestions.length > 0) {
            // Add failed questions to the failedQuestions array
            rejectedQuestions.push(...filteredQuestions);
          }
        });
        const rejectedQuestionsData = {
          hideTableHeader: true,
          title: t('rejected_requirements'),
          data: getQuestionsDataTable(rejectedQuestions),
        };
        setRejectedRequirementsDataTable(rejectedQuestionsData.data);
        const data = categories.map((categorie, index) => {
          return {
            hideTableHeader: index !== 0,
            title: categorie.category,
            data: getQuestionsDataTable(categorie.questions),
          };
        });

        setTablesList(data);
        setIsRequestInProgress(false);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const entireListhandleEditPreloadCheckStatus = (status: PreloadCheckStatus): void => {
    if (preloadCheckData !== undefined) {
      const intermediateSecurityCheckStatus =
        preloadCheckData.security_check_status === PreloadCheckStatus.PENDINGREQUEST
          ? status
          : PreloadCheckStatus.TODO;

      setIsRequestInProgress(true);
      const payload: IPreloadCheckPayload = {
        id: params.id as string,
        transporter: preloadCheckData.transporter,
        trailers: preloadCheckData.trailers,
        created_at: preloadCheckData.created_at,
        updated_at: preloadCheckData.updated_at,
        status,
        security_check_status:
          status === PreloadCheckStatus.REJECTED
            ? PreloadCheckStatus.REJECTED
            : intermediateSecurityCheckStatus,
      };
      editPreloadCheckStatus(params.id as string, payload)
        .then((preloadCheckEdited) => {
          // Edit Preload Check Status
          setPreloadCheckData({ ...preloadCheckData, status: preloadCheckEdited.status });

          let snackBarMsg = 'preload_check_has_been_approved';
          let snackBarClass = 'success';

          if (status === PreloadCheckStatus.REJECTED) {
            snackBarMsg = 'preload_check_has_been_rejected';
            snackBarClass = 'error';
          }

          showSnackbar(
            t(snackBarMsg, { orderNumber: preloadCheckData.order_number }),
            snackBarClass
          );
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const entireListHandleSelectStatusChange = (event: any, data: any): void => {
    setIsRequestInProgress(true);

    const selectedValue = event.target.value;
    const questionPayload: IPreloadCheckQuestionPayload = {
      preload_check_id: params.id as string,
      question_id: data.question_id,
      status: PreloadCheckQuestionStatusEnums.ACCEPTABLE,
      comment: data.comments,
    };

    if (selectedValue === 'no') {
      questionPayload.status = PreloadCheckQuestionStatusEnums.UNACCEPTABLE;
    }

    editPreloadCheckQuestion(data.id, questionPayload)
      .then(() => {
        setIsRequestInProgress(false);
        fetchPreloadCheckCategories();
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  useEffect(() => {
    setCheckQuestionsEditableStatusColumnTable(EditableStatusColumns);
    fetchPreloadCheck();
    fetchPreloadCheckCategories();
  }, []);

  useEffect(() => {
    getColumns();
  }, [preloadCheckData]);

  const getQuestionsDataTable = (
    questions: IPreloadCheckQuestion[]
  ): IRejectedRequirementDataTable[] | IAnnualVettingRejectedRequirementDataTable[] => {
    return isAnnualVetting
      ? getAnnualVettingQuestionsDataTable(questions)
      : getPreloadCheckQuestionsDataTable(questions);
  };

  const getPreloadCheckQuestionsDataTable = (
    questions: IPreloadCheckQuestion[]
  ): IRejectedRequirementDataTable[] => {
    const questionsDataTable: IRejectedRequirementDataTable[] = questions.map((question) => {
      return {
        id: question.id,
        question_id: question.question_id,
        tolerance: question.tolerance as string,
        category: question.category_id as string,
        requirement: question.question,
        comments: question.comment !== null && question.comment.length > 0 ? question.comment : '-',
        photo: question.image,
        status: question.status,
      };
    });

    return questionsDataTable;
  };

  // Recursive function to filter questions and sub-questions by UNACCEPTABLE status
  const filterUnacceptableQuestions = (
    questions: IPreloadCheckQuestion[]
  ): IPreloadCheckQuestion[] => {
    return questions.reduce<IPreloadCheckQuestion[]>((acc, question: IPreloadCheckQuestion) => {
      // Check if the question itself is unacceptable
      if (question.status === PreloadCheckQuestionStatusEnums.UNACCEPTABLE) {
        acc.push(question);
      }

      // Check and filter unacceptable sub-questions, if any
      if (question.subQuestions.length > 0) {
        const filteredSubQuestions = filterUnacceptableQuestions(question.subQuestions);

        // If there are unacceptable sub-questions, clone the question object
        // to avoid mutating the original, and replace its subQuestions with the filtered list
        if (filteredSubQuestions?.length > 0) {
          const questionWithFilteredSubQuestions = {
            ...question,
            subQuestions: filteredSubQuestions,
          };
          acc.push(questionWithFilteredSubQuestions);
        }
      }

      return acc;
    }, []);
  };

  const getAnnualVettingQuestionsDataTable = (
    questions: IPreloadCheckQuestion[]
  ): IAnnualVettingRejectedRequirementDataTable[] => {
    let flattenedQuestions: IAnnualVettingRejectedRequirementDataTable[] = [];

    questions.forEach((question) => {
      const baseQuestion: IAnnualVettingRejectedRequirementDataTable = {
        id: question.id,
        category: question.category_id as string,
        subCategory: question.subCategory,
        requirement: question.question,
        comments: question.comment !== null && question.comment.length > 0 ? question.comment : '-',
        photo: question.image,
        compliance_level: question.eliminatoryComplianceLevels[0],
      };

      // Add the current question to the flattened list
      flattenedQuestions.push(baseQuestion);

      // If there are sub-questions, recursively flatten them and add to the list
      if (question.subQuestions.length > 0) {
        const flattenedSubQuestions = getAnnualVettingQuestionsDataTable(question.subQuestions);
        flattenedQuestions = flattenedQuestions.concat(flattenedSubQuestions);
      }
    });

    return flattenedQuestions;
  };

  const redirectToPrevPage = (): void => {
    window.history.back();
  };

  const displayApprovedButton = (): boolean => {
    return (
      rejectedRequirementsDataTable.length === 0 &&
      (rejectedRequirementsDataTable as IRejectedRequirementDataTable[]).find(
        (element) => element.tolerance === '0'
      ) === undefined
    );
  };

  const displayPreloadCheckStatusBadgeOrBtn = (preloadCheck: IPreloadCheck): React.JSX.Element => {
    if (preloadCheck.status === PreloadCheckStatus.PENDINGREQUEST) {
      return (
        <div className="status-buttons">
          <ButtonComponent
            iconUrl={IconRejected}
            iconAlt="icon-cross"
            label={t('reject')}
            color="inactive"
            classes="box-shadow--inactive"
            onClick={() => {
              entireListhandleEditPreloadCheckStatus(PreloadCheckStatus.REJECTED);
            }}
          />
          {/* Display Approved Button When No Rejected Requirement With Tolerance 0  */}
          {displayApprovedButton() && (
            <ButtonComponent
              iconUrl={IconApproved}
              iconAlt="icon-check"
              label={t('approve')}
              color="active"
              classes="box-shadow--active"
              onClick={() => {
                entireListhandleEditPreloadCheckStatus(PreloadCheckStatus.APPROVED);
              }}
            />
          )}
        </div>
      );
    } else {
      return <div className={`badge ${preloadCheck.status}`}>{t(preloadCheck.status)}</div>;
    }
  };

  const displayAnnualVettingStatusBadgeOrBtn = (preloadCheck: IPreloadCheck): React.JSX.Element => {
    return <div className={`badge ${preloadCheck.status}`}>{t(preloadCheck.status)}</div>;
  };

  const HeaderButtonsBlock = (preloadCheck: IPreloadCheck): React.JSX.Element => {
    return isAnnualVetting
      ? displayAnnualVettingStatusBadgeOrBtn(preloadCheck)
      : displayPreloadCheckStatusBadgeOrBtn(preloadCheck);
  };

  const getPageTitle = (): string => {
    if (isAnnualVetting) {
      return t('entire_annual_vetting');
    } else {
      if (isSecurityCheck) {
        return t('entire_security_check');
      } else {
        return t('entire_preload_check');
      }
    }
  };

  const getColumns = (): void => {
    isAnnualVetting
      ? setCheckQuestionsColumnTable(getAnnualVettingColumns())
      : setCheckQuestionsColumnTable(getPreloadCheckColumns());
  };

  const getPreloadCheckColumns = (): IColumnTable[] => {
    if (preloadCheckData !== undefined) {
      return preloadCheckData.status === PreloadCheckStatus.PENDINGREQUEST
        ? CheckQuestionsEditableStatusColumnTable
        : (CheckQuestionsColumns as IColumnTable[]);
    }

    return [];
  };

  const getAnnualVettingColumns = (): IColumnTable[] => {
    return AnnualVettingQuestionsColumns as IColumnTable[];
  };

  return (
    <div className="preload-check-entire">
      <div className="oryx-check-details">
        {isRequestInProgress && <Loader />}

        <div className="oryx-check-details__header">
          <div className="header-container">
            <div className="left-side">
              <ButtonComponent
                classes="no-padding btn-transparent"
                iconOnly={true}
                iconRound={false}
                iconUrl={IconLeft}
                iconAlt="icon-chevron-left"
                onClick={redirectToPrevPage}
              />
              <div className="description">
                <h1>{getPageTitle()}</h1>
                <p className="subtitle">{preloadCheckData?.truck.truck_number}</p>
              </div>
            </div>
            <div className="right-side">
              {preloadCheckData !== undefined && HeaderButtonsBlock(preloadCheckData)}
            </div>
          </div>
          <Table
            data={[]}
            columns={CheckQuestionsColumnTable}
            emptyMsg={''}
            onRowClicked={() => {}}
            totalPages={0}
            hideTableBody={true}
            enableSorting={false}
          />
        </div>
        {preloadCheckCategories !== undefined && preloadCheckData !== undefined && (
          <div className="oryx-check-details__body">
            <div className="oryx-check-details__table">
              {rejectedRequirementsDataTable.length > 0 && (
                <div className="preload-check-item rejected-requirements">
                  <h2>{t('rejected_requirements')}</h2>
                  <Table
                    hideTableHeader={true}
                    data={rejectedRequirementsDataTable}
                    columns={CheckQuestionsColumnTable}
                    emptyMsg={t('no_rejected_requirements')}
                    onRowClicked={() => {}}
                    totalPages={0}
                  />
                </div>
              )}
              {tablesList.map((tableData, index) => {
                return (
                  <div
                    className={`preload-check-item ${index === 0 ? 'first-item' : ''}`}
                    key={`check-table-container-${index}`}
                  >
                    <h2>{tableData.title}</h2>
                    <Table
                      hideTableHeader={true}
                      key={`check-table-${index}`}
                      data={tableData.data}
                      columns={CheckQuestionsColumnTable}
                      emptyMsg={t('no_rejected_requirements')}
                      onRowClicked={() => {}}
                      totalPages={0}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <Snackbar
          className={`oryx-snackbar ${snackBarType}`}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackBarOpen}
          message={snackbarMessage}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
        />
      </div>
    </div>
  );
};

export default PreloadCheckEntire;
