import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../button/Button';
import IconEdit from './../../../assets/icons/icon-edit.svg';
import IconLeft from './../../../assets/icons/icon-chevron-left-black.svg';

import './Details.scss';
import AddEditModal from '../add-edit-modal/Add-Edit-Modal';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions,
} from '../../../interfaces/Form-Modal.interface';
import { ICard } from '../../../interfaces/Details.interface';
import { Box, Chip, Tab, Tabs, TextField } from '@mui/material';
import { CHECKTYPE } from '../../../enums/Dashboard.enum';
import { IColumnTable, IDataTable } from '../../../interfaces/Table.interface';
import IconSearch from './../../../assets/icons/icon-search.svg';
import IconClear from './../../../assets/icons/icon-search-clear.svg';
import IconFilter from './../../../assets/icons/icon-filter.svg';
import IconAgent from './../../../assets/icons/icon-agent.svg';
import Table from '../table/Table';
import FilterModal from '../filter-modal/Filter-Modal';
import { DisplayCards } from '../../../services/Helper.service';
import { ChecksFilters } from '../../../constants/Dashboard';

interface DetailsProps {
  title: string;
  subtitle: string;
  displayHeaderEditBtn: boolean;
  displayHeaderStatusBtn?: boolean;
  agentStatus?: string;
  modalForm?: IFormData[];
  formInitialValues?: IInitialValues;
  formSchema?: IFormSchema;
  editModalTitle?: string;
  cards: ICard[];
  searchValue: string;
  onSearchSubmit: (newSearchValue: string) => void;
  preloadChecksDataTable: IDataTable[];
  annualVettingsChecksDataTable: IDataTable[];
  preloadChecksColumnsTable: IColumnTable[];
  annualVettingsColumnsTable: IColumnTable[];
  onSearchClear: () => void;
  onTableRowClicked: (id: string) => void;
  updateFiltersData: (filters: IFormData[]) => void;
  emptyMsgTable: string;
  filtersData: IFormData[];
  openEditModal: boolean;
  handleOpenEditModal: () => void;
  handleCloseEditModal: () => void;
  handleEditModalSubmit: (values: IFormSchema) => void;
  deleteConfirmModalTitle?: string;
  deleteConfirmModalDescription?: string;
  closeConfirmModalDescription: string;
  deleteModalConfirmLabel?: string;
  deleteModalConfirmColor?: string;
  deleteModalCancelLabel?: string;
  handleEditModalDelete?: () => void;
  deleteModalButtonLabel?: string;
  deleteModalButtonIcon?: string;
  deleteModalButtonColor?: string;
  preloadChecksTableTableTotalPages: number;
  annualVettingsTableTableTotalPages: number;
  handleTablePaginationChange: (paginationIndex: number, isAnnualVetting: boolean) => void;
  activeTabName: string;
  handleChecksTabChanged: (checkName: string) => void;
  searchPlaceholder?: string;
}

interface DetailsTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomDetailsTabPanel = (props: DetailsTabPanelProps): React.JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className="simple-tabpanel"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const Details = (props: DetailsProps): JSX.Element => {
  const {
    title,
    subtitle,
    displayHeaderEditBtn,
    agentStatus,
    displayHeaderStatusBtn,
    modalForm,
    formInitialValues,
    formSchema,
    editModalTitle,
    cards,
    searchValue,
    onSearchSubmit,
    onSearchClear,
    preloadChecksDataTable,
    annualVettingsChecksDataTable,
    preloadChecksColumnsTable,
    annualVettingsColumnsTable,
    onTableRowClicked,
    filtersData,
    updateFiltersData,
    emptyMsgTable,
    openEditModal,
    handleEditModalSubmit,
    handleOpenEditModal,
    handleCloseEditModal,
    deleteConfirmModalTitle,
    closeConfirmModalDescription,
    deleteConfirmModalDescription,
    deleteModalConfirmLabel,
    deleteModalConfirmColor,
    deleteModalCancelLabel,
    handleEditModalDelete,
    deleteModalButtonLabel,
    deleteModalButtonIcon,
    deleteModalButtonColor,
    preloadChecksTableTableTotalPages,
    annualVettingsTableTableTotalPages,
    handleTablePaginationChange,
    activeTabName,
    handleChecksTabChanged,
    searchPlaceholder = 'Search by transporter, order number, Truck, Trailer',
  } = props;
  const { t } = useTranslation('common');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    activeTabName === ChecksFilters.ANNUALVETTINGS
      ? CHECKTYPE.ANNUALVETTINGS
      : CHECKTYPE.PRELOADCHECKS
  );
  const [Filters, setFilters] = useState(filtersData);
  const [searchValueSource, setSearchValueSource] = useState(searchValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setFilters(filtersData);
  }, [filtersData, updateFiltersData]);

  const checkedFilters = (): IOptions[] => {
    const checkedOptions: IOptions[] = [];
    filtersData
      .filter((item) => item.name !== 'status')
      .forEach((item) => {
        // Use filter to get options with checked set to true
        item.optionsData?.options.forEach((option) => {
          if (option.checked === true) {
            checkedOptions.push(option);
          }
        });
      });

    return checkedOptions;
  };

  const filterClose = (): void => {
    setOpenFilterModal(false);
  };

  const handleFilterDelete = (option: IOptions): void => {
    const updatedFilters = Filters.map((filterData) => {
      filterData.optionsData?.options.forEach((optionData) => {
        if (optionData.value === option.value) {
          optionData.checked = false;
        }
      });
      return filterData;
    });

    setFilters(updatedFilters);
    if (updateFiltersData != null) {
      updateFiltersData(updatedFilters);
    }
  };

  const filterHandleClick = (): void => {
    setOpenFilterModal(true);
  };

  const redirectToPrevPage = (): void => {
    window.history.back();
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string): void => {
    const tabName =
      Number(newValue) === CHECKTYPE.ANNUALVETTINGS
        ? ChecksFilters.ANNUALVETTINGS
        : ChecksFilters.PRELOADCHECKS;

    setActiveTab(Number(newValue));
    handleChecksTabChanged(tabName);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const searchHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValueSource(event.target.value);
  };

  const searchHandleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSearchSubmit(searchValueSource);
  };

  const searchHandleClick = (): void => {
    onSearchSubmit(searchValueSource);
  };

  const searchHandleClear = (): void => {
    setSearchValueSource('');
    onSearchClear();

    if (inputRef !== null && inputRef.current instanceof HTMLInputElement) {
      // Keep Focus On The Input
      inputRef.current?.focus();
    }
  };

  return (
    <div className="oryx-details">
      <div className="oryx-details__header">
        <div className="header-container">
          <div className="left-side">
            <ButtonComponent
              classes="no-padding btn-transparent"
              iconOnly={true}
              iconRound={false}
              iconUrl={IconLeft}
              iconAlt="icon-chevron-left"
              onClick={redirectToPrevPage}
            />
            <div className="description">
              <h1>{title}</h1>
              <p className="subtitle">{subtitle}</p>
            </div>
          </div>
          <div className="right-side">
            {displayHeaderStatusBtn === true && agentStatus !== undefined && (
              <div className={`agent-badge ${agentStatus}`}>
                <p>{t(agentStatus)}</p>
                <img src={IconAgent} alt="badge-agent" />
              </div>
            )}
            {displayHeaderEditBtn && (
              <ButtonComponent
                iconUrl={IconEdit}
                iconAlt="icon-edit"
                label={t('edit_information')}
                color="primary"
                onClick={handleOpenEditModal}
              />
            )}
          </div>
        </div>

        {checkedFilters().length > 0 && (
          <div className="filters-container">
            {checkedFilters().map((option) => (
              <Chip
                className="filter-chip"
                key={option.value}
                label={option.label}
                variant="outlined"
                onDelete={() => {
                  handleFilterDelete(option);
                }}
              />
            ))}
          </div>
        )}
      </div>

      <div className="oryx-details__body">
        {cards !== undefined && cards.length > 0 && DisplayCards(cards, t)}
        <Box className="details-tabs" sx={{ width: '100%' }}>
          <Box className="details-tabs__container" sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label={t('preload_checks')} {...a11yProps(1)} />
              <Tab label={t('annual_vettings')} {...a11yProps(1)} />
            </Tabs>
            <div className="right-side">
              <>
                <ButtonComponent
                  classes="no-padding"
                  iconOnly={true}
                  iconRound={false}
                  iconUrl={IconFilter}
                  iconAlt="icon-filter"
                  onClick={filterHandleClick}
                />
                <form onSubmit={searchHandleSubmit}>
                  <TextField
                    inputRef={inputRef}
                    value={searchValueSource}
                    className="search-input"
                    size="small"
                    placeholder={searchPlaceholder}
                    onChange={searchHandleChange}
                    InputProps={{
                      endAdornment: (
                        <div className="search-input__end">
                          {searchValueSource.length > 0 && (
                            <ButtonComponent
                              classes="no-padding"
                              iconOnly={true}
                              iconRound={true}
                              iconUrl={IconClear}
                              iconAlt="icon-search"
                              onClick={searchHandleClear}
                            />
                          )}

                          <ButtonComponent
                            classes="no-padding"
                            iconOnly={true}
                            iconRound={true}
                            iconUrl={IconSearch}
                            iconAlt="icon-search"
                            onClick={searchHandleClick}
                          />
                        </div>
                      ),
                    }}
                  />
                </form>
              </>
            </div>
          </Box>
          <CustomDetailsTabPanel value={activeTab} index={0}>
            <>
              {preloadChecksDataTable.length === 0 && <p className="no-data">{emptyMsgTable}</p>}
              {preloadChecksDataTable.length > 0 && (
                <div className="tabpanel-header">
                  <div
                    className={`oryx-details__table ${
                      preloadChecksDataTable.length === 0 ? 'empty-table' : ''
                    }`}
                  >
                    <Table
                      data={preloadChecksDataTable}
                      columns={preloadChecksColumnsTable}
                      emptyMsg={t('no_preload_checks_to_display')}
                      onRowClicked={onTableRowClicked}
                      totalPages={preloadChecksTableTableTotalPages}
                      handlePaginationChange={(pageIndex) => {
                        handleTablePaginationChange(pageIndex, false);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          </CustomDetailsTabPanel>
          <CustomDetailsTabPanel value={activeTab} index={1}>
            <>
              {annualVettingsChecksDataTable.length === 0 && <p className="no-data">{emptyMsgTable}</p>}
              {annualVettingsChecksDataTable.length > 0 && (
                <div className="tabpanel-header">
                  <div
                    className={`oryx-details__table ${
                      annualVettingsChecksDataTable.length === 0 ? 'empty-table' : ''
                    }`}
                  >
                    <Table
                      data={annualVettingsChecksDataTable}
                      columns={annualVettingsColumnsTable}
                      emptyMsg={t('no_annual_vettings_to_display')}
                      onRowClicked={onTableRowClicked}
                      totalPages={annualVettingsTableTableTotalPages}
                      handlePaginationChange={(pageIndex) => {
                        handleTablePaginationChange(pageIndex, true);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          </CustomDetailsTabPanel>
        </Box>
      </div>

      {/* Edit Modal */}
      {editModalTitle !== undefined &&
        modalForm !== undefined &&
        formSchema !== undefined &&
        formInitialValues !== undefined && (
          <AddEditModal
            modalTitle={editModalTitle}
            modalForm={modalForm}
            formSchema={formSchema}
            formInitialValues={formInitialValues}
            openModal={openEditModal}
            onClose={handleCloseEditModal}
            handleSubmit={handleEditModalSubmit}
            handleDelete={handleEditModalDelete}
            closeConfirmationModalTitle={t('are_you_sure')}
            closeConfirmationModalDescription={closeConfirmModalDescription}
            deleteConfirmModalTitle={deleteConfirmModalTitle}
            deleteConfirmModalDescription={deleteConfirmModalDescription}
            deleteModalConfirmLabel={deleteModalConfirmLabel}
            deleteModalConfirmColor={deleteModalConfirmColor}
            deleteModalCancelLabel={deleteModalCancelLabel}
            deleteButtonLabel={deleteModalButtonLabel}
            deleteButtonIcon={deleteModalButtonIcon}
            deleteButtonColor={deleteModalButtonColor}
          />
        )}

      {/* Filter Modal */}
      <FilterModal
        openModal={openFilterModal}
        onClose={filterClose}
        filtersData={filtersData}
        updateFiltersData={updateFiltersData}
      />
    </div>
  );
};

export default Details;
