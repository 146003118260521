import { ITrailer, ITrailerData, ITrailerPayload } from '../interfaces/Trailer.interface';
import { ITransporter } from '../interfaces/Transporter.interface';
import { axiosAuthInstance } from './Auth.service';

export const getTrailersList = async (
  limit: number,
  offset: number,
  transporterIds?: string[],
  productIds?: string[],
  searchQuery?: string
): Promise<ITrailerData> => {
  const client = await axiosAuthInstance();
  let queyParams = '';
  transporterIds?.forEach((filter) => {
    queyParams += `&transporterIds[]=${filter}`;
  });

  productIds?.forEach((filter) => {
    queyParams += `&dproductIds[]=${filter}`;
  });

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }

  const response = await client.get(`/trailers/list?limit=${limit}&offset=${offset}${queyParams}`);
  return response.data;
};

export const getTrailer = async (id: string): Promise<ITrailer> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/trailers/${id}`);
  return response.data;
};

export const createTrailer = async (payload: ITrailerPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.post('/trailers', payload);
  return response.data;
};

export const editTrailer = async (id: string, payload: ITrailerPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/trailers/${id}`, payload);
  return response.data;
};

export const deleteTrailer = async (id: string): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.delete(`/trailers/${id}`);
  return response.data;
};

export const GetTrailerTransporterList = async (affiliate: string, searchQuery?: string): Promise<ITransporter[]> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }

  const response = await client.get(`/trailers/transporter/list?limit=30&offset=0&affiliate=${affiliate}${queyParams}`);
  return response.data.data;
};