import { Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions
} from '../../interfaces/Form-Modal.interface';
import { IColumnTable, ITrailersDataTable } from '../../interfaces/Table.interface';
import { ITrailer, ITrailerPayload } from '../../interfaces/Trailer.interface';
import { GetTrailerTransporterList, createTrailer, getTrailersList } from '../../services/Trailer.service';
import AddEditModal from '../../shared/components/add-edit-modal/Add-Edit-Modal';
import Loader from '../../shared/components/Loader/Loader';
import Stats from '../../shared/components/stats/Stats';
import * as Yup from 'yup';
import ButtonComponent from '../../shared/components/button/Button';
import IconExport from './../../assets/icons/icon-export.svg';
import IconCross from './../../assets/icons/icon-cross.svg';
import {
  FetchFiltersTrailersProducts,
  FetchFiltersTrailersProductsListMethod,
  FetchFiltersTrailersTransporters,
  FetchFiltersTrailersTransportersListMethod,
  FiltersRouteParamsUrl,
  TrailerHandleSelectTransportersSearch,
  handleSelectProductsSearch
} from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';
import { GetAffiliates } from '../../services/Affiliate.service';

const formSchema: IFormSchema = Yup.object({
  affiliate: Yup.object().required(),
  transporter: Yup.object().required(),
  registration_number: Yup.string().required(),
  product: Yup.object().required(),
  capacity: Yup.string().required(),
  unit: Yup.object().required(),
  number_compartments: Yup.string().required(),
});

const formInitialValues: IInitialValues = {
  affiliate: '',
  transporter: '',
  registration_number: '',
  product: '',
  capacity: '',
  unit: '',
  number_compartments: '',
};

interface ITrailersProps {
  transporters: IOptions[];
  products: IOptions[];
}

const Trailers = (props: ITrailersProps): JSX.Element => {
  const [trailersListPagination, setTrailersListPagination] = useState<IPagination>();
  const [trailersDataTable, setTrailersDataTable] = useState<ITrailersDataTable[]>([]);
  const [trailersSearchValue, setTrailersSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const { TrailersModalForm } = Mock();
  const [TrailersForm, setTrailersForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(TrailersModalForm))
  );
  const [exportUrl, setExportUrl] = useState<string>();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const columnsTable: IColumnTable[] = [
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'affiliate',
      header: t('affiliate'),
    },
    {
      accessorKey: 'registration_number',
      header: t('registration_number'),
    },
    {
      accessorKey: 'product',
      header: t('product'),
    },
  ];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const productFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const [TrailersFitlers, setTrailersFitlers] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTrailersTransporters,
    },
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'product',
      type: 'text',
      placeholder: 'select_a_product',
      name: 'product',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTrailersProducts,
    },
  ]);

  /* METHOD EMITTING API CALLS */
  const fetchTrailersList = (offset: number): void => {
    setIsRequestInProgress(true);
    getTrailersList(10, offset, transporterFilters, productFilters, trailersSearchValue)
      .then(async (trailersList) => {
        setIsRequestInProgress(false);
        setTrailersListPagination(trailersList.pagination);
        // Method That Sets Trailers Array Data
        getTrailersDataTable(trailersList.data);
        setExportUrl(trailersList.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleCreateTrailer = (formData: IFormSchema): void => {
    setIsRequestInProgress(true);
    const trailerPayload: ITrailerPayload = {
      transporter: formData.transporter.value,
      affiliate: formData.affiliate.value,
      registration_number: formData.registration_number,
      product_ids: formData.product.value,
      capacity: formData.capacity,
      unit: formData.unit.value,
      number_compartments: formData.number_compartments,
    };

    createTrailer(trailerPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenModal(false);
        showSnackbar(t('trailer_has_been_created_successfully'), 'success');
        // Get Trailers List After Creating New Trailer To Fetch All The List With The New Created Trailer
        fetchTrailersList(0);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchTrailersList(offset);
  };

  const updateTrailersFiltersData = (newFilters: IFormData[]): void => {
    setTrailersFitlers(newFilters);
    const url = FiltersRouteParamsUrl(
      location.pathname,
      newFilters,
      undefined,
    );

    navigate(url);
  };

  /* Export Method */
  const TrailersExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const GetTrailerTransporterListListOptions = async (affiliate: string, searchQuery?: string): Promise<IOptions[]> => {
    const transporterList = await GetTrailerTransporterList(affiliate, searchQuery);
    return transporterList.map(transporter => {
      return { value: transporter.id, label: transporter.name };
    });
  };

  useEffect(() => {
    void SetFiltersData();
    fetchTrailersList(0);
    void SetModalForm();
  }, [props, location, trailersSearchValue]);

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersTrailersTransportersListMethod('0');
    const FiltersProductsOptionsData = await FetchFiltersTrailersProductsListMethod('0');
    
    const Filters = TrailersFitlers.map((filter) => {
      if (filter.name === 'transporter') {
        filter.optionsData = FiltersTransportersOptionsData;
        filter.optionsData.options = filter.optionsData.options.map((option) => {
          const found = transporterFilters?.find((id: string) => id === option.value);

          return {
            ...option,
            checked: found !== undefined, // Set checked to true if found in ids
          };
        });
      } else {
        filter.optionsData = FiltersProductsOptionsData
        filter.optionsData.options = filter.optionsData.options.map((option) => {
          const found = productFilters?.find((id: string) => id === option.value);

          return {
            ...option,
            checked: found !== undefined, // Set checked to true if found in ids
          };
        });
      }
      return filter;
    });

    setTrailersFitlers(Filters);
  };

  // This Method Is Trigered When Affiliate Select Change It's Value, So We Can Enable The Transporter Field
  // And Pass The Right Options For It Depending On Affiliate Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleAffiliateChange = async (option: IOptions): Promise<void> => {
    const formData = TrailersForm;
    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Make Transporter Field Enabled After Selecting Affiliate Value
      formData[transporterFieldIndex].disabled = false;
      // Get Affiliate Value
      const affiliateValue = option.value;
      // Fill Options Of Transporter Dropdown By Method GetTrailerTransporterListListOptions
      formData[transporterFieldIndex].options = await GetTrailerTransporterListListOptions(affiliateValue);
      // Passing Affiliate Value To Use It On Select Field Component To Load Options By API And Send Affiliate Value On API
      formData[transporterFieldIndex].relativeFieldValue = affiliateValue;
      formData[transporterFieldIndex].value = undefined;
    }
  }

  const SetModalForm = async (): Promise<void> => {
    const formData = TrailersForm;

    // Find the "Affiliate" field in the Form array
    const affiliateFieldIndex = formData.findIndex((field) => field.name === 'affiliate');
    if (affiliateFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[affiliateFieldIndex].options = await GetAffiliates();
      // Add Method To Handle Affiliate Change
      formData[affiliateFieldIndex].handSelectChange = handleAffiliateChange;
    }

    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Pass Search Product Method With It's result on the attribute method
      formData[transporterFieldIndex].CustomHandleSearchLoadOptions = TrailerHandleSelectTransportersSearch;
    }

    // Find the "product" field in the TrailersModalForm array
    const productFieldIndex = formData.findIndex((field) => field.name === 'product');
    if (productFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[productFieldIndex].options = props.products;
      // Pass Search Product Method With It's result on the attribute method
      formData[productFieldIndex].handleSearchLoadOptions = handleSelectProductsSearch;
    }

    setTrailersForm(formData);
  };

  /* OTHER METHODS */
  const getTrailersDataTable = (trailersData: ITrailer[]): void => {
    const trailersDataTable: ITrailersDataTable[] = trailersData.map((trailer) => {
      return {
        id: trailer.id,
        transporter: trailer.transporter,
        affiliate: trailer.affiliate,
        registration_number: trailer.registration_number.trim().length > 0 ? trailer.registration_number : '-',
        product: trailer.products.length > 0 ? trailer.products[0].description : '-',
      };
    });

    setTrailersDataTable(trailersDataTable);
  };

  /* Search Methods */
  const handleSubmitSearch = (value: string): void => {
    setTrailersSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setTrailersSearchValue('');
  };

  /* Modal Open Close Methods */
  const openAddTrailerModal = (): void => {
    setOpenModal(true);
  };

  const modalClose = (): void => {
    setOpenModal(false);
  };

  /* Redirection To Trailers Details */
  const trailersRowClicked = (trailerId: string): void => {
    navigate(`/trailers/details/${trailerId}`);
  };

  /* Method That Displays Buttons On Header's page */
  const setTrailersHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={TrailersExport}
          />
        )}

        <ButtonComponent
          iconUrl={IconCross}
          iconAlt="icon-cross"
          label={t('add_trailer')}
          color="primary"
          onClick={openAddTrailerModal}
        />
      </>
    );
  };

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={trailersSearchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setTrailersHeaderButtons()}
        dataTable={trailersDataTable}
        columnsTable={columnsTable}
        emptyMsgTable={isRequestInProgress === false ? t('no_trailers_to_display') : ''}
        onTableRowClicked={trailersRowClicked}
        filtersData={TrailersFitlers}
        updateFiltersData={updateTrailersFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={
          trailersListPagination !== undefined ? trailersListPagination.totalPages : 0
        }
        searchPlaceholder={t('trailer_search_placeholder')}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />

      {/* Add Modal */}
      <AddEditModal
        modalTitle={t('add_trailer')}
        modalForm={TrailersForm}
        formSchema={formSchema}
        formInitialValues={formInitialValues}
        openModal={openModal}
        onClose={modalClose}
        handleSubmit={handleCreateTrailer}
        closeConfirmationModalTitle={t('are_you_sure')}
        closeConfirmationModalDescription={t('trailer_info_will_be_lost.')}
      />
    </div>
  );
};

export default Trailers;
