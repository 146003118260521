import React, { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Stats from '../../shared/components/stats/Stats';
import Loader from '../../shared/components/Loader/Loader';
import {
  FetchFiltersTransporters,
  FetchFiltersAgents,
  FetchFiltersProducts,
  FilterAnnualVettingsDataTableSource,
  GetPreloadChecksDataTable,
  InitFiltersData,
  FiltersRouteParamsUrl,
} from '../../services/Helper.service';
import { IAnnualVettingsDataTable } from '../../interfaces/Table.interface';
import { IAnnualVetting } from '../../interfaces/PreloadCheck.interface';
import { getAnnualVettingsList } from '../../services/PreloadCheck.service';
import Mock from '../../constants/Mock';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import ButtonComponent from '../../shared/components/button/Button';
import IconExport from './../../assets/icons/icon-export.svg';
import { ExportData } from '../../services/Export.service';
import { IFormData, IOptionsData } from '../../interfaces/Form-Modal.interface';
import FiltersMock from '../../constants/Fitlers';

interface IAnnualVettingProps {
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const AnnualVettings = (props: IAnnualVettingProps): React.JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [annualVettingsListPagination, setAnnualVettingsListPagination] = useState<IPagination>();
  const [annualVettingsList, setAnnualVettingsList] = useState<IAnnualVetting[]>([]);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const [dataTable, setDataTable] = useState<IAnnualVettingsDataTable[]>([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField, PreloadCheckDateField } =
    FiltersMock();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [exportUrl, setExportUrl] = useState<string>();
  const { AnnualVettingListColumns } = Mock();
  const AnnualVettingFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
    PreloadCheckDateField
  ];
  const [AnnualVettingFilters, setAnnualVettingFilters] =
    useState<IFormData[]>(AnnualVettingFiltersData);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parentTab = searchParams.get('parentTab');
  const statusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());

  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());

  const productFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());

  const agentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());

  const dateFilters = searchParams
    .get('dateFilters')
    ?.split(',')
    .map((item) => item.trim())[0];

  const rejectionType = searchParams.get('rejectionType');
  const onlyOneStatusAllowed = Boolean(searchParams.get('onlyOneStatusAllowed'));

  /* API CALLS METHODS */
  const fetchAnnualVettingsList = (offset: number): void => {
    setIsRequestInProgress(true);
    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      statusFilters,
      rejectionType,
      transporterFilters,
      productFilters,
      agentFilters,
      undefined,
      undefined,
      undefined,
      dateFilters
    )
      .then((annualVettingsList) => {
        setIsRequestInProgress(false);
        setAnnualVettingsList(annualVettingsList.data);
        setAnnualVettingsListPagination(annualVettingsList.pagination);
        // Method That Sets Annual Vettings Array Data
        getAnnualVettingsDataTable(annualVettingsList.data);
        setExportUrl(annualVettingsList.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const updateAnnualVettingsFiltersData = (newFilters: IFormData[]): void => {
    setAnnualVettingFilters(newFilters);
    const url = FiltersRouteParamsUrl(
      location.pathname,
      newFilters,
      undefined,
      parentTab,
      onlyOneStatusAllowed
    );

    navigate(url);
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchAnnualVettingsList(offset);
  };

  /* Export Method */
  const annualVettingsExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  useEffect(() => {
    SetFiltersData();
    fetchAnnualVettingsList(0);
  }, [props, location, searchValue]);

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      AnnualVettingFiltersData,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      statusFilters,
      transporterFilters,
      productFilters,
      agentFilters,
      dateFilters,
      onlyOneStatusAllowed
    );
    setAnnualVettingFilters(InitFiltersOptions);
  };

  const annualVettingsRowClicked = (preloadCheckId: string): void => {
    let queryParams = '';
    if (parentTab !== null) {
      queryParams += `&parentTab=${parentTab}`;
    } else {
      queryParams += '&parentTab=annual-vettings';
    }

    navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true${queryParams}`);
  };

  const getAnnualVettingsDataTable = (annualVettingList: IAnnualVetting[]): void => {
    setDataTable(GetPreloadChecksDataTable(annualVettingList));
  };

  /* Search Methods */
  const handleSubmitSearch = (value: string): void => {
    setSearchValue(value);
    setDataTable(FilterAnnualVettingsDataTableSource(value, dataTable));
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
    // Method That Sets Annual Vettings Array Data
    getAnnualVettingsDataTable(annualVettingsList);
  };

  /* Method That Displays Buttons On Header's page */
  const setHeaderButtons = (): React.JSX.Element => {
    return (
      <ButtonComponent
        iconUrl={IconExport}
        iconAlt="icon-export"
        label={t('export')}
        color="secondary btn-transparent btn-outlined"
        onClick={annualVettingsExport}
      />
    );
  };

  const GetPageTitle = (): string | undefined => {
    // IF onlyOneStatusAllowed is true the statusFilters param will be one value not an array of values
    if (onlyOneStatusAllowed && statusFilters !== undefined) {
      return t(statusFilters[0]);
    } else if (rejectionType !== null && rejectionType !== undefined) {
      return t(rejectionType);
    } else {
      return undefined;
    }
  };

  const GetPageSubTitle = (): string | undefined => {
    if (onlyOneStatusAllowed) {
      return t('annual_vettings');
    } else if (rejectionType !== null && rejectionType !== undefined) {
      return t('annual_vettings_rejection_types');
    } else {
      return undefined;
    }
  };

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        title={GetPageTitle()}
        subtitle={GetPageSubTitle()}
        displayHeaderLeftSideBlock={GetPageTitle() !== undefined}
        searchValue={searchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={exportUrl !== undefined ? setHeaderButtons() : undefined}
        dataTable={dataTable}
        columnsTable={AnnualVettingListColumns}
        searchPlaceholder={t('annual_vettings_search_placeholder')}
        emptyMsgTable={isRequestInProgress === false ? t('no_annual_vettings_to_display') : ''}
        onTableRowClicked={annualVettingsRowClicked}
        filtersData={AnnualVettingFilters}
        updateFiltersData={updateAnnualVettingsFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={
          annualVettingsListPagination !== undefined ? annualVettingsListPagination.totalPages : 0
        }
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default AnnualVettings;
