import React from 'react';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import './Button.scss';

interface ButtonProps {
  url?: string;
  alt?: string;
  variant?: string;
  // contained Or outlined
  color?: string;
  iconOnly?: boolean;
  iconRound?: boolean;
  iconUrl?: string;
  iconAlt?: string;
  label?: string;
  badgeNumber?: string;
  classes?: string;
  disabled?: boolean;
  onClick: () => void;
}

const ButtonComponent = (props: ButtonProps): React.JSX.Element => {
  const { iconOnly, iconRound, iconUrl, iconAlt, label, color, classes, badgeNumber, disabled } =
    props;
  const handleClick = (): void => {
    props.onClick();
  };

  const badgeNumberMethod = (): React.JSX.Element | null => {
    if (badgeNumber != null) {
      return <span className="badge">{badgeNumber}</span>;
    }

    return null;
  };

  const getImgMethod = (): React.JSX.Element | null => {
    if (iconUrl != null) {
      return <img src={iconUrl} alt={iconAlt} />;
    }

    return null;
  };

  const getBtnRoundClasses = (): string => {
    const buttonClasses = ['btn'];
    if (color !== undefined) {
      buttonClasses.push(`btn-${color}`);
    }
    if (iconRound ?? false) {
      buttonClasses.push('btn-round');
    } else {
      buttonClasses.push('btn-square');
    }

    if (classes !== undefined) {
      buttonClasses.push(classes);
    }

    const className = buttonClasses.join(' ');
    return className;
  };

  const getBtnTextClasses = (): string => {
    const className = `btn${color !== undefined ? ` btn-${color}` : ''}${
      classes !== undefined ? ` ${classes}` : ''
    }`;
    return className;
  };

  if (iconOnly != null && iconOnly) {
    return (
      <IconButton aria-label={iconAlt} className={getBtnRoundClasses()} onClick={handleClick}>
        <img src={iconUrl} alt={iconAlt} />
      </IconButton>
    );
  } else {
    return (
      <Button onClick={handleClick} className={getBtnTextClasses()} disabled={disabled}>
        {label}
        {badgeNumberMethod()}
        {getImgMethod()}
      </Button>
    );
  }
};

export default ButtonComponent;
