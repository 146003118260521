import { IDriverData, IDriverDetail, IDriverPayload } from '../interfaces/Driver.interface';
import { ITransporter } from '../interfaces/Transporter.interface';
import { axiosAuthInstance } from './Auth.service';

// ct=false Query Param Means Not Filtering By Country
export const getDriversList = async (
  limit: number,
  offset: number,
  driverIds?: string[],
  searchQuery?: string
): Promise<IDriverData> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  driverIds?.forEach((filter) => {
    queyParams += `&transporterIds[]=${filter}`;
  });

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }

  const response = await client.get(`/driver/list?ct=false&limit=${limit}&offset=${offset}${queyParams}`);
  return response.data;
};

export const getDriver = async (id: string): Promise<IDriverDetail> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/driver/${id}`);
  return response.data;
};

export const createDriver = async (payload: IDriverPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.post('/driver', payload);
  return response.data;
};

export const editDriver = async (id: string, payload: IDriverPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/driver/${id}`, payload);
  return response.data;
};

export const deleteDriver = async (id: string): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.delete(`/driver/${id}`);
  return response.data;
};

export const GetDriverTransporterList = async (affiliate: string, searchQuery?: string): Promise<ITransporter[]> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }


  const response = await client.get(`/driver/transporter/list?limit=30&offset=0&affiliate=${affiliate}${queyParams}`);
  return response.data.data;
};

