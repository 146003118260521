import React, { useEffect, useState } from 'react';
import './Textfield.scss';
import { InputAdornment, InputLabel, TextField } from '@mui/material';
import IconEye from './../../../assets/icons/icon-eye.svg';
import IconEyeSlash from './../../../assets/icons/icon-eye-slash.svg';
import { useTranslation } from 'react-i18next';

interface ITextFieldProps {
  label: string;
  name: string;
  placeholder: string;
  field: any;
  form: any;
  type?: string;
  disabled?: boolean;
  value?: string;
}

const Textfield = ({
  label,
  name,
  placeholder,
  field,
  form,
  type,
  disabled,
  value,
}: ITextFieldProps): React.JSX.Element => {
  const [showPwd, setShowPwd] = useState(false);
  const { t } = useTranslation('common');

  const getHelperText = (): string => {
    return IsError() ? t('information_is_required') : '';
  };

  const IsError = (): boolean => {
    return Boolean(form.touched[name]) && Boolean(form.errors[name]);
  };

  useEffect(() => {
    if (name === 'login_id') {
      form.setFieldValue('login_id', value);
    }
  }, [value]);

  return (
    <div className="block-textfield">
      <InputLabel>{label}</InputLabel>
      <TextField
        className={`textfield 
          ${IsError() ? 'error' : ''} 
          ${disabled === true ? 'disabled' : ''}`}
        fullWidth
        placeholder={placeholder}
        {...field}
        error={Boolean(form.touched[name])}
        helperText={getHelperText()}
        value={value !== undefined ? value : field.value}
        type={type === 'password' && showPwd ? 'text' : type}
        InputProps={{
          disabled,
          endAdornment: type !== undefined && type === 'password' && (
            <InputAdornment position="end">
              <img
                className="icon-eye"
                src={showPwd ? IconEyeSlash : IconEye}
                alt="icon-eye"
                onClick={() => {
                  setShowPwd(!showPwd);
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default Textfield;
