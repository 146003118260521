import React from 'react';
import { useMsal } from '@azure/msal-react';
import TrucksComposition from './../../assets/images/trucks-composition.png';
import OryxLogos from './../../assets/images/oryx-logos.png';
import './Login.scss';
import { Grid } from '@mui/material';
import ButtonComponent from '../../shared/components/button/Button';
import { loginRequest } from '../../config/authConfig';
import { useTranslation } from 'react-i18next';

const Login = (): React.JSX.Element => {
  const { t } = useTranslation('common');
  const { instance } = useMsal();

  const handleLogin = (): void => {
    void instance.loginRedirect(loginRequest);
  };

  return (
    <div className="login-page">
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className="left-side">
            <img className="trucks-composition" src={TrucksComposition} alt="trucks-composition" />
            <img className="oryx-logos" src={OryxLogos} alt="oryx-logos" />
          </div>
        </Grid>
        <Grid className="right-side-container" item xs={12} sm={6}>
          <div className="right-side">
            <h1>{t('welcome_to_oryx')}</h1>
            <p>{t('login_sso_description')}</p>
            <ButtonComponent
              onClick={handleLogin}
              label={t('login_sso')}
              color="secondary"
              classes="btn-pl"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
