import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './TruckDetails.scss';
import * as Yup from 'yup';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions,
  IOptionsData,
} from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IAnnualVettingsDataTable,
  IColumnTable,
  IPreloadChecksDataTable,
} from '../../interfaces/Table.interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  FetchFiltersAgents,
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  InitFiltersData,
  TruckHandleSelectTransportersSearch,
} from '../../services/Helper.service';
import { GetTruckTransporterListOptions, deleteTruck, editTruck, getTruck } from '../../services/Truck.service';
import { ITruckDetail, ITruckPayload } from '../../interfaces/Truck.interface';
import Loader from '../../shared/components/Loader/Loader';
import { Snackbar } from '@mui/material';
import { useSnackbar } from '../../services/SnackbarUtils';
import FiltersMock from '../../constants/Fitlers';
import { ChecksFilters } from '../../constants/Dashboard';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';
import { GetAffiliates } from '../../services/Affiliate.service';

interface ITrucksProps {
  transporters: IOptions[];
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const TruckDetails = (props: ITrucksProps): JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [truckPreloadChecksListPaginationTotal, setTruckPreloadChecksListPaginationTotal] =
    useState(0);
  const [truckannualVettingsListPaginationTotal, setTruckAnnualVettingsPaginationTotal] =
    useState(0);
  const [preloadChecksDataTable, setPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [annualVettingsDataTable, setAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField } =
    FiltersMock();
  const [openEditModal, setOpenEditModal] = useState(false);
  const PreloadCheckFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
  ];
  const [PreloadCheckFilters, setPreloadCheckFilters] =
    useState<IFormData[]>(PreloadCheckFiltersData);

  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState<IInitialValues>({
    truck_number: '',
    transporter: '',
  });
  const [truckDetails, setTruckDetails] = useState<ITruckDetail>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [cardTruckInfo, setCardTruckInfo] = useState<ICardItem[]>([]);
  const { TrucksModalForm } = Mock();
  const [TrucksForm, setTrucksForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(TrucksModalForm))
  );
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const truckStatusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const truckTransporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const truckProductFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const truckAgentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());

  const formSchema: IFormSchema = Yup.object({
    truck_number: Yup.string().required(),
    transporter: Yup.object().required(),
  });

  const { PreloadCheckListColumns, AnnualVettingListColumns } = Mock();

  const cards: ICard[] = [
    {
      title: t('main_information'),
      card: cardTruckInfo,
    },
  ];

  /* METHOD EMITTING API CALLS */
  /* Get Truck Data Method */
  const fetchTruck = (): void => {
    setIsRequestInProgress(true);
    getTruck(params.id as string)
      .then(async (truckData) => {
        setIsRequestInProgress(false);
        setTruckDetails(truckData);
        setTruckPreloadChecksListPaginationTotal(0);
        setTruckAnnualVettingsPaginationTotal(0);
        // Method That Sets Truck Form Values
        setTruckFormValues(truckData);
        getCardTruckInfo(truckData);
        // Find the "transporter" field in the Form array
        const transporterFieldIndex = TrucksForm.findIndex((field) => field.name === 'transporter');
        if (transporterFieldIndex !== -1) {
          TrucksForm[transporterFieldIndex].disabled = false;
          // Fill Options Of Transporter Dropdown By Method GetTruckTransporterListOptions
          TrucksForm[transporterFieldIndex].options = await GetTruckTransporterListOptions(truckData.affiliate);
        }
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const truckId = [params.id as string];
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      truckStatusFilters,
      null,
      truckTransporterFilters,
      truckProductFilters,
      truckAgentFilters,
      undefined,
      undefined,
      truckId
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setTruckPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);

        // Method That Sets Preload Checks Array Data
        setPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const truckId = [params.id as string];
    setIsRequestInProgress(true);

    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      truckStatusFilters,
      null,
      truckTransporterFilters,
      truckProductFilters,
      truckAgentFilters,
      undefined,
      undefined,
      truckId
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setTruckAnnualVettingsPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Delete Truck Method */
  const handleDeleteTruck = (): void => {
    setIsRequestInProgress(true);
    deleteTruck(params.id as string)
      .then(() => {
        setIsRequestInProgress(false);

        setOpenEditModal(false);
        showSnackbar(t('truck_has_been_deleted_successfully'), 'success');

        setTimeout(() => {
          window.history.back();
        }, 1000);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Edit Truck Method */
  const handleEditTruck = (truckForm: IFormSchema): void => {
    setIsRequestInProgress(true);
    const truckPayload: ITruckPayload = {
      truck_number: truckForm.truck_number,
      transporter: truckForm.transporter.label,
    };

    editTruck(params.id as string, truckPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenEditModal(false);
        showSnackbar(t('truck_has_been_edited_successfully'), 'success');
        fetchTruck();
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  useEffect(() => {
    fetchTruck();
  }, []);

  useEffect(() => {
    void SetModalForm();
    SetFiltersData();
    /* GET CHECKS DATA */
    GetChecksData();
  }, [props, location, activeTab, searchValue]);

  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      PreloadCheckFilters,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      truckStatusFilters,
      truckTransporterFilters,
      truckProductFilters,
      truckAgentFilters
    );
    setPreloadCheckFilters(InitFiltersOptions);
  };

  // This Method Is Trigered When Affiliate Select Change It's Value, So We Can Enable The Transporter Field
  // And Pass The Right Options For It Depending On Affiliate Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleAffiliateChange = async (option: IOptions): Promise<void> => {
    const formData = TrucksForm;
    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Get Affiliate Value
      const affiliateValue = option.value;
      // Fill Options Of Transporter Dropdown By Method GetTruckTransporterListOptions
      formData[transporterFieldIndex].options = await GetTruckTransporterListOptions(affiliateValue);
      // Passing Affiliate Value To Use It On Select Field Component To Load Options By API And Send Affiliate Value On API
      formData[transporterFieldIndex].relativeFieldValue = affiliateValue;
    }
  }

  const SetModalForm = async (): Promise<void> => {
    const formData = TrucksForm;
    // Find the "affiliate" field in the Form array
    const affiliateFieldIndex = formData.findIndex((field) => field.name === 'affiliate');
    if (affiliateFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[affiliateFieldIndex].options = await GetAffiliates();
      // Add Method To Handle Affiliate Change
      formData[affiliateFieldIndex].handSelectChange = handleAffiliateChange;
    }

    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      formData[transporterFieldIndex].disabled = false;
      // Fill Options Of Transporter Dropdown By Method GetTruckTransporterListOptions
      if (truckDetails !== undefined) {
        formData[transporterFieldIndex].options = await GetTruckTransporterListOptions(truckDetails.affiliate);
      }
      // Pass Search Product Method With It's result on the attribute method
      formData[transporterFieldIndex].CustomHandleSearchLoadOptions = TruckHandleSelectTransportersSearch;
    }

    setTrucksForm(formData);
  };

  /* Fill Initial Values Truck's Form Method */
  const setTruckFormValues = (truckDetails: ITruckDetail): void => {
    const initialValues = {
      truck_number: truckDetails.truck_number,
      transporter: { label: truckDetails.transporter, value: truckDetails.transporter },
      affiliate: { label: truckDetails.affiliate, value: truckDetails.affiliate },
    };
    setFormInitialValues(initialValues);
  };

  const editModalBtnClicked = (): void => {
    setOpenEditModal(true);
  };

  const editModalBtnClose = (): void => {
    setOpenEditModal(false);
  };

  /* Fill Details Card Method */
  const getCardTruckInfo = (truckData: ITruckDetail): void => {
    const cardTruckInfo: ICardItem[] = [
      {
        label: 'truck_number',
        value: truckData.truck_number,
      },
      {
        label: 'transporter',
        value: truckData.transporter,
      },
      {
        label: 'affiliate',
        value: truckData.affiliate,
      },
    ];

    setCardTruckInfo(cardTruckInfo);
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const truckRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=trucks`);
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=trucks`);
    }
  };

  const updatePreloadChecksFiltersData = (newFilters: IFormData[]): void => {
    setPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, PreloadCheckFilters, tabName);
    navigate(url);
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}

      {truckDetails !== undefined && (
        <Details
          title={truckDetails.truck_number}
          subtitle={t('trucks')}
          cards={cards}
          displayHeaderEditBtn={true}
          editModalTitle={t('edit_truck_info')}
          modalForm={TrucksForm}
          formInitialValues={formInitialValues}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksDataTable={preloadChecksDataTable}
          annualVettingsChecksDataTable={annualVettingsDataTable}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={truckPreloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={truckannualVettingsListPaginationTotal}
          filtersData={PreloadCheckFilters}
          updateFiltersData={updatePreloadChecksFiltersData}
          onTableRowClicked={truckRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          openEditModal={openEditModal}
          handleOpenEditModal={editModalBtnClicked}
          handleCloseEditModal={editModalBtnClose}
          handleEditModalSubmit={handleEditTruck}
          handleEditModalDelete={handleDeleteTruck}
          deleteConfirmModalTitle={t('are_you_sure')}
          deleteConfirmModalDescription={t('truck_info_will_be_lost')}
          closeConfirmModalDescription={t('truck_info_will_be_lost')}
          deleteModalConfirmLabel={t('delete_truck')}
          deleteModalCancelLabel={t('cancel')}
          deleteModalButtonLabel={t('delete_truck')}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('truck_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default TruckDetails;
