export const Status = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDINGREQUEST: 'pending_request',
  HOLD: 'hold',
  TODO: 'to_do',
};

export const ComplianceLevelConst = {
  LEVEL_1: 'compliance_level_1',
  LEVEL_2: 'compliance_level_2',
  LEVEL_3: 'compliance_level_3',
  LEVEL_4: 'compliance_level_4',
};

export const AgentStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
