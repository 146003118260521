import { Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import ButtonComponent from '../button/Button';
import './Confirm-Dialog.scss';

interface IConfirmModalProps {
  openModal: boolean;
  title: string;
  description: string;
  handleConfirm: () => void;
  handleClose: () => void;
  confirmLabel: string;
  cancelLabel: string;
  confirmButtonColor?: string;
}

const ConfirmDialog = (props: IConfirmModalProps): React.JSX.Element => {
  const {
    openModal,
    title,
    description,
    handleConfirm,
    handleClose,
    confirmLabel,
    cancelLabel,
    confirmButtonColor,
  } = props;

  return (
    <Dialog onClose={handleClose} open={openModal} className="confirm-modal">
      <div className="confirm-modal-header">
        <DialogTitle>{title}</DialogTitle>
        <p>{description}</p>
      </div>

      <div className="confirm-modal-footer">
        <ButtonComponent label={cancelLabel} color="secondary btn-outlined" onClick={handleClose} />

        <ButtonComponent
          label={confirmLabel}
          color={confirmButtonColor !== undefined ? confirmButtonColor : 'tertiary'}
          onClick={handleConfirm}
        />
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
