import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OryxLogo from './../../../assets/images/oryx-logo.png';
import IconHome from './../../../assets/icons/icon-home.svg';
import IconTruck from './../../../assets/icons/icon-truck.svg';
import IconTeam from './../../../assets/icons/icon-team.svg';
import IconDocument from './../../../assets/icons/icon-document.svg';
import IconTrailer from './../../../assets/icons/icon-trailer.svg';
import IconLogout from './../../../assets/icons/icon-logout.svg';
import './Tabs.scss';
import ButtonComponent from '../button/Button';
import { useMsal } from '@azure/msal-react';
import { IUser } from '../../../interfaces/User.interface';
import Cookies from 'js-cookie';

interface ITabsProps {
  currentUser: IUser;
  userDeports: string[];
  index?: string;
  handleEditCurrentUserDeport: (deport: string) => void;
}

interface ITabs {
  tabUrl: string;
  label: string;
  iconName: string;
  active: boolean;
}

const Tabs = (props: ITabsProps): React.JSX.Element => {
  const { instance } = useMsal();
  const { t, i18n } = useTranslation('common');
  const Tabs: ITabs[] = [
    {
      tabUrl: '/dashboard',
      label: t('dashboard'),
      iconName: IconHome,
      active: true,
    },
    {
      tabUrl: '/preload-checks',
      label: t('preload_checks'),
      iconName: IconDocument,
      active: false,
    },
    {
      tabUrl: '/annual-vettings',
      label: t('annual_vettings'),
      iconName: IconDocument,
      active: false,
    },
    {
      tabUrl: '/trucks',
      label: t('trucks'),
      iconName: IconTruck,
      active: false,
    },
    {
      tabUrl: '/drivers',
      label: t('drivers'),
      iconName: IconTeam,
      active: false,
    },
    {
      tabUrl: '/agents',
      label: t('agents'),
      iconName: IconTeam,
      active: false,
    },
    {
      tabUrl: '/transporters',
      label: t('transporters'),
      iconName: IconDocument,
      active: false,
    },
    {
      tabUrl: '/trailers',
      label: t('trailers'),
      iconName: IconTrailer,
      active: false,
    },
  ];
  const [tabsState, setTabsState] = useState<ITabs[]>(Tabs);
  const [selectValue, setSelectValue] = useState('');
  const [selectLangValue, setSelectLangValue] = useState(
    localStorage.getItem('OryxLang') !== null ? localStorage.getItem('OryxLang') : 'en'
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parentUrl = searchParams.get('parentTab');

  const handleChange = (event: any): void => {
    setSelectValue(event.target.value);
    props.handleEditCurrentUserDeport(event.target.value);
  };

  const handleSelecLangChange = (event: any): void => {
    const selectLang = event.target.value;
    localStorage.setItem('OryxLang', selectLang);
    setSelectLangValue(selectLang);
    void i18n.changeLanguage(selectLang);
  };

  useEffect(() => {
    setSelectValue(props.currentUser?.deport);
    // Update the document title using the browser API
    toggleTab();
  }, [location, selectLangValue, selectValue]);

  const toggleTab = (): void => {
    const urlFirstPart =
      parentUrl !== null ? '/' + parentUrl : '/' + location.pathname.split('/')[1];
    const TabsArr = Tabs.map((tab) => {
      return { ...tab, active: tab.tabUrl === urlFirstPart };
    });
    setTabsState([...TabsArr]);
  };

  const getTabs = (): ITabs[] => {
    return tabsState;
  };

  const logoutClicked = (): void => {
    void instance.logout({
      onRedirectNavigate: () => {
        localStorage.clear();
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        // Return false to prevent the redirect.
        return false;
      },
    });
  };

  return (
    <div className="tabs-container">
      <>
        <div className="tabs-top">
          <img className="logo" src={OryxLogo} alt="oryx-logo" />
          <Select
            className="tabs-select"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={selectValue?.toLocaleLowerCase()}
            onChange={handleChange}
            MenuProps={{
              classes: { paper: 'oryx-select__paper' },
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
          >
            {props.userDeports.map((deport) => {
              return (
                <MenuItem key={deport} value={deport.toLocaleLowerCase()}>
                  {deport}
                </MenuItem>
              );
            })}
          </Select>

          <div className="block-tabs">
            {getTabs().map((tab, index) => {
              if (props.index === index.toString()) {
                tab.active = true;
              }
              return (
                <Link
                  onClick={() => {
                    toggleTab();
                  }}
                  to={tab.tabUrl}
                  key={tab.label}
                  className={`tab ${tab.active ? 'active' : ''}`}
                >
                  <img className="tab-icon" src={tab.iconName} alt={tab.label} />
                  <span>{tab.label}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="tabs-bottom">
          <Select
            className="lang-select"
            labelId="lang-select-standard-label"
            id="lang-select-standard"
            value={selectLangValue}
            onChange={handleSelecLangChange}
            MenuProps={{
              classes: { paper: 'oryx-select__paper lang-select__paper' },
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
          >
            <MenuItem value="en">{t('english')}</MenuItem>
            <MenuItem value="fr">{t('french')}</MenuItem>
          </Select>
          <div className="block-user-name">
            {props.currentUser !== undefined && (
              <p className="name">{`${props.currentUser.name} ${props.currentUser.last_name}`}</p>
            )}

            <ButtonComponent
              iconOnly={true}
              iconRound={true}
              iconUrl={IconLogout}
              iconAlt="logOut"
              onClick={logoutClicked}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default Tabs;
