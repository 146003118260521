import React, { useEffect, useState } from 'react';
import { IPreloadChecksDataTable } from '../../interfaces/Table.interface';
import Stats from '../../shared/components/stats/Stats';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFormData, IOptionsData } from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import Mock from '../../constants/Mock';
import {
  FetchFiltersAgents,
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FilterPreloadChecksDataTableSource,
  FiltersRouteParamsUrl,
  GetPreloadChecksDataTable,
  InitFiltersData,
} from '../../services/Helper.service';
import { getPreloadChecksList } from '../../services/PreloadCheck.service';
import { IPreloadCheck } from '../../interfaces/PreloadCheck.interface';
import Loader from '../../shared/components/Loader/Loader';
import { Snackbar } from '@mui/material';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import ButtonComponent from '../../shared/components/button/Button';
import IconExport from './../../assets/icons/icon-export.svg';
import { ExportData } from '../../services/Export.service';
import FiltersMock from '../../constants/Fitlers';

interface IPreloadCheckProps {
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const PreloadChecks = (props: IPreloadCheckProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [preloadChecksListPagination, setPreloadChecksListPagination] = useState<IPagination>();
  const [preloadChecksList, setPreloadChecksList] = useState<IPreloadCheck[]>([]);
  const [dataTable, setDataTable] = useState<IPreloadChecksDataTable[]>([]);
  const { t } = useTranslation('common');
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField, PreloadCheckDateField } =
    FiltersMock();
  const PreloadCheckFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
    PreloadCheckDateField,
  ];
  const [PreloadCheckFilters, setPreloadCheckFilters] =
    useState<IFormData[]>(PreloadCheckFiltersData);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parentTab = searchParams.get('parentTab');
  const preloadCheckStatusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());

  const preloadCheckTransporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());

  const preloadCheckProductFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());

  const preloadCheckAgentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());

  const preloadCheckDateFilters = searchParams
    .get('dateFilters')
    ?.split(',')
    .map((item) => item.trim())[0];

  const rejectionType = searchParams.get('rejectionType');
  const onlyOneStatusAllowed = Boolean(searchParams.get('onlyOneStatusAllowed'));

  const [exportUrl, setExportUrl] = useState<string>();
  const { PreloadCheckListColumns } = Mock();

  /* API CALLS METHODS */
  const fetchPreloadChecksList = (offset: number): void => {
    setIsRequestInProgress(true);
    getPreloadChecksList(
      10,
      offset,
      searchValue,
      preloadCheckStatusFilters,
      rejectionType,
      preloadCheckTransporterFilters,
      preloadCheckProductFilters,
      preloadCheckAgentFilters,
      undefined,
      undefined,
      undefined,
      undefined,
      preloadCheckDateFilters
    )
      .then((preloadChecksList) => {
        setIsRequestInProgress(false);
        setPreloadChecksList(preloadChecksList.data);
        setPreloadChecksListPagination(preloadChecksList.pagination);
        // Method That Sets Preload Checks Array Data
        getPreloadChecksDataTable(preloadChecksList.data);
        setExportUrl(preloadChecksList.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const updatePreloadChecksFiltersData = (newFilters: IFormData[]): void => {
    setPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(
      location.pathname,
      newFilters,
      undefined,
      parentTab,
      onlyOneStatusAllowed
    );

    navigate(url);
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchPreloadChecksList(offset);
  };

  /* Export Method */
  const preloadChecksExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  useEffect(() => {
    SetFiltersData();
    fetchPreloadChecksList(0);
  }, [props, location, searchValue, onlyOneStatusAllowed]);

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      PreloadCheckFiltersData,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      preloadCheckStatusFilters,
      preloadCheckTransporterFilters,
      preloadCheckProductFilters,
      preloadCheckAgentFilters,
      preloadCheckDateFilters,
      onlyOneStatusAllowed
    );
    setPreloadCheckFilters(InitFiltersOptions);
  };

  /* OTHER METHODS */
  const searchHandleClear = (): void => {
    getPreloadChecksDataTable(preloadChecksList);
    setSearchValue('');
  };

  const preloadChecksRowClicked = (preloadCheckId: string): void => {
    let queryParams = '';
    if (parentTab !== null) {
      queryParams += `?parentTab=${parentTab}`;
    }

    navigate(`${location.pathname}/details/${preloadCheckId}${queryParams}`);
  };

  const getPreloadChecksDataTable = (preloadCheckList: IPreloadCheck[]): void => {
    setDataTable(GetPreloadChecksDataTable(preloadCheckList));
  };

  const GetPageTitle = (): string | undefined => {
    // IF onlyOneStatusAllowed is true the statusFilters param will be one value not an array of values
    if (onlyOneStatusAllowed && preloadCheckStatusFilters !== undefined) {
      return t(preloadCheckStatusFilters[0]);
    } else if (rejectionType !== null && rejectionType !== undefined) {
      return t(rejectionType);
    } else {
      return undefined;
    }
  };

  const GetPageSubTitle = (): string | undefined => {
    if (onlyOneStatusAllowed) {
      return t('preload_checks');
    } else if (rejectionType !== null && rejectionType !== undefined) {
      return t('preload_checks_rejection_types');
    } else {
      return undefined;
    }
  };

  /* Method That Displays Buttons On Header's page */
  const setPreloadChecksHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        <ButtonComponent
          iconUrl={IconExport}
          iconAlt="icon-export"
          label={t('export')}
          color="secondary btn-transparent btn-outlined"
          onClick={preloadChecksExport}
        />
      </>
    );
  };

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        title={GetPageTitle()}
        subtitle={GetPageSubTitle()}
        displayHeaderLeftSideBlock={GetPageTitle() !== undefined}
        searchValue={searchValue}
        onSearchSubmit={(value) => {
          setSearchValue(value);
          setDataTable(FilterPreloadChecksDataTableSource(value, dataTable));
        }}
        onSearchClear={searchHandleClear}
        headerButtons={exportUrl !== undefined ? setPreloadChecksHeaderButtons() : undefined}
        dataTable={dataTable}
        columnsTable={PreloadCheckListColumns}
        searchPlaceholder={t('preload_checks_search_placeholder')}
        emptyMsgTable={isRequestInProgress === false ? t('no_preload_checks_to_display') : ''}
        onTableRowClicked={preloadChecksRowClicked}
        filtersData={PreloadCheckFilters}
        updateFiltersData={updatePreloadChecksFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={
          preloadChecksListPagination !== undefined ? preloadChecksListPagination.totalPages : 0
        }
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default PreloadChecks;
