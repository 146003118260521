export enum PreloadCheckStatus {
  TODO = 'to_do',
  HOLD = 'hold',
  PENDINGREQUEST = 'pending_request',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum PreloadCheckCategoryStatusEnums {
  FAILED = 'failed',
  DONE = 'done',
  CURRENT = 'current',
  HOLD = 'on_hold',
  FAILEDTOLERANCE = 'failed_tolerance',
}

export enum PreloadCheckQuestionStatusEnums {
  _,
  UNACCEPTABLE,
  __,
  ___,
  ACCEPTABLE,
}
