export enum FieldEnums {
  TEXTFIELD,
  SELECT,
  CHIP,
  CUSTOMSELECT,
  DATEPICKER,
  SUBTITLE,
}

export const PreloadCheckCategoryStatus = {
  FAILED: 'failed',
  DONE: 'done',
  CURRENT: 'current',
  HOLD: 'on_hold',
  FAILEDTOLERANCE: 'failed_tolerance',
};
