import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions
} from '../../interfaces/Form-Modal.interface';
import { IDriversDataTable } from '../../interfaces/Table.interface';
import { getDriversList, createDriver, GetDriverTransporterList } from '../../services/Driver.service';
import ButtonComponent from '../../shared/components/button/Button';
import Stats from '../../shared/components/stats/Stats';
import IconExport from './../../assets/icons/icon-export.svg';
import IconCross from './../../assets/icons/icon-cross.svg';
import AddEditModal from '../../shared/components/add-edit-modal/Add-Edit-Modal';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { IDriver, IDriverPayload } from '../../interfaces/Driver.interface';
import moment from 'moment';
import { Snackbar } from '@mui/material';
import Loader from '../../shared/components/Loader/Loader';
import {
  DriverHandleSelectTransportersSearch,
  FetchFiltersDriversTransporters,
  FetchFiltersDriversTransportersListMethod
} from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';
import { GetAffiliates } from '../../services/Affiliate.service';

const formInitialValues: IInitialValues = {
  surname: '',
  name: '',
  affiliate: '',
  transporter: '',
  id_number: '',
  licence_number: '',
  licence_expiration_date: null,
};

const formSchema: IFormSchema = Yup.object({
  surname: Yup.string().required(),
  name: Yup.string().required(),
  affiliate: Yup.object().required(),
  transporter: Yup.object().required(),
  id_number: Yup.string().required(),
  licence_number: Yup.string().required(),
  licence_expiration_date: Yup.string().required(),
});

interface IDriversProps {
  Transporters: IOptions[];
}

const Drivers = (props: IDriversProps): JSX.Element => {
  const [driversListPagination, setDriversListPagination] = useState<IPagination>();
  const [driversDataTable, setDriversDataTable] = useState<IDriversDataTable[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('common');
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { DriversModalForm, DriversListColumns } = Mock();
  const [DriversForm, setDriversForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(DriversModalForm))
  );
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [exportUrl, setExportUrl] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const [DriversFitlers, setDriversFitlers] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersDriversTransporters,
    },
  ]);

  /* METHOD EMITTING API CALLS */
  const fetchDriversList = (offset: number): void => {
    setIsRequestInProgress(true);
    getDriversList(10, offset, transporterFilters, searchValue)
      .then((driversData) => {
        setIsRequestInProgress(false);
        setDriversListPagination(driversData.pagination);
        // Method That Sets Drivers Array Data
        getDriversDataTable(driversData.data);
        setExportUrl(driversData.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleCreateDriver = (formData: IFormSchema): void => {
    setIsRequestInProgress(true);
    const driverPayload: IDriverPayload = {
      card_id: formData.id_number,
      name: formData.name,
      last_name: formData.surname,
      driver_licence: formData.licence_number,
      exp_licence: formData.licence_expiration_date,
      affiliate: formData.affiliate.value,
      transporter: formData.transporter.value,
    };

    createDriver(driverPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenModal(false);
        showSnackbar(t('driver_has_been_created_successfully'), 'success');
        // Get Drivers List After Creating New Driver To Fetch All The List With The New Created Driver
        fetchDriversList(0);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchDriversList(offset);
  };

  /* OTHER METHODS */
  const getDriversDataTable = (driversData: IDriver[]): void => {
    const driversDataTable: IDriversDataTable[] = driversData.map((driver) => {
      return {
        id: driver.id,
        surname: driver.last_name.length !== 0 ? driver.last_name : '-',
        name: driver.name,
        transporter: driver.transporter,
        affiliate: driver.affiliate,
        licenceNumber: driver.driver_licence.length !== 0 ? driver.driver_licence : '-',
        licenceExpirationDate:
          driver.exp_licence === null ? '-' : moment(driver.exp_licence).format('MM/YYYY'),
        checksNumber: driver.nbChecks,
      };
    });

    setDriversDataTable(driversDataTable);
  };

  const handleTableRowClicked = (driverId: string): void => {
    navigate(`/drivers/details/${driverId}`);
  };

  const updateFiltersData = (newFilters: IFormData[]): void => {
    setDriversFitlers(newFilters);
    const driverIds = newFilters
      .find((filter) => filter.name === 'transporter')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    let queryString = '';
    if (driverIds !== undefined) {
      queryString = `?transporterFilters=${driverIds.join(',')}`;

      if (driverIds.length > 0) {
        navigate('/drivers' + queryString);
      } else {
        navigate('/drivers');
      }
    }
  };

  /* Export Method */
  const DriversExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const GetDriverTransporterListOptions = async (affiliate: string, searchQuery?: string): Promise<IOptions[]> => {
    const transporterList = await GetDriverTransporterList(affiliate, searchQuery);
    return transporterList.map(transporter => {
      return { value: transporter.id, label: transporter.name };
    });
  };

  useEffect(() => {
    void SetFiltersData();
    fetchDriversList(0);
    void SetModalForm();
  }, [props, location, searchValue]);

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersDriversTransportersListMethod('0');

    const Fitlers = DriversFitlers.map((filter) => {
      filter.optionsData = FiltersTransportersOptionsData;
      filter.optionsData.options = filter.optionsData.options.map((option) => {
        const found = transporterFilters?.find((id: string) => id === option.value);

        return {
          ...option,
          checked: found !== undefined, // Set checked to true if found in ids
        };
      });
      return filter;
    });

    setDriversFitlers(Fitlers);
  };

  // This Method Is Trigered When Affiliate Select Change It's Value, So We Can Enable The Transporter Field
  // And Pass The Right Options For It Depending On Affiliate Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleAffiliateChange = async (option: IOptions): Promise<void> => {
    const formData = DriversForm;
    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Make Transporter Field Enabled After Selecting Affiliate Value
      formData[transporterFieldIndex].disabled = false;
      // Get Affiliate Value
      const affiliateValue = option.value;
      // Fill Options Of Transporter Dropdown By Method GetDriverTransporterListOptions
      formData[transporterFieldIndex].options = await GetDriverTransporterListOptions(affiliateValue);
      // Passing Affiliate Value To Use It On Select Field Component To Load Options By API And Send Affiliate Value On API
      formData[transporterFieldIndex].relativeFieldValue = affiliateValue;
      formData[transporterFieldIndex].value = undefined;
    }
  }

  const SetModalForm = async (): Promise<void> => {
    const formData = DriversForm;
    // Find the "Affiliate" field in the Form array
    const affiliateFieldIndex = formData.findIndex((field) => field.name === 'affiliate');
    if (affiliateFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[affiliateFieldIndex].options = await GetAffiliates();
      // Add Method To Handle Affiliate Change
      formData[affiliateFieldIndex].handSelectChange = handleAffiliateChange;
    }


    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Pass Search Product Method With It's result on the attribute method
      formData[transporterFieldIndex].CustomHandleSearchLoadOptions = DriverHandleSelectTransportersSearch;
    }

    setDriversForm(formData);
  };

  const openAddDriverModal = (): void => {
    setOpenModal(true);
  };

  const setDriversHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={DriversExport}
          />
        )}

        <ButtonComponent
          iconUrl={IconCross}
          iconAlt="icon-cross"
          label={t('add_driver')}
          color="primary"
          onClick={openAddDriverModal}
        />
      </>
    );
  };

  const modalClose = (): void => {
    setOpenModal(false);
  };

  const handleSubmitSearch = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={searchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setDriversHeaderButtons()}
        dataTable={driversDataTable}
        columnsTable={DriversListColumns}
        emptyMsgTable={isRequestInProgress === false ? t('no_drivers_to_display') : ''}
        onTableRowClicked={handleTableRowClicked}
        filtersData={DriversFitlers}
        updateFiltersData={updateFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={driversListPagination !== undefined ? driversListPagination.totalPages : 0}
        searchPlaceholder={t('driver_search_placeholder')}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />

      {/* Add Edit Modal */}
      <AddEditModal
        modalTitle={t('add_driver')}
        modalForm={DriversForm}
        formSchema={formSchema}
        formInitialValues={formInitialValues}
        openModal={openModal}
        onClose={modalClose}
        handleSubmit={handleCreateDriver}
        closeConfirmationModalTitle={t('are_you_sure')}
        closeConfirmationModalDescription={t('driver_info_will_be_lost')}
      />
    </div>
  );
};

export default Drivers;
