import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './TrailerDetails.scss';
import * as Yup from 'yup';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions,
  IOptionsData,
} from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IAnnualVettingsDataTable,
  IColumnTable,
  IPreloadChecksDataTable,
} from '../../interfaces/Table.interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';

import {
  FetchFiltersAgents,
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  handleSelectProductsSearch,
  InitFiltersData,
  TrailerHandleSelectTransportersSearch,
} from '../../services/Helper.service';
import Loader from '../../shared/components/Loader/Loader';
import { Snackbar } from '@mui/material';
import { deleteTrailer, editTrailer, getTrailer, GetTrailerTransporterList } from '../../services/Trailer.service';
import { ITrailer, ITrailerPayload } from '../../interfaces/Trailer.interface';
import { useSnackbar } from '../../services/SnackbarUtils';
import FiltersMock from '../../constants/Fitlers';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';
import { ChecksFilters } from '../../constants/Dashboard';
import { GetAffiliates } from '../../services/Affiliate.service';

interface ITrailersProps {
  transporters: IOptions[];
  products: IOptions[];
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const TrailerDetails = (props: ITrailersProps): JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [trailerPreloadChecksListPaginationTotal, setTrailerPreloadChecksListPaginationTotal] =
    useState(0);
  const [annualVettingsListPaginationTotal, setAnnualVettingsPaginationTotal] = useState(0);
  const [trailerPreloadChecksDataTable, setTrailerPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [trailerAnnualVettingsDataTable, setTrailerAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField } =
    FiltersMock();
  const PreloadCheckFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
  ];
  const [TrailerPreloadCheckFilters, setTrailerPreloadCheckFilters] =
    useState<IFormData[]>(PreloadCheckFiltersData);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [TrailerFormInitialValues, setTrailerFormInitialValues] = useState<IInitialValues>({
    affiliate: '',
    transporter: '',
    registration_number: '',
    product: '',
    capacity: '',
    unit: '',
    number_compartments: '',
  });
  const [trailerDetails, setTrailerDetails] = useState<ITrailer>();
  const [cardTrailerInfo, setCardTrailerInfo] = useState<ICardItem[]>([]);
  const { TrailersModalForm } = Mock();
  const [TrailersForm, setTrailersForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(TrailersModalForm))
  );

  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const trailerStatusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const trailerTransporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const trailerProductFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const trailerAgentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());

  const { PreloadCheckListColumns, AnnualVettingListColumns } = Mock();

  const formSchema: IFormSchema = Yup.object({
    affiliate: Yup.object().required(),
    transporter: Yup.object().required(),
    registration_number: Yup.string().required(),
    product: Yup.object().required(),
    capacity: Yup.string().required(),
    unit: Yup.object().required(),
    number_compartments: Yup.string().required(),
  });

  const cards: ICard[] = [
    {
      title: t('main_information'),
      card: cardTrailerInfo,
    },
  ];

  const GetTrailerTransporterListOptions = async (affiliate: string, searchQuery?: string): Promise<IOptions[]> => {
    return await GetTrailerTransporterList(affiliate, searchQuery).then(transporterList => {
      return transporterList.map(transporter => {
        return { value: transporter.id, label: transporter.name };
      })
    })
  };

  /* METHOD EMITTING API CALLS */
  /* Get Trailer Data Method */
  const fetchTrailer = (): void => {
    setIsRequestInProgress(true);
    getTrailer(params.id as string)
      .then(async (trailerData) => {
        setIsRequestInProgress(false);
        setTrailerDetails(trailerData);
        // Method That Sets trailer Form Values
        setTrailerFormValues(trailerData);
        getCardTrailerInfo(trailerData);
        // Find the "transporter" field in the Form array
        const transporterFieldIndex = TrailersForm.findIndex((field) => field.name === 'transporter');
        if (transporterFieldIndex !== -1) {
          TrailersForm[transporterFieldIndex].disabled = false;
          // Fill Options Of Transporter Dropdown By Method GetTrailerTransporterListOptions
          TrailersForm[transporterFieldIndex].options = await GetTrailerTransporterListOptions(trailerData.affiliate);
        }
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const trailerId = [params.id as string];
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      trailerStatusFilters,
      null,
      trailerTransporterFilters,
      trailerProductFilters,
      trailerAgentFilters,
      trailerId
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setTrailerPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);
        // Method That Sets Preload Checks Array Data
        setTrailerPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const trailerId = [params.id as string];
    setIsRequestInProgress(true);

    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      trailerStatusFilters,
      null,
      trailerTransporterFilters,
      trailerProductFilters,
      trailerAgentFilters,
      trailerId
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setAnnualVettingsPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setTrailerAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Delete Trailer Method */
  const handleDeleteTrailer = (): void => {
    setIsRequestInProgress(true);
    deleteTrailer(params.id as string)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenEditModal(false);
        showSnackbar(t('trailer_has_been_deleted_successfully'), 'success');

        setTimeout(() => {
          window.history.back();
        }, 1000);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Edit Trailer Method */
  const handleEditTrailer = (trailerForm: IFormSchema): void => {
    setIsRequestInProgress(true);
    const trailerPayload: ITrailerPayload = {
      transporter: trailerForm.transporter.label,
      affiliate: trailerForm.affiliate.value,
      registration_number: trailerForm.registration_number,
      product_ids: trailerForm.product.value,
      capacity: trailerForm.capacity,
      unit: trailerForm.unit.value,
      number_compartments: trailerForm.number_compartments,
    };

    editTrailer(params.id as string, trailerPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenEditModal(false);
        showSnackbar(t('trailer_has_been_edited_successfully'), 'success');
        fetchTrailer();
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  useEffect(() => {
    fetchTrailer();
  }, []);

  useEffect(() => {
    SetFiltersData();
    void SetTrailerDetailsModalForm();
    /* GET CHECKS DATA */
    GetChecksData();
  }, [props, location, activeTab, searchValue]);

  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      TrailerPreloadCheckFilters,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      trailerStatusFilters,
      trailerTransporterFilters,
      trailerProductFilters,
      trailerAgentFilters
    );
    setTrailerPreloadCheckFilters(InitFiltersOptions);
  };

  /* Fill Initial Values Trailer's Form Method */
  const setTrailerFormValues = (trailerDetails: ITrailer): void => {
    const productDesc = trailerDetails.products.length > 0 ? trailerDetails.products[0].description : '';
    const productId = trailerDetails.products.length > 0 ? trailerDetails.products[0].id : '';

    const initialValues = {
      affiliate: { label: trailerDetails.affiliate, value: trailerDetails.affiliate },
      transporter: { label: trailerDetails.transporter, value: trailerDetails.transporter },
      registration_number: trailerDetails.registration_number,
      product: { label: productDesc, value: productId },
      capacity: trailerDetails.capacity,
      unit: { label: trailerDetails.unit, value: trailerDetails.unit },
      number_compartments: trailerDetails.number_compartments,
    };

    setTrailerFormInitialValues(initialValues);
  };

  const editModalBtnClicked = (): void => {
    setOpenEditModal(true);
  };

  const editModalBtnClose = (): void => {
    setOpenEditModal(false);
  };

  /* Fill Details Card Method */
  const getCardTrailerInfo = (trailerData: ITrailer): void => {
    const cardTrailerInfo: ICardItem[] = [
      {
        label: 'affiliate',
        value: trailerData.affiliate,
      },
      {
        label: 'transporter',
        value: trailerData.transporter === undefined ? '-' : trailerData.transporter,
      },
      {
        label: 'product',
        value: trailerData.products.length > 0 ? trailerData.products[0].description : '-',
      },

      {
        label: 'capacity',
        value: trailerData.capacity === null ? '-' : trailerData.capacity,
      },
      {
        label: 'unit',
        value: trailerData.unit === null ? '-' : trailerData.unit,
      },
      {
        label: 'number_compartments',
        value: trailerData.number_compartments === null ? '-' : trailerData.number_compartments,
      },
    ];

    setCardTrailerInfo(cardTrailerInfo);
  };

  const updateTrailersFiltersData = (newFilters: IFormData[]): void => {
    setTrailerPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  // This Method Is Trigered When Affiliate Select Change It's Value, So We Can Enable The Transporter Field
  // And Pass The Right Options For It Depending On Affiliate Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleAffiliateChange = async (option: IOptions): Promise<void> => {
    const formData = TrailersForm;
    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Get Affiliate Value
      const affiliateValue = option.value;
      // Fill Options Of Transporter Dropdown By Method GetTrailerTransporterListOptions
      formData[transporterFieldIndex].options = await GetTrailerTransporterListOptions(affiliateValue);
      // Passing Affiliate Value To Use It On Select Field Component To Load Options By API And Send Affiliate Value On API
      formData[transporterFieldIndex].relativeFieldValue = affiliateValue;
    }
  }

  const SetTrailerDetailsModalForm = async (): Promise<void> => {
    const formData = TrailersForm;
    // Find the "affiliate" field in the Form array
    const affiliateFieldIndex = formData.findIndex((field) => field.name === 'affiliate');
    if (affiliateFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[affiliateFieldIndex].options = await GetAffiliates();
      // Add Method To Handle Affiliate Change
      formData[affiliateFieldIndex].handSelectChange = handleAffiliateChange;
    }

    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      formData[transporterFieldIndex].disabled = false;
      // Fill Options Of Transporter Dropdown By Method GetTrailerTransporterListOptions
      if (trailerDetails !== undefined) {
        formData[transporterFieldIndex].options = await GetTrailerTransporterListOptions(trailerDetails.affiliate);
      }
      // Pass Search Product Method With It's result on the attribute method
      formData[transporterFieldIndex].CustomHandleSearchLoadOptions = TrailerHandleSelectTransportersSearch;
    }

    // Find the "product" field in the TrailersModalForm array
    const productFieldIndex = formData.findIndex((field) => field.name === 'product');
    if (productFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[productFieldIndex].options = props.products;
      // Pass Search Product Method With It's result on the attribute method
      formData[productFieldIndex].handleSearchLoadOptions = handleSelectProductsSearch;
    }

    setTrailersForm(formData);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const trailerRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=trailers`);
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=trailers`);
    }
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, TrailerPreloadCheckFilters, tabName);
    navigate(url);
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}

      {trailerDetails !== undefined && (
        <Details
          title={trailerDetails.registration_number}
          subtitle={t('trailers')}
          cards={cards}
          displayHeaderEditBtn={true}
          editModalTitle={t('edit_trailer_info')}
          modalForm={TrailersForm}
          formInitialValues={TrailerFormInitialValues}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          preloadChecksDataTable={trailerPreloadChecksDataTable}
          annualVettingsChecksDataTable={trailerAnnualVettingsDataTable}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={trailerPreloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={annualVettingsListPaginationTotal}
          filtersData={TrailerPreloadCheckFilters}
          updateFiltersData={updateTrailersFiltersData}
          onTableRowClicked={trailerRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          openEditModal={openEditModal}
          handleOpenEditModal={editModalBtnClicked}
          handleCloseEditModal={editModalBtnClose}
          handleEditModalSubmit={handleEditTrailer}
          handleEditModalDelete={handleDeleteTrailer}
          deleteConfirmModalTitle={t('are_you_sure')}
          deleteConfirmModalDescription={t('trailer_info_will_be_lost')}
          closeConfirmModalDescription={t('trailer_info_will_be_lost')}
          deleteModalConfirmLabel={t('delete_trailer')}
          deleteModalCancelLabel={t('cancel')}
          deleteModalButtonLabel={t('delete_trailer')}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('trailer_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default TrailerDetails;

