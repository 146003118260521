import { axiosAuthInstance } from './Auth.service';
import { IDashboard } from '../interfaces/Dashboard.interface';

export const getDashboard = async (
  period: string,
  isAnnualVetting: boolean
): Promise<IDashboard> => {
  const client = await axiosAuthInstance();
  const response = await client.get(
    `/dashboard?period=${period}&isAnnualVetting=${isAnnualVetting.toString()}`
  );
  return response.data;
};
