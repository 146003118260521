import { IEditUserPayload, IUser } from '../interfaces/User.interface';
import { axiosAuthInstance } from './Auth.service';

export const getCurrentUser = async (): Promise<IUser> => {
  const client = await axiosAuthInstance();
  const response = await client.get('user/me');
  return response.data;
};

export const getUserDeports = async (countries?: string[]): Promise<string[]> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  countries?.forEach((country) => {
    if (queyParams.length === 0) {
      queyParams += `?countries[]=${country}`;
    } else {
      queyParams += `&countries[]=${country}`;
    }
  });

  const response = await client.get(`/user/deport${queyParams}`);
  return response.data.deports;
};

export const editCurrentUserDeport = async (payload: IEditUserPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.put('/user/deport', payload);
  return response.data;
};
