import React from 'react';
import { Outlet } from 'react-router-dom';
import { IUser } from '../../../interfaces/User.interface';

import Tabs from '../tabs/Tabs';
import './Layout.scss';

interface ILayoutProps {
  currentUser: IUser;
  userDeports: string[];
  handleEditCurrentUserDeport: (deport: string) => void;
}

const Layout = ({
  currentUser,
  userDeports,
  handleEditCurrentUserDeport,
}: ILayoutProps): React.JSX.Element => {
  return (
    <div className="App-container">
      <Tabs
        currentUser={currentUser}
        userDeports={userDeports}
        handleEditCurrentUserDeport={handleEditCurrentUserDeport}
      />
      <main className="main">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
