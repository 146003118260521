import { IAnnualVettingData, IPreloadCheckData } from '../interfaces/PreloadCheck.interface';
import {
  ITransporter,
  ITransporterData,
  ITransporterPayload,
} from '../interfaces/Transporter.interface';
import { axiosAuthInstance } from './Auth.service';

export const FetchTransportersListCreateEditModal = async (): Promise<ITransporter[]> => {
  const client = await axiosAuthInstance();
  const response = await client.get('/transporter/list?limit=30&offset=0');
  return response.data.data;
};

export const searchTransportersList = async (search: string): Promise<ITransporter[]> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/transporter/list?limit=30&offset=0&search=${search}`);
  return response.data.data;
};

export const FetchFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/transporter/list?limit=30&isFilter=true&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const FetchTransportersContractsFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const FetchTrucksFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/truck/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const FetchTrailersFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/trailers/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const FetchDriversFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/driver/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const getTransporter = async (id: string): Promise<ITransporter> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/transporter/${id}`);
  return response.data;
};

export const createTransporter = async (payload: ITransporterPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.post('/transporter', payload);
  return response.data;
};

export const getTransporterPreloadChecksList = async (
  limit: number,
  offset: number,
  transporterId: string,
  search?: string
): Promise<IPreloadCheckData> => {
  const client = await axiosAuthInstance();
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const response = await client.get(
    `/preload-check/list?limit=${limit}&offset=${offset}&transporterIds[]=${transporterId}${searchQuery}`
  );
  return response.data;
};

export const getTransporterAnnualVettingsList = async (
  limit: number,
  offset: number,
  transporterId: string,
  search?: string
): Promise<IAnnualVettingData> => {
  const client = await axiosAuthInstance();
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const response = await client.get(
    `/preload-check/list?isAnnual=true&limit=${limit}&offset=${offset}&transporterIds[]=${transporterId}${searchQuery}`
  );
  return response.data;
};

export const deleteTransporter = async (id: string): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.delete(`/transporter/${id}`);
  return response.data;
};