import { PreloadCheckQuestionStatusEnums } from '../enums/Status.enum';
import {
  IPreloadCheckData,
  IAnnualVettingData,
  IPreloadCheck,
  IPreloadCheckCategory,
  IPreloadCheckQuestion,
  IPreloadCheckPayload,
  IPreloadCheckQuestionPayload,
} from '../interfaces/PreloadCheck.interface';
import { axiosAuthInstance } from './Auth.service';

export const getPreloadChecksList = async (
  limit: number,
  offset: number,
  searchQuery?: string,
  statusIds?: string[],
  rejectionType?: string | null,
  transporterIds?: string[],
  productIds?: string[],
  agentIds?: string[],
  trailersIds?: string[],
  driversIds?: string[],
  trucksIds?: string[],
  isAnnual?: boolean,
  date?: string
): Promise<IPreloadCheckData> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  if (isAnnual !== undefined && isAnnual) {
    queyParams += '&isAnnual=true';
  }

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }

  // IF ALL FILTER IS SELECTED WE DON'T SEND FILTER STATUS TO BE
  if (statusIds?.includes('all') === false) {
    statusIds?.forEach((filter) => {
      queyParams += `&status[]=${filter}`;
    });
  }

  transporterIds?.forEach((filter) => {
    queyParams += `&transporterIds[]=${filter}`;
  });

  productIds?.forEach((filter) => {
    queyParams += `&productsIds[]=${filter}`;
  });

  agentIds?.forEach((filter) => {
    queyParams += `&agentsIds[]=${filter}`;
  });

  trailersIds?.forEach((filter) => {
    queyParams += `&trailersIds[]=${filter}`;
  });

  driversIds?.forEach((filter) => {
    queyParams += `&driversIds[]=${filter}`;
  });

  trucksIds?.forEach((filter) => {
    queyParams += `&trucksIds[]=${filter}`;
  });

  if (date !== undefined) {
    queyParams += `&date=${date}`;
  }

  queyParams +=
    rejectionType !== null && rejectionType !== undefined
      ? `&questionCategoryIds=${rejectionType}`
      : '';

  const response = await client.get(
    `/preload-check/list?limit=${limit}&offset=${offset}${queyParams}`
  );
  return response.data;
};

export const getPreloadCheck = async (id: string): Promise<IPreloadCheck> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/preload-check/${id}`);

  return response.data;
};

export const getPreloadCheckCategories = async (id: string): Promise<IPreloadCheckCategory[]> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/preload-check/categories?pc_id=${id}`);

  response.data.categories.forEach(
    (categorie: IPreloadCheckCategory) =>
      (categorie.questions = categorie.questions.map((question) => {
        return { ...question, category_id: categorie.category_id };
      }))
  );
  return response.data.categories;
};

export const FilterUnacceptableQuestions = (
  questions: IPreloadCheckQuestion[],
  categoryId?: string
): IPreloadCheckQuestion[] => {
  return questions.reduce<IPreloadCheckQuestion[]>((acc, question: IPreloadCheckQuestion) => {
    // Check if the question itself is unacceptable
    if (question.status === PreloadCheckQuestionStatusEnums.UNACCEPTABLE) {
      acc.push({ ...question, category_id: categoryId });
    }

    // Check and filter unacceptable sub-questions, if any
    if (question.subQuestions.length > 0) {
      const filteredSubQuestions = FilterUnacceptableQuestions(question.subQuestions);

      // If there are unacceptable sub-questions, clone the question object
      // to avoid mutating the original, and replace its subQuestions with the filtered list
      if (filteredSubQuestions?.length > 0) {
        acc.push({ ...question, category_id: categoryId }, ...filteredSubQuestions);
      }
    }

    return acc;
  }, []);
};

export const getPreloadCheckRejectedRequirements = async (
  id: string
): Promise<IPreloadCheckQuestion[]> => {
  try {
    const client = await axiosAuthInstance();
    const response = await client.get(`/preload-check/categories?pc_id=${id}`);
    const failedQuestions: IPreloadCheckQuestion[] = [];

    response.data.categories.forEach((category: IPreloadCheckCategory) => {
      const filteredQuestions = FilterUnacceptableQuestions(
        category.questions,
        category.category_id
      );
      failedQuestions.push(...filteredQuestions);
    });
    // Resolve the promise with the response and failed questions
    return await Promise.resolve(failedQuestions);
  } catch (error) {
    // Reject the promise with the error
    return await Promise.reject(error);
  }
};

export const getAnnualVettingsList = async (
  limit: number,
  offset: number,
  searchQuery?: string,
  statusIds?: string[],
  rejectionType?: string | null,
  transporterIds?: string[],
  productIds?: string[],
  agentIds?: string[],
  trailersIds?: string[],
  driverIds?: string[],
  truckIds?: string[],
  date?: string
): Promise<IAnnualVettingData> => {
  const isAnnual = true;

  return (await getPreloadChecksList(
    limit,
    offset,
    searchQuery,
    statusIds,
    rejectionType,
    transporterIds,
    productIds,
    agentIds,
    trailersIds,
    driverIds,
    truckIds,
    isAnnual,
    date
  )) as IAnnualVettingData;
};

export const editPreloadCheckStatus = async (
  id: string,
  payload: IPreloadCheckPayload
): Promise<IPreloadCheck> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/preload-check?pc_id=${id}`, payload);
  return response.data;
};

export const editPreloadCheckQuestion = async (
  id: string,
  payload: IPreloadCheckQuestionPayload
): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/preload-check-qa/${id}`, payload);
  return response.data;
};
