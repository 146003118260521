import { Snackbar } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions,
} from '../../interfaces/Form-Modal.interface';
import { IColumnTable, ITransportersDataTable } from '../../interfaces/Table.interface';
import {
  ITransporterContract,
  ITransporterContractPayload,
} from '../../interfaces/TransporterContract.interface';
import {
  createTransporterContract,
  getTransportersContractsList,
} from '../../services/TransporterContract.service';
import AddEditModal from '../../shared/components/add-edit-modal/Add-Edit-Modal';
import ButtonComponent from '../../shared/components/button/Button';
import Loader from '../../shared/components/Loader/Loader';
import Stats from '../../shared/components/stats/Stats';
import IconExport from './../../assets/icons/icon-export.svg';
import IconCross from './../../assets/icons/icon-cross.svg';
import * as Yup from 'yup';
import {
  FetchFiltersTransportersContractsTransporters,
  FetchFiltersTransportersContractsTransportersListMethod,
  handleSelectProductsSearch,
} from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';
import { GetAffiliates } from '../../services/Affiliate.service';

const formSchema: IFormSchema = Yup.object({
  affiliate: Yup.object().required(),
  reference_number: Yup.string().required(),
  transporter: Yup.string().required(),
  product: Yup.object().required(),
  start_date: Yup.string().required(),
  expiration_date: Yup.string().required(),
});

const formInitialValues: IInitialValues = {
  affiliate: '',
  reference_number: '',
  transporter: '',
  product: '',
  start_date: null,
  expiration_date: null,
};

interface ITransportersProps {
  products: IOptions[];
}

const Transporters = (props: ITransportersProps): JSX.Element => {
  const [transportersSearchValue, setTransportersSearchValue] = useState('');
  const [transportersListPagination, setTransportersListPagination] = useState<IPagination>();
  const [transportersContractsDataTable, setTransportersContractsDataTable] = useState<
    ITransportersDataTable[]
  >([]);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const { TransportersContractsModalForm } = Mock();
  const [TransportersContractsForm, setTransportersContractsForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(TransportersContractsModalForm))
  );
  const [exportUrl, setExportUrl] = useState<string>();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const [TransportersFitlers, setTransporterFilters] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTransportersContractsTransporters,
    },
  ]);
  const columnsTable: IColumnTable[] = [
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'affiliate',
      header: t('affiliate'),
    },
    {
      accessorKey: 'contractRef',
      header: t('contractRef'),
    },
    {
      accessorKey: 'contractStartDate',
      header: t('contractStartDate'),
    },
    {
      accessorKey: 'contractExpDate',
      header: t('contractExpDate'),
    },
  ];

  /* METHOD EMITTING API CALLS */
  const fetchTransportersContractsList = (offset: number): void => {
    setIsRequestInProgress(true);
    getTransportersContractsList(10, offset, transporterFilters, transportersSearchValue)
      .then((transportersData) => {
        setIsRequestInProgress(false);
        setTransportersListPagination(transportersData.pagination);
        // Method That Sets Transporters Contracts Array Data
        getTransportersContractsDataTable(transportersData.data);
        setExportUrl(transportersData.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleCreateTransporterContract = (formData: IFormSchema): void => {
    setIsRequestInProgress(true);
    const transporterContractPayload: ITransporterContractPayload = {
      company_name: formData.affiliate.value,
      contract_reference: formData.reference_number,
      name: formData.transporter,
      productID: formData.product.value,
      contract_start_date: formData.start_date,
      contract_end_date: formData.expiration_date,
    };

    createTransporterContract(transporterContractPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenModal(false);
        showSnackbar(t('transporter_has_been_created_successfully'), 'success');
        // Get Contracts List After Creating New Contract To Fetch All The List With The New Created Contract
        fetchTransportersContractsList(0);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchTransportersContractsList(offset);
  };

  useEffect(() => {
    void SetFiltersData();
    fetchTransportersContractsList(0);
    void SetModalForm();
  }, [props, location, transportersSearchValue]);

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersTransportersContractsTransportersListMethod('0');
    
    const Filters = TransportersFitlers.map((filter) => {
      filter.optionsData = FiltersTransportersOptionsData;
      filter.optionsData.options = filter.optionsData.options.map((option) => {
        const found = transporterFilters?.find((id: string) => id === option.value);

        return {
          ...option,
          checked: found !== undefined, // Set checked to true if found in ids
        };
      });
      return filter;
    });

    setTransporterFilters(Filters);
  };

  const SetModalForm = async (): Promise<void> => {
    const formData = TransportersContractsForm;
    // Find the "affiliate" field in the Form array
    const affiliateFieldIndex = formData.findIndex((field) => field.name === 'affiliate');
    if (affiliateFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[affiliateFieldIndex].options = await GetAffiliates();
    }

    // Find the "product" field in the TrucksModalForm array
    const productFieldIndex = formData.findIndex((field) => field.name === 'product');
    if (productFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[productFieldIndex].options = props.products;
      // Pass Search Product Method With It's result on the attribute method
      formData[productFieldIndex].handleSearchLoadOptions = handleSelectProductsSearch;
    }

    setTransportersContractsForm(formData);
  };

  /* OTHER METHODS */
  const getTransportersContractsDataTable = (transportersData: ITransporterContract[]): void => {
    const transportersDataTable: ITransportersDataTable[] = transportersData.map((transporter) => {
      return {
        id: transporter.id,
        transporter: transporter.name,
        affiliate: transporter.company_name,
        contractRef: transporter.contract_reference !== null ? transporter.contract_reference : '-',
        contractStartDate:
          transporter?.contract_start_date !== null
            ? moment(transporter.contract_start_date).format('DD/MM/YYYY')
            : '-',
        contractExpDate:
          transporter?.contract_end_date != null
            ? moment(transporter.contract_end_date).format('DD/MM/YYYY')
            : '-',
      };
    });

    setTransportersContractsDataTable(transportersDataTable);
  };

  /* Search Methods */
  const handleSubmitSearch = (value: string): void => {
    setTransportersSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setTransportersSearchValue('');
  };

  const transportersRowClicked = (preloadCheckId: string): void => {
    navigate(`/transporters/details/${preloadCheckId}`);
  };

  const updateTransportersFiltersData = (newFilters: IFormData[]): void => {
    setTransporterFilters(newFilters);
    const transporterIds = newFilters
      .find((filter) => filter.name === 'transporter')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    let queyParams = '';

    if (transporterIds !== undefined && transporterIds.length > 0) {
      queyParams += `?transporterFilters=${transporterIds.join(',')}`;
    }

    navigate('/transporters' + queyParams);
  };

  const TransportersExport = (): void => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const openAddTransportContractModal = (): void => {
    setOpenModal(true);
  };

  const modalClose = (): void => {
    setOpenModal(false);
  };

  const setTransportersContractsHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={TransportersExport}
          />
        )}

        <ButtonComponent
          iconUrl={IconCross}
          iconAlt="icon-cross"
          label={t('add_transporter')}
          color="primary"
          onClick={openAddTransportContractModal}
        />
      </>
    );
  };

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={transportersSearchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setTransportersContractsHeaderButtons()}
        dataTable={transportersContractsDataTable}
        columnsTable={columnsTable}
        emptyMsgTable={isRequestInProgress === false ? t('no_transporters_to_display') : ''}
        onTableRowClicked={transportersRowClicked}
        filtersData={TransportersFitlers}
        updateFiltersData={updateTransportersFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={
          transportersListPagination !== undefined ? transportersListPagination.totalPages : 0
        }
        searchPlaceholder={t('transporter_contract_search_placeholder')}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />

      {/* Add Edit Modal */}
      <AddEditModal
        modalTitle={t('add_a_transporter')}
        modalForm={TransportersContractsForm}
        formSchema={formSchema}
        formInitialValues={formInitialValues}
        openModal={openModal}
        onClose={modalClose}
        handleSubmit={handleCreateTransporterContract}
        closeConfirmationModalTitle={t('are_you_sure')}
        closeConfirmationModalDescription={t('transporter_info_will_be_lost')}
      />
    </div>
  );
};

export default Transporters;
