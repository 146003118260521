import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { ITokens, IUserSSOPayload } from '../interfaces/User.interface';

const axiosAuthInstance = async (): Promise<AxiosInstance> => {
  let token = '';

  if (Cookies.get('token') !== null) {
    token = Cookies.get('token') as string;
  }

  const axiosApiInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL as string,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      language: localStorage.getItem('OryxLang') !== null ? localStorage.getItem('OryxLang') : 'en',
    },
  });

  axiosApiInstance.interceptors.request.use((config) => {
    if (config.url !== undefined && Boolean(config.url.includes('/auth/silent'))) {
      config.headers['Authorization'] = `Bearer ${Cookies.get('refreshToken') as string}`;
      config.headers['Platform'] = 'web';
    } else if (config.url !== undefined && Boolean(config.url.includes('/auth/sso'))) {
      config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    } else if (config.url !== undefined && Boolean(config.url.includes('/export'))) {
      config.responseType = 'blob';
    }

    return config;
  });

  axiosApiInstance.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      const currentApiUrl = error.response.config.url;
      if (
        error.response.status === 401 &&
        (Boolean(currentApiUrl.includes('/auth/login')) ||
          Boolean(currentApiUrl.includes('/auth/sso')))
      ) {
        return await Promise.reject(error);
      }
      if (error.response.status === 401 && Boolean(currentApiUrl.includes('/auth/silent'))) {
        ClearTokenData();
        return await Promise.reject(error);
      }

      if (error.response.status === 401 && currentApiUrl.includes('/auth/silent') === false) {
        return await silentLogin().then(async (data) => {
          Cookies.set('token', data.accessToken, {
            secure: true,
          });
          Cookies.set('refreshToken', data.refreshToken, {
            secure: true,
          });
          error.config.headers.Authorization = `Bearer ${data.accessToken}`;

          return await axiosApiInstance.request(error.config);
        });
      }

      return await Promise.reject(error);
    }
  );

  const ClearTokenData = (): void => {
    localStorage.clear();
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    window.location.replace('/login');
  };

  return axiosApiInstance;
};

export { axiosAuthInstance };

export const createSSOUser = async (payload: IUserSSOPayload): Promise<ITokens> => {
  const client = await axiosAuthInstance();

  const response = await client.post('/auth/sso', payload);
  Cookies.set('token', response.data.accessToken, {
    secure: true,
  });
  Cookies.set('refreshToken', response.data.refreshToken, {
    secure: true,
  });
  return response.data;
};

export const silentLogin = async (): Promise<ITokens> => {
  const client = await axiosAuthInstance();

  const response = await client.post('/auth/silent');
  return response.data;
};
