import React from 'react';
import { DatePickerInput } from '@mantine/dates';
import IconRight from './../../../assets/icons/icon-selectfield-right.svg';
import './DatepickerField.scss';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

interface IDatePickerFieldProps {
  label: string;
  name: string;
  placeholder: string;
  field: any;
  form: any;
  disabled?: boolean;
}

const DatepickerField = (props: IDatePickerFieldProps): React.JSX.Element => {
  const { label, name, placeholder, field, form, disabled } = props;
  let [blurIndex, setBlurIndex] = React.useState(0);
  const { t, i18n } = useTranslation('common');

  const getHelperText = (): string => {
    return DisplayError() ? t('information_is_required') : '';
  };

  const DisplayError = (): boolean => {
    return Boolean(form.touched[name]) && form.errors[name];
  };

  return (
    <div
      className={`block-datepicker 
      ${DisplayError() ? 'error' : ''}
      ${disabled === true ? 'disabled' : ''}`}
    >
      <DatePickerInput
        locale={i18n.language}
        value={(field.value !== undefined && field.value !== null && field.value.length !== 0) ? new Date(field.value) : undefined}
        name={field.name}
        onChange={(ev) => {
          if (ev !== null) {
            form.setFieldValue(name, ev);
          }
        }}
        label={label}
        {...{ placeholder: placeholder }}
        valueFormat="YYYY-MM-DD"
        error={getHelperText()}
        disabled={disabled}
        onBlurCapture={() => {
          setBlurIndex(blurIndex++);
          if (blurIndex === 2) {
            form.setFieldTouched(name, true);
            setBlurIndex(0);
          }
        }}
        rightSection={<img className="chevron-right" src={IconRight} alt="chevron-right" />}
      />
    </div>
  );
};

export default DatepickerField;
