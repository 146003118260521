import {
  ITransporterContract,
  ITransporterContractData,
  ITransporterContractPayload,
} from '../interfaces/TransporterContract.interface';
import { axiosAuthInstance } from './Auth.service';

export const getTransportersContractsList = async (
  limit: number,
  offset: number,
  transporterIds?: string[],
  searchQuery?: string
): Promise<ITransporterContractData> => {
  const client = await axiosAuthInstance();
  let queryParams = '';

  transporterIds?.forEach((filter) => {
    queryParams += `&transporterIds[]=${filter}`;
  });

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queryParams += `&search=${searchQuery}`;
  }

  const response = await client.get(
    `/transporter/list?limit=${limit}&offset=${offset}${queryParams}`
  );
  return response.data;
};

export const searchTransportersList = async (search: string): Promise<ITransporterContract[]> => {
  const client = await axiosAuthInstance();
  const response = await client.get(
    `/transporter/list?limit=10&offset=0&search=${search}`
  );
  return response.data.data;
};

export const getTransporterContract = async (id: string): Promise<ITransporterContract> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/transporter/${id}`);
  return response.data;
};

export const createTransporterContract = async (
  payload: ITransporterContractPayload
): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.post('/transporter', payload);
  return response.data;
};

export const editTransporterContract = async (
  id: string,
  payload: ITransporterContractPayload
): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/transporter/${id}`, payload);
  return response.data;
};
