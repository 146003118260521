import { useState } from 'react';

interface ISnackBarData {
  snackBarOpen: boolean;
  snackbarMessage: string;
  snackBarType: string;
  showSnackbar: (message: string, type: string) => void;
  handleSnackBarClose: (_event: React.SyntheticEvent | Event, reason?: string) => void;
}

export const useSnackbar = (): ISnackBarData => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('');

  const showSnackbar = (message: string, type: string): void => {
    setSnackBarOpen(true);
    setSnackbarMessage(message);
    setSnackBarType(type);
  };

  const handleSnackBarClose = (_event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  return {
    snackBarOpen,
    snackbarMessage,
    snackBarType,
    showSnackbar,
    handleSnackBarClose,
  };
};
