import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import './Image-modal.scss';

interface ImageModalProps {
  imageBase64: string;
}

const style: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
};

const ImageModal: React.FC<ImageModalProps> = ({ imageBase64 }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <div className="image-modal">
      <button className="image-container" onClick={handleOpen}>
        <img src={`data:image/jpeg;base64,${imageBase64}`} alt="Preview" />
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <img
            src={`data:image/jpeg;base64,${imageBase64}`}
            alt="Detailed view"
            style={{ width: '100%' }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ImageModal;
