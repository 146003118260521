import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config/authConfig';
import { MsalProvider } from '@azure/msal-react';

void i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'en',
    debug: 'true',
    detection: {
      oreder: ['queryString', 'cookie'],
      cache: ['cookie'],
      lookupLocalStorage: 'OryxLang',
    },
    resources: {
      en: { common: en },
      fr: { common: fr },
    },
  });
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
