import {
  IAgentCreatePayload,
  IAgentData,
  IAgentDetail,
  IAgentEditPayload,
  IAgentEditStatusPayload,
} from '../interfaces/Agent.interface';
import { axiosAuthInstance } from './Auth.service';

export const getAgentsList = async (
  offset: number,
  roleIds?: string[],
  deportIds?: string[],
  countryIds?: string[],
  searchQuery?: string
): Promise<IAgentData> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  roleIds?.forEach((filter) => {
    queyParams += `&roles[]=${filter}`;
  });

  deportIds?.forEach((filter) => {
    queyParams += `&deports[]=${filter}`;
  });

  countryIds?.forEach((filter) => {
    queyParams += `&countries[]=${filter}`;
  });

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }

  const response = await client.get(`/user/agent/list?limit=10&offset=${offset}${queyParams}`);
  return response.data;
};

export const FetchFiltersAgentsList = async (
  offset: string,
  search?: string
): Promise<IAgentData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/user/agent/list?limit=30&isFilter=true&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const getAgent = async (id: string): Promise<IAgentDetail> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/user/agent/${id}`);
  return response.data;
};

export const createAgent = async (payload: IAgentCreatePayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.post('/user', payload);
  return response.data;
};

export const editAgent = async (id: string, payload: IAgentEditPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/user/agent/${id}`, payload);
  return response.data;
};

export const editAgentStatus = async (
  id: string,
  payload: IAgentEditStatusPayload
): Promise<IAgentDetail> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/user/agent/${id}`, payload);
  return response.data;
};
