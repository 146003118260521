import { axiosAuthInstance } from './Auth.service';

export const ExportData = async (url: string): Promise<any> => {
  const client = await axiosAuthInstance();
  const response = await client.get(url);
  // Create a Blob from the PDF Stream
  const file = new Blob([response.data], { type: 'application/octet-stream' });
  // Build a URL from the file
  const fileURL = window.URL.createObjectURL(file);

  const startIndex = url.indexOf('/') + 1; // Get the index of the first "/" plus 1 to skip it
  const endIndex = url.indexOf('/', startIndex); // Get the index of the second "/"
  const fileName = url.substring(startIndex, endIndex);

  // Create a temporary anchor tag and trigger download
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = `${fileName}.csv`; // Replace with your desired file name and extension
  document.body.appendChild(link);
  link.click();
  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(fileURL);
  return response.data;
};
