import React, { useEffect, useRef, useState } from 'react';
import './CustomSelect.scss';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  InputLabel,
  Modal,
  Slide,
  TextField,
} from '@mui/material';
import ButtonComponent from '../button/Button';
import IconLeft from './../../../assets/icons/icon-chevron-left-black.svg';
import IconRight from './../../../assets/icons/icon-chevron-right-black.svg';
import { IOptions, IOptionsData } from '../../../interfaces/Form-Modal.interface';
import IconClear from './../../../assets/icons/icon-search-clear.svg';
import IconSearch from './../../../assets/icons/icon-search.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IPagination } from '../../../interfaces/Pagination.interface';
import Loader from '../Loader/Loader';
import { useTranslation } from 'react-i18next';

interface CustomSelectProps {
  label: string;
  name: string;
  placeholder: string;
  field: any;
  form: any;
  optionsData: IOptionsData;
  onModalClose?: (options: IOptions[]) => void;
  handleFetchMoreData: ((
    offset: string,
    callback: (options: IOptionsData) => void,
    searchQuery?: string
  ) => void) | undefined;
}

const CustomSelect = ({
  label,
  name,
  placeholder,
  field,
  form,
  optionsData,
  onModalClose,
  handleFetchMoreData,
}: CustomSelectProps): React.JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [optionsArrSource, setOptionsArrSource] = useState(optionsData.options);
  const [optionsArr, setOptionsArr] = useState(optionsData.options);
  const [selectSearchValueSource, setSelectSearchValueSource] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [pagination, setPagination] = useState<IPagination>(optionsData.pagination as IPagination);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (optionsData.pagination !== undefined) setPagination(optionsData.pagination);
  }, [optionsData]);

  const handleClick = (): void => {
    setOpenModal(true);
  };

  const handleClose = (): void => {
    const checkedItems = optionsArrSource.filter((option) => option.checked);
    form.getFieldHelpers(name).setValue(checkedItems);
    setOpenModal(false);
  };

  const handleCheckboxChange = (dataValue: string): void => {
    const sourceUpdatedItems = optionsArrSource.map((item) => {
      if (item.value === dataValue) {
        item = { ...item, checked: !((item.checked as boolean) ?? false) };
      }
      return item;
    });
    setOptionsArrSource(sourceUpdatedItems);

    const updatedItems = optionsArr.map((item) => {
      if (item.value === dataValue) {
        item = { ...item, checked: !((item.checked as boolean) ?? false) };
      }
      return item;
    });
    setOptionsArr(updatedItems);
  };

  const selectSearchHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectSearchValueSource(event.target.value);
  };

  const FetchOptionsData = (offset: string, searchQuery?: string): void => {
      // SEARCH LOGIC USING API
    if (handleFetchMoreData !== undefined) {
      const getOptionsData = (optionsData: IOptionsData): void => {
        if (optionsData.pagination !== undefined) {
          setPagination(optionsData.pagination);
        }
        setOptionsArr(optionsData.options);
      };

      handleFetchMoreData(offset, getOptionsData, searchQuery);
    } else {
      // SEARCH LOGIC LOCALLY
      if (searchQuery !== undefined && searchQuery.length > 0) {
        const filteredData = optionsArrSource.filter(obj => obj.label.toLowerCase().includes(searchQuery));
        setOptionsArr(filteredData);
      } else {
        setOptionsArr(optionsArrSource);
      }
    }
  };

  const selectSearchHandleClick = (): void => {
    const searchQuery = selectSearchValueSource.toLowerCase();
    FetchOptionsData('0', searchQuery);
  };

  const selectSearchHandleClear = (): void => {
    setSelectSearchValueSource('');
    FetchOptionsData('0');

    if (inputRef !== null && inputRef.current instanceof HTMLInputElement) {
      // Keep Focus On The Input
      inputRef.current?.focus();
    }
  };

  const FetchMoreDataByPagination = (): void => {
    if (handleFetchMoreData !== undefined) {
      const getOptionsData = (optionsData: IOptionsData): void => {
        if (optionsData.pagination !== undefined) {
          setPagination(optionsData.pagination);
        }
        setOptionsArr([...optionsArr, ...optionsData.options]);
      };
  
      const offset = (pagination.itemsPerPage + pagination.currentOffset).toString();
      const searchQuery = selectSearchValueSource.length > 0 ? selectSearchValueSource : undefined;
      handleFetchMoreData(offset, getOptionsData, searchQuery);
    }
  };

  const modalComponent = (): React.JSX.Element => {
    return (
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide in={openModal} direction="left" timeout={600}>
          <Box>
            <div className={`custom-select-modal ${openModal ? 'open' : 'close'}`}>
              <InfiniteScroll
                dataLength={optionsArr.length}
                next={FetchMoreDataByPagination}
                hasMore={
                  pagination !== undefined && pagination.totalPages !== pagination.currentPage
                }
                height={'100vh'}
                loader={<Loader />}
              >
                <div className="custom-select-header">
                  <div className="container">
                    <ButtonComponent
                      classes="no-shadow"
                      iconOnly={true}
                      iconRound={true}
                      iconUrl={IconLeft}
                      iconAlt="icon-left"
                      onClick={handleClose}
                    />
                    <h3>{label}</h3>
                  </div>
                  <form onSubmit={selectSearchHandleClick}>
                    <TextField
                      inputRef={inputRef}
                      value={selectSearchValueSource}
                      className="custom-select__search-input"
                      size="small"
                      placeholder={t('search')}
                      onChange={selectSearchHandleChange}
                      InputProps={{
                        endAdornment: (
                          <div className="search-input__end">
                            {selectSearchValueSource.length > 0 && (
                              <ButtonComponent
                                classes="no-padding"
                                iconOnly={true}
                                iconRound={true}
                                iconUrl={IconClear}
                                iconAlt="icon-search"
                                onClick={selectSearchHandleClear}
                              />
                            )}

                            <ButtonComponent
                              classes="no-padding"
                              iconOnly={true}
                              iconRound={true}
                              iconUrl={IconSearch}
                              iconAlt="icon-search"
                              onClick={selectSearchHandleClick}
                            />
                          </div>
                        ),
                      }}
                    />
                  </form>
                </div>
                <div className="custom-select-body">
                  <div className="options">
                    {optionsArr.map((option) => (
                      <Card className="option-card" key={option.value}>
                        <CardContent>
                          <span>{option.label}</span>
                          <Checkbox
                            checked={option.checked}
                            onChange={() => {
                              handleCheckboxChange(option.value);
                            }}
                          />
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          </Box>
        </Slide>
      </Modal>
    );
  };

  const SetFieldValueLabel = (): string => {
    return field.value.length > 0 ? field.value[0].label : field.value;
  };
  return (
    <>
      <div className="block-custom-select">
        <InputLabel>{label}</InputLabel>
        <TextField
          onClick={handleClick}
          InputProps={{
            readOnly: true,
            endAdornment: <img className="custom-select-icon" src={IconRight} alt="icon-right" />,
          }}
          className="custom-select"
          fullWidth
          placeholder={placeholder}
          {...field}
          value={SetFieldValueLabel()}
          error={Boolean(form.touched[name])}
        />
      </div>
      {modalComponent()}
    </>
  );
};

export default CustomSelect;
