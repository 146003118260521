import { MenuItem, Select, Snackbar } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';
import { ComplianceLevelConst, Status } from '../../constants/Status';
import { PreloadCheckQuestionStatusEnums, PreloadCheckStatus } from '../../enums/Status.enum';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IPreloadCheck,
  IPreloadCheckPayload,
  IPreloadCheckPrintableData,
  IPreloadCheckQuestion,
  IPreloadCheckQuestionPayload,
} from '../../interfaces/PreloadCheck.interface';
import {
  IAnnualVettingRejectedRequirementDataTable,
  IColumnTable,
  IRejectedRequirementDataTable,
} from '../../interfaces/Table.interface';
import {
  FilterUnacceptableQuestions,
  editPreloadCheckQuestion,
  editPreloadCheckStatus,
  getPreloadCheck,
  getPreloadCheckCategories,
} from '../../services/PreloadCheck.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import CheckDetails from '../../shared/components/CheckDetails/CheckDetails';
import Loader from '../../shared/components/Loader/Loader';
import './PreloadCheckDetails.scss';
import IconStatusApproved from './../../assets/icons/icon-status-approved.svg';
import IconStatusRejected from './../../assets/icons/icon-status-rejected.svg';
import { useReactToPrint } from 'react-to-print';
import OryxLogo from './../../assets/images/oryx-energies-print.png';
import { ExportData } from '../../services/Export.service';
import { IPrintFooter } from '../../interfaces/Print.interface';

const PreloadCheckDetails = (): JSX.Element => {
  const [preloadCheckData, setPreloadCheckData] = useState<IPreloadCheck>();
  const [dataTable, setDataTable] = useState<IRejectedRequirementDataTable[]>([]);
  const [PreloadCheckPrintableData, setPreloadCheckPrintableData] = useState<
    IPreloadCheckPrintableData[]
  >([]);
  const [annualVettingRejectedRequirementDataTable, setAnnualVettingRejectedRequirementDataTable] =
    useState<IAnnualVettingRejectedRequirementDataTable[]>([]);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isAnnualVetting = Boolean(searchParams.get('isAnnualVetting'));
  const parentTab = searchParams.get('parentTab');
  const { t } = useTranslation('common');
  const params = useParams();

  const [cardMainInfo, setCardMainInfo] = useState<ICardItem[]>([]);
  const [cardDriverInfo, setCardDriverInfo] = useState<ICardItem[]>([]);
  const [cardAgentInfo, setCardAgentInfo] = useState<ICardItem[]>([]);
  const [exportUrl, setExportUrl] = useState<string>();
  const [componentRef, setComponentRef] = React.useState<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef,
    onAfterPrint: () => {
      componentRef?.setAttribute('style', 'display:none;');
    },
    onBeforeGetContent: () => {
      componentRef?.setAttribute('style', 'display:flex;');
    },
  });
  const cards: ICard[] = [
    {
      title: t('main_information'),
      card: cardMainInfo,
    },
    {
      title: t('driver_information'),
      card: cardDriverInfo,
    },
    {
      title: t('agent_information'),
      card: cardAgentInfo,
    },
  ];
  const { CheckQuestionsColumns, PreloadCheckQuestionsColumns, AnnualVettingQuestionsColumns } =
    Mock();
  const CheckQuestionsEditableStatusColumnTable: IColumnTable[] = [
    ...(CheckQuestionsColumns as IColumnTable[]),
    {
      accessorKey: 'status',
      header: t('status'),
      Cell: (data: any) => {
        return (
          <div className="status-select">
            <div className="status-container">{getStatusIcon(data.row.original.status)}</div>
            <Select
              className="table-status-select"
              labelId="table-status-select-standard-label"
              id="table-status-select-standard"
              value={
                data.row.original.status === PreloadCheckQuestionStatusEnums.ACCEPTABLE
                  ? 'yes'
                  : 'no'
              }
              onChange={(e) => {
                handleSelectStatusChange(e, data.row.original);
              }}
              MenuProps={{
                classes: { paper: 'oryx-select__paper table-status-select__paper' },
              }}
            >
              <MenuItem value="yes">{t('yes')}</MenuItem>
              <MenuItem value="no">{t('no')}</MenuItem>
            </Select>
          </div>
        );
      },
    },
  ];

  const printFooter: IPrintFooter[] = [
    {
      role: t('inspector'),
      label: t('agent_name'),
      date: moment(Date()).format('YYYY-MM-DD'),
    },
    {
      role: t('safety_supervisor'),
      label: t('agent_name'),
      date: moment(Date()).format('YYYY-MM-DD'),
    },
    {
      role: t('driver'),
      label: t('driver_name'),
    },
  ];

  const getStatusIcon = (status: PreloadCheckQuestionStatusEnums): React.JSX.Element => {
    switch (status) {
      case PreloadCheckQuestionStatusEnums.ACCEPTABLE:
        return <img src={IconStatusApproved} alt="icon-status" />;
      default:
        return <img src={IconStatusRejected} alt="icon-status" />;
    }
  };

  const handleSelectStatusChange = (event: any, data: any): void => {
    setIsRequestInProgress(true);

    const selectedValue = event.target.value;
    const questionPayload: IPreloadCheckQuestionPayload = {
      preload_check_id: params.id as string,
      question_id: data.question_id,
      status: PreloadCheckQuestionStatusEnums.ACCEPTABLE,
      comment: data.comments,
    };

    if (selectedValue === 'no') {
      questionPayload.status = PreloadCheckQuestionStatusEnums.UNACCEPTABLE;
    }

    editPreloadCheckQuestion(data.id, questionPayload)
      .then(() => {
        setIsRequestInProgress(false);
        // Remove Question From Reject Requirements Table When it's edited
        const questionId = data.id;
        const newRejectedRequirementsDataTable = dataTable.filter((obj) => obj.id !== questionId);
        setDataTable(newRejectedRequirementsDataTable);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* METHOD EMITTING API CALLS */
  /* Get Preload Check Data Method */
  const fetchPreloadCheck = (): void => {
    setIsRequestInProgress(true);
    getPreloadCheck(params.id as string)
      .then((preloadCheck) => {
        setIsRequestInProgress(false);
        setPreloadCheckData(preloadCheck);

        getCardMainInfo(preloadCheck);
        getCardDriverInfo(preloadCheck);
        getCardAgentInfo(preloadCheck);
        // SET EXPORT URL
        setExportUrl(`/preload-check/export/detail/${preloadCheck.id}`);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleEditPreloadCheckStatus = (status: PreloadCheckStatus): void => {
    if (preloadCheckData !== undefined) {
      const pcDetailsintermediateSecurityCheckStatus =
        preloadCheckData.security_check_status === PreloadCheckStatus.PENDINGREQUEST
          ? status
          : PreloadCheckStatus.TODO;

      setIsRequestInProgress(true);
      const preloadCheckPayload: IPreloadCheckPayload = {
        id: params.id as string,
        transporter: preloadCheckData.transporter,
        trailers: preloadCheckData.trailers,
        created_at: preloadCheckData.created_at,
        updated_at: preloadCheckData.updated_at,
        status,
        security_check_status:
          status === PreloadCheckStatus.REJECTED
            ? PreloadCheckStatus.REJECTED
            : pcDetailsintermediateSecurityCheckStatus,
      };
      editPreloadCheckStatus(params.id as string, preloadCheckPayload)
        .then((preloadCheckEdited) => {
          // Edit Preload CHeck Statue Status
          setPreloadCheckData({ ...preloadCheckData, status: preloadCheckEdited.status });

          let pcDetailsSnackBarMsg = 'preload_check_has_been_approved';
          let pcDetailsSnackBarClass = 'success';

          if (status === PreloadCheckStatus.REJECTED) {
            pcDetailsSnackBarMsg = 'preload_check_has_been_rejected';
            pcDetailsSnackBarClass = 'error';
          }

          showSnackbar(
            t(pcDetailsSnackBarMsg, { orderNumber: preloadCheckData.order_number }),
            pcDetailsSnackBarClass
          );
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const FetchRejectedRequirements = (questions: IPreloadCheckQuestion[]): void => {
    if (isAnnualVetting) {
      getAnnualVettingRejectedRequiremmentsDataTable(questions);
    } else {
      getRejectedRequiremmentsDataTable(questions);
    }
  };

  useEffect(() => {
    fetchPreloadCheck();
    FetchPreloadCheckCategories();
  }, []);

  useEffect(() => {
    FetchPreloadCheckCategories();
  }, [t]);

  const FetchPreloadCheckCategories = (): void => {
    setIsRequestInProgress(true);
    getPreloadCheckCategories(params.id as string)
      .then((categories) => {
        setIsRequestInProgress(false);
        const categoriesData = categories.map((categorie) => {
          return {
            title: categorie.category,
            rejectedQuestions: FilterUnacceptableQuestions(
              categorie.questions,
              categorie.category_id
            ),
          };
        });
        setPreloadCheckPrintableData(categoriesData);

        // Script To Retrieve Failed Requirements
        const failedQuestions: IPreloadCheckQuestion[] = [];

        categories.forEach((category) => {
          const filteredQuestions = FilterUnacceptableQuestions(
            category.questions,
            category.category_id
          );

          failedQuestions.push(...filteredQuestions);
        });

        if (failedQuestions.length > 0) {
          FetchRejectedRequirements(failedQuestions);
        }
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const getRejectedRequiremmentsDataTable = (
    rejectedRequirement: IPreloadCheckQuestion[]
  ): void => {
    const rejectedRequirementDataTable: IRejectedRequirementDataTable[] = rejectedRequirement.map(
      (question) => {
        return {
          id: question.id,
          question_id: question.question_id,
          tolerance: question.tolerance as string,
          category: question.category_id as string,
          requirement: question.question,
          comments: question.comment,
          photo: question.image,
          status: question.status,
        };
      }
    );

    setDataTable(rejectedRequirementDataTable);
  };

  const getAnnualVettingRejectedRequiremmentsDataTable = (
    rejectedRequirement: IPreloadCheckQuestion[]
  ): void => {
    const rejectedRequirementDataTable: IAnnualVettingRejectedRequirementDataTable[] =
      rejectedRequirement.map((question) => {
        return {
          id: question.id,
          question_id: question.question_id,
          category: question.category_id as string,
          requirement: question.question,
          comments: question.comment,
          subCategory: question.subCategory,
          photo: question.image,
          compliance_level: question.eliminatoryComplianceLevels[0],
        };
      });

    setAnnualVettingRejectedRequirementDataTable(rejectedRequirementDataTable);
  };

  /* Fill Details Card Method */
  const getCardMainInfo = (preloadCheck: IPreloadCheck): void => {
    const cardMainInfoData: ICardItem[] = [
      {
        label: 'transporter',
        value: preloadCheck.transporter,
      },
      {
        label: 'truck_number',
        value: preloadCheck.truck.truck_number,
      },
      {
        label: 'trailer_number',
        value:
          preloadCheck.trailers.length > 0 ? preloadCheck.trailers[0].registration_number : '-',
      },
      {
        label: 'product',
        value: preloadCheck.product.description,
      },
      {
        label: 'date',
        value: moment(preloadCheck.created_at).format('YYYY-MM-DD'),
      },
    ];

    // ORDER NUMBER IS NOT SUPPORTED ON ANNUAL VETTINGS
    if (!isAnnualVetting) {
      setCardMainInfo([
        {
          label: 'order_number',
          value: preloadCheck.order_number,
        },
        ...cardMainInfoData,
      ]);
    } else {
      setCardMainInfo(cardMainInfoData);
    }
  };

  const getCardDriverInfo = (preloadCheck: IPreloadCheck): void => {
    const cardDriverInfoData: ICardItem[] = [
      {
        label: 'name',
        value: preloadCheck.driver.name + ' ' + preloadCheck.driver.last_name,
      },
      {
        label: 'id_number',
        value: preloadCheck.driver.card_id,
      },
      {
        label: 'driver_licence',
        value: preloadCheck.driver.driver_licence,
      },
      {
        label: 'licence_expiration',
        value: moment(preloadCheck.driver.exp_licence).format('YYYY-MM-DD'),
      },
    ];

    setCardDriverInfo(cardDriverInfoData);
  };

  const getCardAgentInfo = (preloadCheck: IPreloadCheck): void => {
    const cardAgentInfoData: ICardItem[] = [
      {
        label: 'country',
        value: preloadCheck.agent !== undefined ? preloadCheck.agent.country : '-',
      },
      {
        label: 'deport',
        value: preloadCheck.agent !== undefined ? preloadCheck.agent.deport : '-',
      },
      {
        label: 'agent',
        value: preloadCheck.agent !== undefined ? preloadCheck.agent.name : '-',
      },
      {
        label: 'situation',
        value:
          preloadCheck.agent !== undefined
            ? preloadCheck.agent.is_external
              ? t('agent_extern')
              : t('agent_intern')
            : '-',
      },
      {
        label: 'time_spent',
        value:
          preloadCheck.agent?.preload_check_average_time !== undefined
            ? preloadCheck.agent.preload_check_average_time
            : '-',
      },
      {
        label: 'security_agent',
        value:
          preloadCheck.security_agent?.name !== undefined ? preloadCheck.security_agent?.name : '-',
      },
      {
        label: 'time_spent',
        value:
          preloadCheck.security_agent?.preload_check_average_time !== undefined
            ? preloadCheck.security_agent?.preload_check_average_time
            : '-',
      },
    ];

    setCardAgentInfo(cardAgentInfoData);
  };

  const redirectToEntirePreloadCheck = (): void => {
    let queryParams = '';

    if (parentTab !== null) {
      queryParams = `?parentTab=${parentTab}`;
    }

    if (isAnnualVetting) {
      queryParams += `${queryParams.length > 0 ? '&' : '?'}isAnnualVetting=true`;
    }

    navigate(`/preload-checks/entire-check/${params.id as string}${queryParams}`);
  };

  /* Export Method */
  const PreloadCheckExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);
      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const redirectToEntireSecurityCheck = (): void => {
    let queryParams = '';

    if (parentTab !== null) {
      queryParams += `&parentTab=${parentTab}`;
    }

    if (isAnnualVetting) {
      queryParams += '&isAnnualVetting=true';
    }

    navigate(
      `/preload-checks/entire-check/${params.id as string}?isSecurityCheck=true${queryParams}`
    );
  };

  const isCheckDone = (): boolean => {
    return preloadCheckData?.status !== Status.TODO;
  };

  const GetComplianceLevel = (complianceLevel: string): string => {
    switch (complianceLevel) {
      case ComplianceLevelConst.LEVEL_1:
        return '1';
      case ComplianceLevelConst.LEVEL_2:
        return '2';
      case ComplianceLevelConst.LEVEL_3:
        return '3';
      default:
        return '4';
    }
  };

  const PrintTemplate = (preloadCheck: IPreloadCheck): JSX.Element => {
    return (
      <div
        className="print-template"
        ref={(el) => {
          setComponentRef(el);
        }}
      >
        <div className="print-template__top">
          <div className="print-template__header">
            <img src={OryxLogo} alt="oryx-logo" />
            <div className="block-title">
              <p className="subtitle">ORYX ENERGIES</p>
              <h1 className="title">{t('check_list_trucks')}</h1>
            </div>
            <div className="block-desc">
              <p>OE-DS-FO-011-V01-E</p>
              <p>{moment().format('DD MMMM YYYY')}</p>
            </div>
          </div>

          <div className="print-template__body">
            <div className="card-preload-check">
              <div className="card-right">
                <div className="card-item">
                  <p className="label">{t('check_list_number')}</p>
                  <p className="value">{preloadCheck.id}</p>
                </div>
                <div className="card-item">
                  <p className="label">{t('date')}</p>
                  <p className="value">{moment(preloadCheck.created_at).format('YYYY-MM-DD')}</p>
                </div>
                {!isAnnualVetting && (
                  <div className="card-item">
                    <p className="label">{t('erp_loading_number')}</p>
                    <p className="value">{preloadCheck.order_number}</p>
                  </div>
                )}
                <div className="card-item">
                  <p className="label">{t('transporter')}</p>
                  <p className="value">Oryx Energies</p>
                </div>
              </div>
              <div className="card-left">
                <div className="card-item">
                  <p className="label">{t('driver_name')}</p>
                  <p className="value">
                    {preloadCheck.driver.name + ' ' + preloadCheck.driver.last_name}
                  </p>
                </div>
                <div className="card-item">
                  <p className="label">{t('product_transported')}</p>
                  <p className="value">{preloadCheck.product.description}</p>
                </div>
                <div className="card-item">
                  <p className="label">{t('tractor_regist_numer')}</p>
                  <p className="value">{preloadCheck.truck.truck_number}</p>
                </div>
                <div className="card-item">
                  <p className="label">{t('trailer_regist_numer')}</p>
                  <p className="value">
                    {preloadCheck.trailers.length > 0
                      ? preloadCheck.trailers[0].registration_number
                      : '-'}
                  </p>
                </div>
                <div className="card-item">
                  <p className="label">{t('capacity_of_the_tank')}</p>
                  <p className="value">
                    {preloadCheck.trailers.length > 0
                      ? preloadCheck.trailers[0].capacity + ' ' + preloadCheck.trailers[0].unit
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className="card-categories">
              <p className="card-categories__title">{t('categories_and_rejected_requirements')}</p>
              <div className="card-categories__item">
                {PreloadCheckPrintableData.map((category, index) => {
                  return (
                    <div className="category-item" key={`category-${index}`}>
                      <h2 className="category-title">{category.title}</h2>
                      {category.rejectedQuestions.length > 0 ? (
                        <div className="rejected-requirements">
                          {category.rejectedQuestions.map((question) => {
                            return (
                              <div className="rejected-requirements__item" key={question.id}>
                                <div className="labels">
                                  <p className="label requirement">{t('requirement')}</p>
                                  {!isAnnualVetting && <p className="label">{t('tolerance')}</p>}
                                  {isAnnualVetting && (
                                    <p className="label">{t('compliance_level')}</p>
                                  )}
                                  <p className="label">{t('comment')}</p>
                                </div>
                                <div className="values">
                                  <p className="value">{question.question}</p>
                                  {!isAnnualVetting && (
                                    <p className="value">{question.tolerance}</p>
                                  )}
                                  {isAnnualVetting && (
                                    <p className="value">
                                      {GetComplianceLevel(question.eliminatoryComplianceLevels[0])}
                                    </p>
                                  )}
                                  <p className="value">{question.comment}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <p className="all-requirements-met">{t('all_requirements_met')}</p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="print-template__bottom">
          <div className="truck-acceptance">
            <div className="truck-acceptance__item">
              <p className="label">{t('truck_accepted_required')}</p>
              <div className="value"></div>
            </div>
            <div className="truck-acceptance__item">
              <p className="label">{t('truck_rejected_required')}</p>
              <div className="value"></div>
            </div>
          </div>
          <div className="block-signature">
            <p className="block-signature__title">{t('name_signature_and_date')}</p>
            <div className="block-signature__items">
              {printFooter.map((item, index) => (
                <div className="block-signature__item" key={`footer-print-item-${index}`}>
                  <p className="role">{item.role}</p>
                  <div className="signature-box">
                    <span className="label">{item.label}</span>
                  </div>
                  {item.date !== undefined && (
                    <div className="date">
                      <p className="label">{t('automatic_date')}</p>
                      <p className="value">{item.date}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="preload-check-details">
      {isRequestInProgress && <Loader />}
      {preloadCheckData !== undefined && PrintTemplate(preloadCheckData)}
      {preloadCheckData !== undefined && (
        <div className="details-container">
          <CheckDetails
            isAnnualVetting={isAnnualVetting}
            title={
              isAnnualVetting ? preloadCheckData.truck.truck_number : preloadCheckData.order_number
            }
            pcStatus={preloadCheckData.status}
            handleApprovalClicked={() => {
              handleEditPreloadCheckStatus(PreloadCheckStatus.APPROVED);
            }}
            handleRejectionClicked={() => {
              handleEditPreloadCheckStatus(PreloadCheckStatus.REJECTED);
            }}
            subtitle={isAnnualVetting ? t('annual_vettings') : t('preload_checks')}
            cards={cards}
            isCheckDone={isCheckDone()}
            rejectedRequirementsDataTable={dataTable}
            rejectedRequirementsColumnsTable={
              preloadCheckData.status === PreloadCheckStatus.PENDINGREQUEST
                ? CheckQuestionsEditableStatusColumnTable
                : (PreloadCheckQuestionsColumns as IColumnTable[])
            }
            annualVettingRejectedRequirementDataTable={annualVettingRejectedRequirementDataTable}
            annualVettingRejectedRequirementsColumnsTable={
              AnnualVettingQuestionsColumns as IColumnTable[]
            }
            rejectedRequirementsTableTotalPages={0}
            onTableRowClicked={() => {}}
            handleViewEntirePreloadCheckClicked={redirectToEntirePreloadCheck}
            handleViewEntireSecurityCheckClicked={redirectToEntireSecurityCheck}
            handleExport={exportUrl !== undefined ? PreloadCheckExport : undefined}
            handlePrint={handlePrint}
          />
        </div>
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default PreloadCheckDetails;
