import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions,
} from '../../interfaces/Form-Modal.interface';
import { IColumnTable, ITrucksDataTable } from '../../interfaces/Table.interface';
import Stats from '../../shared/components/stats/Stats';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AddEditModal from '../../shared/components/add-edit-modal/Add-Edit-Modal';
import IconExport from './../../assets/icons/icon-export.svg';
import IconCross from './../../assets/icons/icon-cross.svg';
import ButtonComponent from '../../shared/components/button/Button';
import { Snackbar } from '@mui/material';
import { GetTruckTransporterListOptions, createTruck, getTrucksList } from '../../services/Truck.service';
import { ITruck, ITruckPayload } from '../../interfaces/Truck.interface';
import Loader from '../../shared/components/Loader/Loader';
import {
  FetchFiltersTrucksTransporters,
  FetchFiltersTrucksTransportersListMethod,
  TruckHandleSelectTransportersSearch,
} from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';
import { GetAffiliates } from '../../services/Affiliate.service';

const formInitialValues: IInitialValues = {
  truck_number: '',
  transporter: '',
};

const formSchema: IFormSchema = Yup.object({
  truck_number: Yup.string().required(),
  transporter: Yup.object().required(),
  affiliate: Yup.object().required(),
});

interface ITrucksProps {
  Transporters: IOptions[];
}

const Trucks = (props: ITrucksProps): JSX.Element => {
  const [trucksListPagination, setTrucksListPagination] = useState<IPagination>();
  const [trucksDataTable, setTrucksDataTable] = useState<ITrucksDataTable[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [openTruckModal, setOpenTruckModal] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { TrucksModalForm } = Mock();
  const [TrucksForm, setTrucksForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(TrucksModalForm))
  );
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [exportUrl, setExportUrl] = useState<string>();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [TrucksFitlers, setTrucksFitlers] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTrucksTransporters,
    },
  ]);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());

  const truckColumnsTable: IColumnTable[] = [
    {
      accessorKey: 'truckNumber',
      header: t('truck_number'),
    },
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'affiliate',
      header: t('affiliate'),
    },
    {
      accessorKey: 'preloadChecksNbr',
      header: t('preload_checks'),
    },
    {
      accessorKey: 'annualVettingsNbr',
      header: t('annual_vettings'),
    },
  ];

  /* METHOD EMITTING API CALLS */
  const fetchTrucksList = (offset: number): void => {
    setIsRequestInProgress(true);
    getTrucksList(10, offset, transporterFilters, searchValue)
      .then((trucksData) => {
        setIsRequestInProgress(false);
        setTrucksListPagination(trucksData.pagination);
        // Method That Sets Trucks Array Data
        getTrucksDataTable(trucksData.data);
        setExportUrl(trucksData.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleCreateTruck = (formData: IFormSchema): void => {
    setIsRequestInProgress(true);
    const truckPayload: ITruckPayload = {
      truck_number: formData.truck_number,
      transporter: formData.transporter.value,
    };

    createTruck(truckPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenTruckModal(false);
        showSnackbar(t('truck_has_been_created_successfully'), 'success');
        // Get Trcuks List After Creating New Truck To Fetch All The List With The New Created Truck
        fetchTrucksList(0);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchTrucksList(offset);
  };

  /* Export Method */
  const TrucksExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  useEffect(() => {
    void SetFiltersData();
    fetchTrucksList(0);
    void SetModalForm();
  }, [props, location, searchValue]);

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersTrucksTransportersListMethod('0');

    const Filters = TrucksFitlers.map((filter) => {
      filter.optionsData = FiltersTransportersOptionsData;
      filter.optionsData.options = filter.optionsData.options.map((option) => {
        const found = transporterFilters?.find((id: string) => id === option.value);

        return {
          ...option,
          checked: found !== undefined, // Set checked to true if found in ids
        };
      });
      return filter;
    });

    setTrucksFitlers(Filters);
  };

  // This Method Is Trigered When Affiliate Select Change It's Value, So We Can Enable The Transporter Field
  // And Pass The Right Options For It Depending On Affiliate Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleAffiliateChange = async (option: IOptions): Promise<void> => {
    const formData = TrucksForm;
    // Find the "transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Make Transporter Field Enabled After Selecting Affiliate Value
      formData[transporterFieldIndex].disabled = false;
      // Get Affiliate Value
      const affiliateValue = option.value;
      // Fill Options Of Transporter Dropdown By Method GetTruckTransporterListOptions
      formData[transporterFieldIndex].options = await GetTruckTransporterListOptions(affiliateValue);
      // Passing Affiliate Value To Use It On Select Field Component To Load Options By API And Send Affiliate Value On API
      formData[transporterFieldIndex].relativeFieldValue = affiliateValue;
      formData[transporterFieldIndex].value = undefined;
    }
  }

  const SetModalForm = async (): Promise<void> => {
    const formData = TrucksForm;
    // Find the "affiliate" field in the Form array
    const affiliateFieldIndex = formData.findIndex((field) => field.name === 'affiliate');
    if (affiliateFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[affiliateFieldIndex].options = await GetAffiliates();
      // Add Method To Handle Affiliate Change
      formData[affiliateFieldIndex].handSelectChange = handleAffiliateChange;
    }
    // Find the "Transporter" field in the Form array
    const transporterFieldIndex = formData.findIndex((field) => field.name === 'transporter');
    if (transporterFieldIndex !== -1) {
      // Pass Search Product Method With It's result on the attribute method
      formData[transporterFieldIndex].CustomHandleSearchLoadOptions = TruckHandleSelectTransportersSearch;
    }

    setTrucksForm(formData);
  };

  /* OTHER METHODS */
  const getTrucksDataTable = (trucksData: ITruck[]): void => {
    const trucksDataTable: ITrucksDataTable[] = trucksData.map((truck) => {
      return {
        id: truck.id,
        truckNumber: truck.truck_number.length !== 0 ? truck.truck_number : '-',
        transporter: truck.transporter,
        affiliate: truck.affiliate,
        preloadChecksNbr: truck.nbChecks,
        annualVettingsNbr: truck.nbAnnualChecks,
      };
    });

    setTrucksDataTable(trucksDataTable);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const handleSubmitSearch = (value: string): void => {
    setSearchValue(value);
  };

  const handleTableRowClicked = (truckId: string): void => {
    navigate(`/trucks/details/${truckId}`);
  };

  const updateFiltersData = (newFilters: IFormData[]): void => {
    setTrucksFitlers(newFilters);
    const transporterIds = newFilters
      .find((filter) => filter.name === 'transporter')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    let queryString = '';
    if (transporterIds !== undefined) {
      queryString = `?transporterFilters=${transporterIds.join(',')}`;

      if (transporterIds.length > 0) {
        navigate('/trucks' + queryString);
      } else navigate('/trucks');
    }
  };

  const openAddTruckModal = (): void => {
    setOpenTruckModal(true);
  };

  const setTrucksHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={TrucksExport}
          />
        )}

        <ButtonComponent
          iconUrl={IconCross}
          iconAlt="icon-cross"
          label={t('add_truck')}
          color="primary"
          onClick={openAddTruckModal}
        />
      </>
    );
  };

  const modalClose = (): void => {
    setOpenTruckModal(false);
  };

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={searchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setTrucksHeaderButtons()}
        dataTable={trucksDataTable}
        columnsTable={truckColumnsTable}
        emptyMsgTable={isRequestInProgress === false ? t('no_truck_to_display') : ''}
        onTableRowClicked={handleTableRowClicked}
        filtersData={TrucksFitlers}
        updateFiltersData={updateFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={trucksListPagination !== undefined ? trucksListPagination.totalPages : 0}
        searchPlaceholder={t('truck_search_placeholder')}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />

      {/* Add Edit Modal */}
      <AddEditModal
        modalTitle={t('add_truck')}
        modalForm={TrucksForm}
        formSchema={formSchema}
        formInitialValues={formInitialValues}
        openModal={openTruckModal}
        onClose={modalClose}
        handleSubmit={handleCreateTruck}
        closeConfirmationModalTitle={t('are_you_sure')}
        closeConfirmationModalDescription={t('truck_info_will_be_lost')}
      />
    </div>
  );
};

export default Trucks;
