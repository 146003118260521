import { IOptions } from '../interfaces/Form-Modal.interface';
import { ITransporter } from '../interfaces/Transporter.interface';
import { ITruckData, ITruckDetail, ITruckPayload } from '../interfaces/Truck.interface';
import { axiosAuthInstance } from './Auth.service';

export const getTrucksList = async (
  limit: number,
  offset: number,
  transporterIds?: string[],
  searchQuery?: string
): Promise<ITruckData> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  transporterIds?.forEach((filter) => {
    queyParams += `&transporterIds[]=${filter}`;
  });

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }

  const response = await client.get(`/truck/list?limit=${limit}&offset=${offset}${queyParams}`);
  return response.data;
};

export const getTruck = async (id: string): Promise<ITruckDetail> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/truck/${id}`);
  return response.data;
};

export const createTruck = async (payload: ITruckPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.post('/truck', payload);
  return response.data;
};

export const editTruck = async (id: string, payload: ITruckPayload): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.put(`/truck/${id}`, payload);
  return response.data;
};

export const deleteTruck = async (id: string): Promise<void> => {
  const client = await axiosAuthInstance();
  const response = await client.delete(`/truck/${id}`);
  return response.data;
};

export const GetTruckTransporterList = async (affiliate: string, searchQuery?: string): Promise<ITransporter[]> => {
  const client = await axiosAuthInstance();
  let queyParams = '';

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queyParams += `&search=${searchQuery}`;
  }


  const response = await client.get(`/truck/transporter/list?limit=30&offset=0&affiliate=${affiliate}${queyParams}`);
  return response.data.data;
};

export const GetTruckTransporterListOptions = async (affiliate: string, searchQuery?: string): Promise<IOptions[]> => {
  return await GetTruckTransporterList(affiliate, searchQuery).then(transporterList => {
    return transporterList.map(transporter => {
      return { value: transporter.id, label: transporter.name };
    })
  })
};
