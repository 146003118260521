import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Slide, Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import IconCloseBlack from './../../../assets/icons/icon-close-black.svg';
import IconCheck from './../../../assets/icons/icon-check.svg';
import IconDelete from './../../../assets/icons/icon-delete.svg';
import ButtonComponent from '../button/Button';
import {
  IFormData,
  IInitialValues,
  IFormSchema,
  IOptions,
} from '../../../interfaces/Form-Modal.interface';
import { FieldEnums } from '../../../enums/Field.enum';
import SelectField from '../SelectField/SelectField';
import Textfield from '../Textfield/Textfield';
import IconClose from './../../../assets/icons/icon-close.svg';

import './Add-Edit-Modal.scss';
import DatepickerField from '../DatepickerField/DatepickerField';
import ConfirmDialog from '../confirm-dialog/Confirm-Dialog';
import { useTranslation } from 'react-i18next';

interface IAddEditModalProps {
  openModal: boolean;
  onClose: () => void;
  modalForm: IFormData[];
  formInitialValues: IInitialValues;
  formSchema: IFormSchema;
  modalTitle: string;
  handleSubmit: (values: IFormSchema) => void;
  closeConfirmationModalTitle: string;
  closeConfirmationModalDescription: string;
  deleteConfirmModalTitle?: string;
  deleteConfirmModalDescription?: string;
  deleteModalConfirmColor?: string;
  deleteModalConfirmLabel?: string;
  deleteModalCancelLabel?: string;
  handleDelete?: () => void;
  deleteButtonLabel?: string;
  deleteButtonColor?: string;
  deleteButtonIcon?: string;
}

const AddEditModal = (props: IAddEditModalProps): React.JSX.Element => {
  const {
    openModal,
    onClose,
    modalForm,
    formInitialValues,
    formSchema,
    modalTitle,
    handleSubmit,
    closeConfirmationModalTitle,
    closeConfirmationModalDescription,
    deleteConfirmModalTitle,
    deleteConfirmModalDescription,
    deleteModalConfirmLabel,
    deleteModalConfirmColor,
    deleteModalCancelLabel,
    handleDelete,
    deleteButtonLabel,
    deleteButtonColor,
    deleteButtonIcon,
  } = props;

  const [formData, setFormData] = useState<IFormData[]>(
    [...modalForm].map((item) => JSON.parse(JSON.stringify(item)))
  );
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [validationSchema, setValidationSchema] = useState(formSchema);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    setFormData(modalForm);
    setInitialValues(formInitialValues);
    setValidationSchema(formSchema);
  }, [openModal, formInitialValues]);

  const displayFormFields = (formItem: IFormData): React.JSX.Element | null => {
    if (formItem.componentType === FieldEnums.SELECT) {
      return selectFieldComponent(formItem);
    } else if (formItem.componentType === FieldEnums.TEXTFIELD) {
      return textFieldComponent(formItem);
    } else if (formItem.componentType === FieldEnums.DATEPICKER) {
      return datePickerFieldComponent(formItem);
    } else if (formItem.componentType === FieldEnums.SUBTITLE) {
      return <h4 className="subtitle">{formItem.label}</h4>;
    }

    return null;
  };

  const selectFieldComponent = (frmData: IFormData): React.JSX.Element => {
    return (
      <Field
        key={frmData.name}
        name={frmData.name}
        label={t(frmData.label)}
        placeholder={t(frmData.placeholder as string)}
        render={({ field, form }: any) => (
          <>
            <SelectField
              name={frmData.name}
              label={t(frmData.label)}
              disabled={frmData.disabled}
              options={frmData.options as IOptions[]}
              field={field}
              form={form}
              handleSearchLoadOptions={frmData.handleSearchLoadOptions}
              CustomHandleSearchLoadOptions={frmData.CustomHandleSearchLoadOptions}
              relativeFieldValue={frmData.relativeFieldValue}
              relativeFieldName={frmData.relativeFieldName}
              handleChange={frmData.handSelectChange}
              value={frmData.value === null ? frmData.value : field.value}
            />
          </>
        )}
      />
    );
  };

  const setLoginIdFieldValue = (form: any): string => {
    let value = '';
    if (form.values.surname?.length > 0) {
      value = `${form.values.surname.toLowerCase().replace(/ /g, '') as string}.${
        form.values.name.toLowerCase().replace(/ /g, '') as string
      }`;
    } else {
      value = form.values.name.toLowerCase();
    }

    return value;
  };

  const textFieldComponent = (frmData: IFormData): React.JSX.Element => {
    return (
      <Field
        key={frmData.name}
        name={frmData.name}
        label={t(frmData.label)}
        placeholder={t(frmData.placeholder as string)}
        render={({ field, form }: any) => (
          <>
            <Textfield
              name={frmData.name}
              label={t(frmData.label)}
              type={frmData.type}
              disabled={frmData.disabled}
              placeholder={t(frmData.placeholder as string)}
              field={field}
              form={form}
              value={frmData.name === 'login_id' ? setLoginIdFieldValue(form) : field.value}
            />
          </>
        )}
      />
    );
  };

  const datePickerFieldComponent = (frmData: IFormData): React.JSX.Element => {
    return (
      <Field
        key={frmData.name}
        name={frmData.name}
        label={t(frmData.label)}
        placeholder={t(frmData.placeholder as string)}
        render={({ field, form }: any) => (
          <DatepickerField
            name={frmData.name}
            label={t(frmData.label)}
            disabled={frmData.disabled}
            placeholder={t(frmData.placeholder as string)}
            field={field}
            form={form}
          />
        )}
      />
    );
  };

  const closeModal = (values: IInitialValues): void => {
    // If Form Fields Has Chnaged Comparing To Their Initial Values
    if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
      // Display Confirmation Modal
      setOpenConfirmModal(true);
    } else {
      onClose();
    }
  };

  const deleteButtonClicked = (): void => {
    setOpenDeleteConfirmModal(true);
  };

  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide in={openModal} direction="left" timeout={600}>
          <Box>
            <div className={`add-edit-modal ${openModal ? 'open' : 'close'}`}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values, isValid, dirty }) => (
                  <Form>
                    <div className="add-edit-modal-top">
                      <div className="add-edit-modal-header">
                        <h3>{modalTitle}</h3>
                        <ButtonComponent
                          classes="no-shadow"
                          iconOnly={true}
                          iconRound={true}
                          iconUrl={IconClose}
                          iconAlt="icon-close"
                          onClick={() => {
                            closeModal(values);
                          }}
                        />
                      </div>
                      <div className="add-edit-modal-body">
                        {/* Loop through formData Variable and display form fields */}
                        {formData.map((frmData) => {
                          return displayFormFields(frmData);
                        })}
                      </div>
                    </div>
                    <div className="add-edit-modal-footer">
                      <div className="left-side">
                        {/* Delete Button */}
                        {handleDelete !== undefined && (
                          <ButtonComponent
                            iconUrl={deleteButtonIcon != null ? deleteButtonIcon : IconDelete}
                            iconAlt="icon-delete"
                            label={deleteButtonLabel}
                            color={deleteButtonColor !== undefined ? deleteButtonColor : 'senary'}
                            onClick={deleteButtonClicked}
                          />
                        )}
                      </div>

                      <div className="right-side">
                        <ButtonComponent
                          iconUrl={IconCloseBlack}
                          iconAlt="icon-close"
                          label={t('cancel')}
                          color="secondary btn-outlined"
                          onClick={() => {
                            closeModal(values);
                          }}
                        />

                        <ButtonComponent
                          iconUrl={IconCheck}
                          iconAlt="icon-right"
                          label={t('confirm')}
                          color="tertiary"
                          disabled={!dirty || (dirty && !isValid)}
                          onClick={() => {
                            handleSubmit(values);
                          }}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Slide>
      </Modal>

      {/* Closing Confirmation Dialog */}
      <ConfirmDialog
        openModal={openConfirmModal}
        title={closeConfirmationModalTitle}
        description={closeConfirmationModalDescription}
        confirmLabel={t('confirm')}
        cancelLabel={t('cancel')}
        handleConfirm={() => {
          setOpenConfirmModal(false);
          onClose();
        }}
        handleClose={() => {
          setOpenConfirmModal(false);
        }}
      />

      {/* Delete Confirmation Dialog */}
      {deleteConfirmModalTitle !== undefined &&
        deleteConfirmModalDescription !== undefined &&
        deleteModalConfirmLabel !== undefined &&
        deleteModalCancelLabel !== undefined && (
          <ConfirmDialog
            openModal={openDeleteConfirmModal}
            title={deleteConfirmModalTitle}
            description={deleteConfirmModalDescription}
            confirmLabel={deleteModalConfirmLabel}
            cancelLabel={deleteModalCancelLabel}
            handleConfirm={() => {
              setOpenDeleteConfirmModal(false);
              if (handleDelete !== undefined) {
                handleDelete();
              }
            }}
            handleClose={() => {
              setOpenDeleteConfirmModal(false);
            }}
            confirmButtonColor={deleteModalConfirmColor}
          />
        )}
    </div>
  );
};

export default AddEditModal;
