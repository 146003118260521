import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ButtonBase, Snackbar } from '@mui/material';
import IconPieChart from './../../assets/icons/icon-piechart.svg';
import IconApproved from './../../assets/icons/icon-approved.svg';
import IconRejected from './../../assets/icons/icon-rejected.svg';
import IconFullRightWhite from './../../assets/icons/icon-full-chevron-right.svg';
import IconFullRightBlack from './../../assets/icons/icon-full-chevron-right-black.svg';
import IconExport from './../../assets/icons/icon-export.svg';
import ButtonComponent from '../../shared/components/button/Button';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTranslation } from 'react-i18next';
import { CHECKTYPE } from '../../enums/Dashboard.enum';
import { DashboardFilters } from '../../constants/Dashboard';
import { getDashboard } from '../../services/Dashboard.service';
import { IDashboard, IRejectionType } from '../../interfaces/Dashboard.interface';
import { PreloadCheckStatus } from '../../enums/Status.enum';
import Loader from '../../shared/components/Loader/Loader';
import { useSnackbar } from '../../services/SnackbarUtils';
import { TFunction } from 'i18next';
import { ExportData } from '../../services/Export.service';

interface IFilter {
  label: string;
  id: string;
  active: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps): React.JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className="simple-tabpanel"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

interface IDashboardProps {
  userName: string;
}

const RenderDashboardPanel = (
  checkDataInfo: IDashboard,
  navigate: NavigateFunction,
  t: TFunction<any, any>,
  activeTab: CHECKTYPE
): React.JSX.Element => {
  return (
    <>
      {checkDataInfo.checksDone === 0 && <p className="no-data">{t('no_data_available')}</p>}
      {checkDataInfo.checksDone > 0 && (
        <>
          <div className="tabpanel-header">
            <p className="stats">
              <span>{checkDataInfo.checksDone}</span>/{checkDataInfo.totalChecks} {t('checks_done')}
            </p>

            <ButtonComponent
              iconUrl={IconFullRightWhite}
              iconAlt="chevron-right"
              label={
                activeTab === CHECKTYPE.ANNUALVETTINGS ? t('see_all_vettings') : t('see_all_checks')
              }
              color="primary"
              onClick={() => {
                if (activeTab === CHECKTYPE.ANNUALVETTINGS) {
                  navigate('/annual-vettings');
                } else {
                  navigate('/preload-checks');
                }
              }}
            />
          </div>
          {renderCards(checkDataInfo, navigate, t, activeTab)}
          {renderRejectionType(checkDataInfo, navigate, t, activeTab)}
        </>
      )}
    </>
  );
};

const renderCards = (
  dashboardElem: IDashboard,
  navigate: NavigateFunction,
  t: TFunction<any, any>,
  activeTab: CHECKTYPE
): React.JSX.Element => {
  return (
    <div className="tabpanel-cards">
      {renderCard(dashboardElem, PreloadCheckStatus.APPROVED, navigate, t, activeTab)}
      {renderCard(dashboardElem, PreloadCheckStatus.REJECTED, navigate, t, activeTab)}
    </div>
  );
};

const renderRejectionType = (
  dashboardElem: IDashboard,
  navigate: NavigateFunction,
  t: TFunction<any, any>,
  activeTab: CHECKTYPE
): React.JSX.Element => {
  return (
    <div className="rejection-type">
      <div className="title-container">
        <p className="title">{t('rejection_type')}</p>
        <img className="title-icon" src={IconPieChart} alt="icon-piechart" />
      </div>
      {renderPieChart(dashboardElem, navigate, t, activeTab)}
    </div>
  );
};

const renderPieChart = (
  dashboardElem: IDashboard,
  navigate: NavigateFunction,
  t: TFunction<any, any>,
  activeTab: CHECKTYPE
): React.JSX.Element => {
  if (dashboardElem.rejectionTypes.length > 0) {
    return (
      <div className="block-piechart">
        <PieChart
          series={[
            {
              data: dashboardElem.rejectionTypes,
              innerRadius: 55,
              paddingAngle: 5,
              cornerRadius: 5,
              cx: 70,
            },
          ]}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
          width={250}
          height={160}
        />
        {renderPieChartLegend(dashboardElem, navigate, activeTab)}
      </div>
    );
  } else {
    return <p className="no-data">{t('no_rejected_recorded_yet')}</p>;
  }
};

const renderCard = (
  dashboardElem: IDashboard,
  cardType: PreloadCheckStatus,
  navigate: NavigateFunction,
  t: TFunction<any, any>,
  activeTab: CHECKTYPE
): React.JSX.Element => {
  let cardTitle = t('approved');
  let cardLength = dashboardElem.approvedCount;
  let cardPercentage = dashboardElem.approvedPercentage;

  if (cardType === PreloadCheckStatus.REJECTED) {
    cardTitle = t('rejected');
    cardLength = dashboardElem.rejectedCount;
    cardPercentage = dashboardElem.rejectedPercentage;
  }

  return (
    <ButtonBase
      disableRipple={false}
      component="div"
      className={`card ${cardType === PreloadCheckStatus.APPROVED ? 'approved' : 'rejected'}`}
      onClick={() => {
        redirectToDashboardDetail(cardType, null, navigate, activeTab);
      }}
    >
      <div className="card-header">
        <p>{cardTitle}</p>
        <img
          className="card-icon"
          src={cardType === PreloadCheckStatus.APPROVED ? IconApproved : IconRejected}
          alt={`icon-${cardType}`}
        />
      </div>
      <p className="card-body">
        <span className="bold">{cardPercentage}%</span>
        <span>({cardLength})</span>
      </p>
      <div className="card-footer">
        <span>{t('more')}</span>
        <div className="chevron-right">
          <span className="icon"></span>
        </div>
      </div>
    </ButtonBase>
  );
};

const renderPieChartLegend = (
  dashboardElem: IDashboard,
  navigate: NavigateFunction,
  activeTab: CHECKTYPE
): React.JSX.Element => {
  return (
    <div className="block-legend">
      {dashboardElem.rejectionTypes.map((item: IRejectionType) => {
        return (
          <ButtonBase
            className="legend-item"
            component="div"
            key={item.categoryId}
            onClick={() => {
              redirectToDashboardDetail(null, item.categoryId, navigate, activeTab);
            }}
          >
            <div className="legend-square">
              <div className="square" style={{ background: item.color }}></div>
              <p className="label">{item.label}</p>
            </div>
            <p className="legend-percentage">{item.percentage}%</p>
          </ButtonBase>
        );
      })}
    </div>
  );
};

const redirectToDashboardDetail = (
  checkStatus: PreloadCheckStatus | null,
  rejectionType: string | null,
  navigate: NavigateFunction,
  activeTab: CHECKTYPE
): void => {
  let queryString = '?statusFilters=approved&onlyOneStatusAllowed=true';
  if (checkStatus !== null) {
    queryString = `?statusFilters=${checkStatus}&onlyOneStatusAllowed=true`;
  }

  if (rejectionType !== null) {
    queryString = `?rejectionType=${rejectionType}`;
  }

  if (activeTab === CHECKTYPE.ANNUALVETTINGS) {
    queryString += '&isAnnualVetting=true';
    navigate('/annual-vettings' + queryString + '&parentTab=dashboard');
  } else {
    navigate('/preload-checks' + queryString + '&parentTab=dashboard');
  }
};

const Dashboard = ({ userName }: IDashboardProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [dashboardData, setDashboardData] = useState<IDashboard>();
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activePeriodFilter, setActivePeriodFilter] = useState(
    searchParams.get('periodFilter') !== null
      ? (searchParams.get('periodFilter') as string)
      : DashboardFilters.DAY
  );
  const [periodFilters, setPeriodFilters] = useState<IFilter[]>([
    {
      label: 'day',
      id: DashboardFilters.DAY,
      active: activePeriodFilter === DashboardFilters.DAY,
    },
    {
      label: 'month',
      id: DashboardFilters.MONTH,
      active: activePeriodFilter === DashboardFilters.MONTH,
    },
    {
      label: 'year',
      id: DashboardFilters.YEAR,
      active: activePeriodFilter === DashboardFilters.YEAR,
    },
  ]);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('isAnnual') !== null && searchParams.get('isAnnual') === 'true'
      ? CHECKTYPE.ANNUALVETTINGS
      : CHECKTYPE.PRELOADCHECKS
  );
  const [exportUrl, setExportUrl] = useState<string>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();

  const fetchDashboard = (): void => {
    const isAnnualVettingTabActive = CHECKTYPE.ANNUALVETTINGS === activeTab;
    setIsRequestInProgress(true);
    getDashboard(activePeriodFilter, isAnnualVettingTabActive)
      .then((data) => {
        setIsRequestInProgress(false);
        setDashboardData(data);
        setExportUrl(data.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  useEffect(() => {
    fetchDashboard();
  }, [location, activeTab, activePeriodFilter]);

  const handleChange = (_event: React.SyntheticEvent, activeTabIndex: number): void => {
    setActiveTab(activeTabIndex);
    let queryString = `?isAnnual=false&periodFilter=${activePeriodFilter}`;
    if (activeTabIndex === CHECKTYPE.ANNUALVETTINGS) {
      queryString = `?isAnnual=true&periodFilter=${activePeriodFilter}`;
    }

    navigate('/dashboard' + queryString);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  /* Export Method */
  const DashboardExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const pendingRequestClicked = (): void => {
    navigate(
      '/preload-checks?statusFilters=pending_request&onlyOneStatusAllowed=true&parentTab=dashboard'
    );
  };

  const handleFiltersClick = (filter: IFilter): void => {
    const filtersData: IFilter[] = periodFilters.map((filter) => {
      filter.active = false;
      return filter;
    });
    filter.active = true;
    setPeriodFilters(filtersData);
    setActivePeriodFilter(filter.id);

    let queryString = `?isAnnual=false&periodFilter=${filter.id}`;
    if (activeTab === CHECKTYPE.ANNUALVETTINGS) {
      queryString = `?isAnnual=true=false&periodFilter=${filter.id}`;
    }

    navigate('/dashboard' + queryString);
  };

  const renderFilters = (): React.JSX.Element => {
    return (
      <div className="filters">
        <div className="filters__container">
          {periodFilters.map((filter) => {
            return (
              <ButtonComponent
                key={`filter-${filter.id}`}
                classes={`tabs ${!filter.active ? 'btn-text' : ''}`}
                label={t(filter.label)}
                color={filter.active ? 'quinary' : 'secondary'}
                onClick={() => {
                  handleFiltersClick(filter);
                }}
              />
            );
          })}
        </div>

        {/* Export button */}
        {exportUrl !== undefined && (
          <ButtonComponent
            classes="btn-outlined"
            iconUrl={IconExport}
            label={t('export')}
            color="secondary"
            onClick={DashboardExport}
          />
        )}
      </div>
    );
  };

  const getDashboardSubtitle = (): string => {
    if (activePeriodFilter === DashboardFilters.DAY) {
      return t('dashboard_subtitle_day_filter');
    } else if (activePeriodFilter === DashboardFilters.MONTH) {
      return t('dashboard_subtitle_month_filter');
    } else {
      return t('dashboard_subtitle_year_filter');
    }
  };

  return (
    <>
      {isRequestInProgress === true && <Loader />}

      <div className="dashboard-page">
        <div className="dashboard-page--container">
          <div className="header">
            <div className="header__leaft-side">
              <h1>{t('welcome_username', { userName })}</h1>
              <p className="subtitle">{getDashboardSubtitle()}</p>
            </div>
            <div className="header-right-side">
              <ButtonComponent
                iconUrl={IconFullRightBlack}
                iconAlt="chevron-right"
                label={t('pending_requests')}
                badgeNumber={
                  dashboardData !== undefined ? dashboardData.pendingRequestCount.toString() : '0'
                }
                color="secondary box-shadow"
                onClick={pendingRequestClicked}
              />
            </div>
          </div>
          <div className="body">
            <Box className="dashboard-tabs" sx={{ width: '100%' }}>
              <Box
                className="dashboard-tabs__container"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label={t('preload_checks')} {...a11yProps(1)} />
                  <Tab label={t('annual_vettings')} {...a11yProps(1)} />
                </Tabs>
                {renderFilters()}
              </Box>
              <CustomTabPanel value={activeTab} index={CHECKTYPE.PRELOADCHECKS}>
                {dashboardData !== undefined &&
                  RenderDashboardPanel(dashboardData, navigate, t, activeTab)}
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={CHECKTYPE.ANNUALVETTINGS}>
                {dashboardData !== undefined &&
                  RenderDashboardPanel(dashboardData, navigate, t, activeTab)}
              </CustomTabPanel>
            </Box>
          </div>
        </div>
      </div>

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </>
  );
};

export default Dashboard;
