import React, { useEffect, useState } from 'react';
import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Trucks from './pages/trucks/Trucks';
import Layout from './shared/components/layout/Layout';
import PreloadChecks from './pages/preload-checks/PreloadChecks';
import AnnualVettings from './pages/annual-vettings/AnnualVettings';
import Drivers from './pages/drivers/Drivers';
import Agents from './pages/agents/Agents';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Login from './pages/login/Login';
import axios from 'axios';
import PreloadCheckDetails from './pages/preload-checks-details/PreloadCheckDetails';
import DriverDetails from './pages/driver-details/DriverDetails';
import TruckDetails from './pages/truck-details/TruckDetails';
import Trailers from './pages/trailers/Trailers';
import Transporters from './pages/transporters/Transporters';
import { createSSOUser } from './services/Auth.service';
import TrailerDetails from './pages/trailers-details/TrailerDetails';
import AgentDetails from './pages/agent-details/AgentDetails';
import TransporterDetails from './pages/transporter-details/TransporterDetails';
import PreloadCheckEntire from './pages/preload-check-entire/PreloadCheckEntire';
import { editCurrentUserDeport, getCurrentUser } from './services/User.service';
import { Snackbar } from '@mui/material';
import { useSnackbar } from './services/SnackbarUtils';
import { IUser } from './interfaces/User.interface';
import Loader from './shared/components/Loader/Loader';
import { FetchProductsListCreateEditModal } from './services/Product.service';
import { IOptions, IOptionsData } from './interfaces/Form-Modal.interface';
import { FetchTransportersListCreateEditModal } from './services/Transporter.service';
import {
  FetchFiltersAgentsListMethod,
  FetchFiltersProductsListMethod,
  FetchFiltersTransportersListMethod,
} from './services/Helper.service';
import Cookies from 'js-cookie';
import { GetCountries } from './services/Country.service';

const App = (): React.JSX.Element => {
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userDeports, setUserDeports] = useState<string[]>([]);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [ProductsList, setProductsList] = useState<IOptions[]>([]);
  const [TransportersList, setTransportersList] = useState<IOptions[]>([]);
  const [Countries, setCountries] = useState<IOptions[]>([]);
  const [FiltersProductsList, setFiltersProductsList] = useState<IOptionsData>();
  const [PreloadCheckFiltersTransportersList, setPreloadCheckFiltersTransportersList] = useState<IOptionsData>();
  const [FiltersAgentsList, setFiltersAgentsList] = useState<IOptionsData>();
  const [deportChanged, setDeportChanged] = useState(false);
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();

  const LogoutMethod = (): void => {
    void instance.logout({
      onRedirectNavigate: () => {
        setIsLoading(false);
        localStorage.clear();
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        // Return false to prevent the redirect.
        return false;
      },
    });
  };

  const getUserData = async (): Promise<void> => {
    if (accounts.length === 0) {
      return;
    }

    setIsLoading(true);
    instance.setActiveAccount(accounts[0]);

    try {
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: ['User.Read'],
      });

      const token = tokenResponse.accessToken;
      const userResponse = await axios.get('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = userResponse.data;

      const memberOfResponse = await axios.get('https://graph.microsoft.com/v1.0/me/memberOf?$search="displayName:DW_TruckVetting"', {
        headers: {
          Authorization: `Bearer ${token}`,
          ConsistencyLevel: 'eventual'
        },
      });

      const groupsData = memberOfResponse.data.value.map((data: any) => data.displayName);
      // Use filter and list comprehension to find the element
      const deportElement = groupsData.find(
        (element: string) => typeof element === 'string' && element.toLowerCase().includes('depot')
      );

      const isUserRoleManager = groupsData.find(
        (element: string) => typeof element === 'string' && element.toLowerCase().includes('manager')
      );
      /* GET ALL DEPORTS THAT ARE ASSIGNED TO USER FROM SSO TO DISPLAY THEM AS OPTIONS ON THE SELECT MENU */
      const deportItems = groupsData.filter((item: string) => typeof item === 'string' && item.toLowerCase().includes('depot'));
      let initialDeport = null;
      if (deportElement !== undefined) {
        const deports = deportItems.map((deport: string) => {
          const deportValue = deport.split('_');
          return deportValue[deportValue.length - 1];
        });
        setUserDeports(deports);
        initialDeport = deports[0];
      }

      // IF USER IS NOT MANAGER PREVENT ACCESS AND LOGOUT
      if (isUserRoleManager === undefined) {
        LogoutMethod();
        return;
      }

      const payload = {
        name: userData.givenName,
        last_name: userData.surname !== null ? userData.surname : '',
        deport: initialDeport !== null ? initialDeport : userData.officeLocation,
        role: isUserRoleManager !== undefined ? 'manager' : '',
        number_id: userData.id,
        external_id: userData.id,
      };
      

      await createSSOUser(payload).then(() => {
        getCurrentUserData();
        // Modal Add Edit List Options
        FecthProducts();
        FecthTransporters();
        GetCountriesMethod();
        // Filters List Options
        FetchFiltersProductsList();
        FetchFiltersTransportersList();
        FetchFiltersAgentsList();
      });

      setUserName(userData.displayName);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        showSnackbar(error.message, 'error');
      }
      LogoutMethod();
    }
  };

  const getCurrentUserData = (): void => {
    getCurrentUser()
      .then((currentUser) => {
        setCurrentUser(currentUser);
        setIsLoading(false);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
        setIsLoading(false);
      });
  };

  const GetCountriesMethod = (): void => {
    GetCountries().then((countries) => {
      setIsLoading(false);
      setCountries(countries)
    })
    .catch((error) => {
      showSnackbar(error.message, 'error');
      setIsLoading(false);
    });
  };

  const editUserDeport = (deport: string): void => {
    setDeportChanged(false);

    const payload = { deport };
    editCurrentUserDeport(payload)
      .then(() => {
        setDeportChanged(true);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
      });
  };

  const FecthProducts = (): void => {
    FetchProductsListCreateEditModal()
      .then((productsList) => {
        const productsOptionsList = productsList.map((product) => {
          return { value: product.id, label: product.description };
        });

        setProductsList(productsOptionsList);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
      });
  };

  const FecthTransporters = (): void => {
    FetchTransportersListCreateEditModal()
      .then((transportersList) => {
        const transportersOptionsList = transportersList.map((transporter) => {
          return { value: transporter.id, label: transporter.name };
        });

        setTransportersList(transportersOptionsList);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
      });
  };

  const FetchFiltersProductsList = (): void => {
    setIsLoading(true);
    FetchFiltersProductsListMethod('0')
      .then((productsList) => {
        setIsLoading(false);
        setFiltersProductsList(productsList);
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackbar(error.message, 'error');
      });
  };

  const FetchFiltersTransportersList = (): void => {
    setIsLoading(true);
    FetchFiltersTransportersListMethod('0')
      .then((transportersList) => {
        setIsLoading(false);
        setPreloadCheckFiltersTransportersList(transportersList);
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackbar(error.message, 'error');
      });
  };

  const FetchFiltersAgentsList = (): void => {
    setIsLoading(true);
    FetchFiltersAgentsListMethod('0')
      .then((agentsList) => {
        setIsLoading(false);
        setFiltersAgentsList(agentsList);
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackbar(error.message, 'error');
      });
  };

  useEffect(() => {
    void instance.initialize().then(() => {
      void getUserData();
    });
    // Update the document title using the browser API
  }, [accounts, deportChanged]);

  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Routes>
          {!isLoading && (
            <>
              {/* Redirect base URL to /dashboard */}
              <Route path="/" element={<Navigate replace to="/dashboard" />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route
                path="/"
                element={
                  <Layout
                    currentUser={currentUser as IUser}
                    userDeports={userDeports}
                    handleEditCurrentUserDeport={editUserDeport}
                  />
                }
              >
                <Route path="/dashboard">
                  <Route
                    path="/dashboard/:activeTab?/:periodFilter?"
                    element={<Dashboard userName={userName} />}
                  />
                  <Route
                    path="preload-checks/:checkStatus?/:id"
                    element={<PreloadCheckDetails />}
                  />
                </Route>

                <Route path="/preload-checks">
                  <Route
                    path="/preload-checks/:statusFilters?/:transporterFilters?/:productFilters?/:agentFilters?"
                    element={
                      <PreloadChecks
                        FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                        FiltersProductsList={FiltersProductsList as IOptionsData}
                        FiltersAgentsList={FiltersAgentsList as IOptionsData}
                      />
                    }
                  />
                  <Route path="details/:id" element={<PreloadCheckDetails />} />
                  <Route path="entire-check/:id" element={<PreloadCheckEntire />} />
                </Route>

                <Route
                  path="/annual-vettings/:statusFilters?/:transporterFilters?/:productFilters?/:agentFilters?"
                  element={
                    <AnnualVettings
                      FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                      FiltersProductsList={FiltersProductsList as IOptionsData}
                      FiltersAgentsList={FiltersAgentsList as IOptionsData}
                    />
                  }
                />

                <Route path="/trucks">
                  <Route
                    path="/trucks/:transporterFilters?"
                    element={
                      <Trucks
                        Transporters={TransportersList}
                      />
                    }
                  />

                  <Route
                    path="details/:id"
                    element={
                      <TruckDetails
                        transporters={TransportersList}
                        FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                        FiltersProductsList={FiltersProductsList as IOptionsData}
                        FiltersAgentsList={FiltersAgentsList as IOptionsData}
                      />
                    }
                  />
                </Route>

                <Route path="/drivers">
                  <Route
                    path="/drivers/:transporterFilters?"
                    element={
                      <Drivers
                        Transporters={TransportersList}
                      />
                    }
                  />
                  <Route
                    path="details/:id/:activeTab?/:statusFilters?/:productFilters?/:productFilters?/:agentFilters?"
                    element={
                      <DriverDetails
                        transporters={TransportersList}
                        FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                        FiltersProductsList={FiltersProductsList as IOptionsData}
                        FiltersAgentsList={FiltersAgentsList as IOptionsData}
                      />
                    }
                  />
                </Route>

                <Route path="/agents">
                  <Route
                    path="/agents/:roleFilters?/:deportFilters?"
                    element={<Agents CountriesOptions={Countries} />}
                  />
                  <Route
                    path="details/:id"
                    element={
                      <AgentDetails
                        userDeports={userDeports}
                        CountriesOptions={Countries}
                        FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                        FiltersProductsList={FiltersProductsList as IOptionsData}
                        FiltersAgentsList={FiltersAgentsList as IOptionsData}
                      />
                    }
                  />
                </Route>

                <Route path="/transporters">
                  <Route
                    path="/transporters/:transporterFilters?"
                    element={
                      <Transporters
                        products={ProductsList}
                      />
                    }
                  />
                  <Route
                    path="details/:id/:activeTab?/:statusFilters?/:productFilters?/:agentFilters?"
                    element={
                      <TransporterDetails
                        products={ProductsList}
                        FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                        FiltersProductsList={FiltersProductsList as IOptionsData}
                        FiltersAgentsList={FiltersAgentsList as IOptionsData}
                      />
                    }
                  />
                </Route>

                <Route path="/trailers">
                  <Route
                    path="/trailers/:transporterFilters?/:productFilters?"
                    element={
                      <Trailers
                        transporters={TransportersList}
                        products={ProductsList}
                      />
                    }
                  />
                  <Route
                    path="details/:id/:activeTab?/:statusFilters?/:productFilters?/:productFilters?/:agentFilters?"
                    element={
                      <TrailerDetails
                        transporters={TransportersList}
                        products={ProductsList}
                        FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                        FiltersProductsList={FiltersProductsList as IOptionsData}
                        FiltersAgentsList={FiltersAgentsList as IOptionsData}
                      />
                    }
                  />
                </Route>
              </Route>
            </>
          )}
        </Routes>
      </AuthenticatedTemplate>
      {isLoading && <Loader />}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default App;
