import { IOptions } from '../interfaces/Form-Modal.interface';
import { axiosAuthInstance } from './Auth.service';

// Get Countries
export const GetCountries = async (): Promise<IOptions[]> => {
  const client = await axiosAuthInstance();

  const response = await client.get('/user/countries');
  return response.data.countries.filter((country: string | null) => country !== null).map((country: string) => {
      return {
        label: country,
        value: country,
      }
  });
};
