import React, { useEffect, useState } from 'react';
import './Table.scss';
import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_PaginationState,
  type MRT_Row,
} from 'mantine-react-table';
import { IColumnTable, IDataTable } from '../../../interfaces/Table.interface';
import { useTranslation } from 'react-i18next';

interface ITableProps {
  data: IDataTable[];
  columns: IColumnTable[];
  emptyMsg: string;
  onRowClicked: (id: string) => void;
  handlePaginationChange?: (paginationIndex: number) => void;
  totalPages: number;
  hideTableHeader?: boolean;
  hideTableBody?: boolean;
  enableSorting?: boolean;
  enableStickyHeader?: boolean;
}

const Table = ({
  data,
  columns,
  emptyMsg,
  onRowClicked,
  handlePaginationChange,
  totalPages,
  hideTableHeader,
  hideTableBody,
  enableSorting,
  enableStickyHeader,
}: ITableProps): JSX.Element => {
  const [columnsTable, setColumnsTable] = useState<Array<MRT_ColumnDef<IDataTable>>>(columns);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [paginationCurrentPageNumber, setPaginationCurrentPageNumber] = useState(1);
  const { t } = useTranslation('common');

  useEffect(() => {
    setPaginationCurrentPageNumber(pagination.pageIndex + 1);
  }, []);

  useEffect(() => {
    setColumnsTable(columns);
  }, [columns, t]);

  const renderEmptyRowsFallback = (): React.JSX.Element => {
    return <p className="oryx-table-empty-message">{emptyMsg}</p>;
  };

  const handleRowClick = (row: MRT_Row<IDataTable>): void => {
    const rowData: IDataTable = row.original;
    onRowClicked(rowData.id);
  };

  return (
    <MantineReactTable
      columns={columnsTable}
      data={data}
      enableSorting={enableSorting !== undefined ? enableSorting : true}
      paginationDisplayMode="pages"
      enableColumnActions={false}
      enableTopToolbar={false}
      enableStickyHeader={enableStickyHeader !== undefined ? enableStickyHeader : false}
      enableStickyFooter
      enablePagination={data.length > 0}
      mantineTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
        className: `oryx-table ${data.length === 0 ? 'empty' : ''} ${
          hideTableBody === true ? 'hide-body' : ''
        }`,
      }}
      mantineTableHeadProps={{
        className: `oryx-table-header ${hideTableHeader === true ? 'hide-header' : ''}`,
      }}
      mantineTableHeadRowProps={{
        className: 'oryx-table-header-row',
      }}
      mantineTableHeadCellProps={{
        className: 'oryx-table-header-cell',
      }}
      mantineTableBodyRowProps={({ row }) => ({
        onClick: () => {
          handleRowClick(row);
        },
        sx: {
          cursor: 'pointer',
        },
      })}
      mantinePaginationProps={{
        className: 'oryx-table-pagination',
        total: totalPages,
        value: paginationCurrentPageNumber,
        onChange: (pageNumber) => {
          setPaginationCurrentPageNumber(pageNumber as number);
          if (handlePaginationChange !== undefined) {
            const pageIndex = (pageNumber as number) - 1;
            handlePaginationChange(pageIndex);
          }
        },
      }}
      renderEmptyRowsFallback={renderEmptyRowsFallback}
      manualPagination={true}
      onPaginationChange={setPagination}
    />
  );
};

export default Table;
